import React from "react";
import { InputNumber, Input, Select, TimePicker } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/zh_TW";
import moment from "moment";
class TransactionItemForm extends React.Component {
  render() {
    const { fielddata, disabled } = this.props;
    return (
      <>
        <div
          label="時間"
          className="cloumn-style"
          name="date"
        >
            <TimePicker
              placeholder="請選擇時間"
              style={{ width: "100%" }}
              format="HH:mm"
              inputReadOnly={true}
              allowClear={false}
              onChange={this.props.onTimeChange}
              onSelect={this.props.onTimeChange}
              disabled={disabled}
              locale={locale}
              value={
                (fielddata && fielddata.date) !== undefined
                  ? moment(fielddata.date)
                  : undefined
              }
            />
        </div>

        <div
          label="類型"
          className="cloumn-style"
          name="action"
        >
          <Select
            style={{ width: "100%" }}
            size={this.props.size}
            placeholder="買/賣"
            onChange={this.props.onSelectChange.bind(this, "action")}
            disabled={disabled}
            >
            <Select.Option value="buy">買進</Select.Option>
            <Select.Option value="sell">賣出</Select.Option>
          </Select>
        </div>
        <div label="金額" className="cloumn-style" name="subtotal">
          <InputNumber
            style={{ width: "100%" }}
            size={this.props.size}
            placeholder="請輸入交易金額"
            value={fielddata !== undefined ? fielddata.subtotal : null}
            disabled={
              disabled||
              fielddata.action === null ||
              fielddata.action === undefined
            }
            onChange={this.props.onValueChange.bind(this, "subtotal")}
          />
        </div>
        <div label="數量" className="cloumn-style" name="quantity">
          <InputNumber
            style={{ width: "100%" }}
            size={this.props.size}
            placeholder="請輸入交易數量"
            value={fielddata !== undefined ? fielddata.quantity : null}
            disabled={
              disabled||
              fielddata.action === null ||
              fielddata.action === undefined
            }
            onChange={this.props.onValueChange.bind(this, "quantity")}
          />
        </div>
        <div label="成交價格" className="cloumn-style" name="unitprice">
          <InputNumber
            style={{ width: "100%" }}
            size={this.props.size}
            placeholder="請輸入成交價格"
            value={fielddata !== undefined ? fielddata.unitprice : null}
            disabled={true}
            onChange={this.props.onValueChange.bind(this, "unitprice")}
          />
        </div>
        <div label="餘額" className="cloumn-style" name="balance">
          <InputNumber
            style={{ width: "100%" }}
            size={this.props.size}
            placeholder="餘額"
            value={fielddata !== undefined ? fielddata.balance : null}
            disabled={true}
            onChange={this.props.onValueChange.bind(this, "balance")}
          />
        </div>
        <div label="備註" className="cloumn-style" name="note">
          <Input
            size={this.props.size}
            placeholder="本筆交易的備註"
            onChange={this.props.onValueChange.bind(this, "note")}
            disabled={disabled}
          />
        </div>
        {!disabled ? (
          <div label="&nbsp;" className="cloumn-style">
            <MinusCircleOutlined onClick={this.props.onDeleteItem} />
          </div>
        ) : null}
      </>
    );
  }
}
export default TransactionItemForm;
