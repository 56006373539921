import React from "react";
import { Form, Button, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Drawer from "../Drawer";
import AccountForm from "./AccountForm";
import HoldingsForm from "../Holding/HoldingsForm";
import { createFundAccount, createHoldings } from "../../controller/api";
import moment from "moment";
class CreateAccountDrawer extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      accountprincipal: 0,
      remaining: 0,
      holdingsprincipal: {},
    };
  }
  getInitialValues = () => {
    return { currencyid: "TWD" };
  };
  onInputNumberChange = (value) => {
    this.setState({
      accountprincipal: value,
      remaining: value,
    });
  };
  calculateRemainPrincipal = (id, value) => {
    let holdingsprincipal = this.state.holdingsprincipal;
    holdingsprincipal[id] = value;
    let remaining = this.state.accountprincipal;
    Object.values(holdingsprincipal).forEach((data) => (remaining -= data));
    this.setState({ holdingsprincipal, remaining });
  };
  onDeleteItem = (field, remove) => {
    let holdingsprincipal = this.state.holdingsprincipal;
    let removeItem = holdingsprincipal[field.key];
    delete holdingsprincipal[field.key];
    remove(field.name);
    this.setState({
      holdingsprincipal,
      remaining: this.state.remaining + removeItem,
    });
  };
  onHandleSubmit = (formProps) => {
    const accountdata = {
      customersid: this.props.customerid,
      account: formProps.account,
      custodiansid: formProps.custodiansid,
      authorizationmodesid: formProps.authorizationmodesid,
      enabledate: moment(formProps.enabledate).format(
        process.env.REACT_APP_DATE_FORMAT
      ),
      principal: formProps.principal,
      currencyid: formProps.currencyid,
    };
    this.setState({ loading: true }, () => {
      createFundAccount(accountdata).then((res) => {
        if (formProps.assets !== undefined) {
          const holdingsdata = formProps.assets;

          // 建立商品
          let promises = holdingsdata.map((item) => {
            const data = item;
            data.enabledate = moment(formProps.enabledate).format(
              process.env.REACT_APP_DATE_FORMAT
            );
            data.fundaccountsid = res.data.fundaccount.id;
            return createHoldings(data);
          });
          Promise.all(promises).then((results) => {
            notification["success"]({
              message: res.data.message,
              duration: 3,
              onClose: () => {
                this.props.reloadList(1);
                this.props.onClose();
              },
            });
          }).catch((err) => {
            console.log(err);
            notification["error"]({
              message: "商品新增失敗",
              duration: 3,
              onClose: () => {
                this.props.reloadList(1);
                this.props.onClose();
              },
            });
          });
        } else {
          notification["success"]({
            message: res.data.message,
            duration: 3,
            onClose: () => {
              this.props.reloadList(1);
              this.props.onClose();
            },
          });
        }
      });
    });
  };
  onClose = () => {
    this.props.onClose();
  };
  onValuesChange = (changedValues, allValues) => {
    let assets = allValues.assets;
    if (allValues.assets) {
      let isUpdates = false;
      assets.forEach((asset, index) => {
        if (!asset) return;
        let principal = asset.principal;
        let quantity = asset.quantity;
        if (principal && quantity) {
          let unitprice = principal / quantity;
          asset.unitprice = unitprice;
          isUpdates = true;
        }
      });
      if (isUpdates) {
        this.formRef.current.setFieldsValue({ assets: assets });
      }
    }
  };
  render() {
    return (
      <Drawer
        title="新增資金帳戶"
        width={"45%"}
        visible={this.props.visible}
        onClose={this.onClose}
        destroyOnClose={true}
      >
        <Form
          name="AccountForm"
          layout="vertical"
          onFinish={this.onHandleSubmit}
          initialValues={this.getInitialValues()}
          scrollToFirstError={true}
          onValuesChange={this.onValuesChange}
          ref={this.formRef}
        >
          <AccountForm
            createflag={true}
            authorizationmodes={this.props.authorizationmodes}
            custodian={this.props.custodian}
            onInputNumberChange={this.onInputNumberChange}
          />

          <Form.List name="assets">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <div
                    key={field.key}
                    style={{
                      backgroundColor: "#F2F2F2",
                      borderRadius: "10px",
                      marginTop: 10,
                      padding: 20,
                    }}
                  >
                    <HoldingsForm
                      fielddata={field}
                      createflag={true}
                      assets={this.props.assets}
                      calculateRemainPrincipal={this.calculateRemainPrincipal.bind(
                        this,
                        field.key
                      )}
                      remaining={this.state.remaining}
                    />
                    <Button
                      block
                      type="dashed"
                      onClick={this.onDeleteItem.bind(this, field, remove)}
                    >
                      刪除商品
                    </Button>
                  </div>
                ))}
                <Button
                  block
                  type="dashed"
                  onClick={() => add()}
                  style={{ marginTop: 10 }}
                >
                  <PlusOutlined />
                  新增商品
                </Button>
              </>
            )}
          </Form.List>
          <div style={{ marginTop: "5px" }}>
            <Button
              type="primary"
              htmlType="submit"
              className="button-color mt"
              loading={this.state.loading}
            >
              儲存
            </Button>
            <Button
              style={{ marginLeft: "5px" }}
              className="mt"
              onClick={this.onClose}
            >
              取消
            </Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}
export default CreateAccountDrawer;
