import React from "react";
import { Form, Input, Button, notification } from "antd";
import { Link } from "react-router-dom";
import Logo from "../assets/image/loginLogo.png";
import * as Layout from "../components/Layout";
import { apiUserLogin } from "../controller/api";
import { login } from "../controller/auth";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      account: "",
      password: "",
    };
  }
  onInputChange = (name, e) => {
    const state = { ...this.state };
    state[name] = e.target.value;
    this.setState(state);
  };
  onLogin = () => {
    let data = { account: this.state.account, password: this.state.password };
    this.setState(
      {
        loading: true,
      },
      () => {
        apiUserLogin({ ...data })
          .then((res) => {

            const tokenType = res.data.token_type;
            const token = res.data.token;
            const userId = res.data.id;
            const roles = res.data.roles;
            const expiresIn = res.data.expires_in;
            login(tokenType, token, userId, roles, expiresIn);
            this.props.history.push("/admin");
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            notification.error({
              message: error.response ? error.response.data.error:'A network error occurred. '
              + 'This could be a CORS issue or a dropped internet connection. '
              + 'It is not possible for us to know.',
              duration: 3,
            });
          });
      }
    );
  };
  render() {
    return (
      <Layout.LoginLayout {...this.props}>
        <img
          src={Logo}
          alt="one stone"
          style={{ margin: "0 auto 8% auto", display: "block" }}
        />
        <Form layout="vertical" onFinish={this.onLogin}>
          <Form.Item
            label="帳號"
            name="account"
            rules={[{ required: true, message: "請輸入身份證字號或帳號" }, { pattern:/^[0-9a-zA-Z._-]+$/, message: "格式錯誤請重新輸入" }]}
          >
            <Input
              size="large"
              placeholder="請輸入身份證字號或護照號碼或帳號"
              value={this.state.account}
              onChange={this.onInputChange.bind(this, "account")}
            />
          </Form.Item>
          <Form.Item
            label="密碼"
            name="password"
          >
            <Input.Password
              size="large"
              placeholder="請點擊開始輸入"
              value={this.state.password}
              onChange={this.onInputChange.bind(this, "password")}
            />
          </Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            block
            className="button-color mt"
            loading={this.state.loading}
          >
            登入
          </Button>
          <Link
            className="login-form-forgot"
            to="/forget"
            style={{
              textAlign: "center",
              display: "block",
              marginTop: 20,
              color: "#fff",
            }}
          >
            忘記密碼
          </Link>
        </Form>
      </Layout.LoginLayout>
    );
  }
}

export default LoginPage;
