import React from "react";
import {
  Descriptions,
  Table,
  Button,
  Space,
  Tooltip,
  Typography,
  Divider,
  Row,
  Col,
} from "antd";

import Drawer from "../Drawer";
import { QuestionCircleOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import CreateHoldingsDrawer from "../Holding/CreateHoldingsDrawer";
import DeleteHoldingsModal from "../Holding/DeleteHoldingsModal";
import HoldingsDetailDrawer from "../Holding/HoldingsDetailDrawer";
import { getAccountDetail, getHoldings } from "../../controller/api";
import moment from "moment";
import CurrencyNumberFormat from "../CurrencyNumberFormat";
import { TYPE_PERFORMANCE, TYPE_TRANSACTION } from "../../consts/HoldingTypes";
class AccountsDetailDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      accountDetail: props.accountDetail,
      holdings: [],
      createHoldingsDrawerVisible: false,
      deleteHoldingsVisible: false,
      holdingDetailDrawerVisible: false,
      selectedHoldingId: null,
      viewing: null,
      pagination: {
        load: 0,
        total: 0,
        pagesize: 5,
        current: 1,
      },
    };
  }
  componentDidMount() {
    this.refresh(1);
  }
  refresh = (page) => {
    this.refreshAccountDetail();
    this.refreshHoldings(page);
  };
  refreshAccountDetail = () => {
    getAccountDetail(this.props.accountDetail.id).then((response) => {
      this.setState({
        accountDetail: response.data.fundaccounts
      });
    });
  };
  refreshHoldings = (page) => {
    if(!page) page = 1;
    getHoldings(
      this.props.accountDetail.id,
      `?count=${this.state.pagination.pagesize}&page=${page}`
    ).then((res) => {
      let pagination = { ...this.state.pagination };
      pagination.total = res.data.holdingsqty;
      pagination.current = page;
      this.setState({
        loading: false,
        holdings: res.data.holdings,
        pagination,
      });
    });
  };
  createHoldingsDrawerVisible = () => {
    this.setState({
      createHoldingsDrawerVisible: !this.state.createHoldingsDrawerVisible,
    });
  };
  deleteHoldingsVisible = (item) => {
    this.setState({
      viewing: item,
      deleteHoldingsVisible: !this.state.deleteHoldingsVisible,
    });
  };
  showHoldingDetailDrawer = (holdingsId, data) => {
    this.setState({
      holdingDetailDrawerVisible: true,
      selectedHoldingId: holdingsId,
      selectHoldingData: data
    });
  };
  hideHoldingDetailDrawer = () => {
    this.refresh();
    this.setState({
      holdingDetailDrawerVisible: false,
      selectedHoldingId: null,
      selectHoldingData: null
    });
  }
  onClose = () => {
    this.props.onClose();
  };
  render() {
    const columns = [
      {
        title: "商品名稱",
        dataIndex: "assetname",
        key: "assetname",
        render: (text, record) => {
          return <Button type="link" onClick={this.showHoldingDetailDrawer.bind(this, record.id, record)}>{text}</Button>
        }
      },
      {
        title: "買進日期",
        dataIndex: "enabledate",
        key: "enabledate",
        width: "150px",
        render: (text) =>
          text === null
            ? "-"
            : moment(text).format(process.env.REACT_APP_DATE_FORMAT + " (dd)"),
      },
      {
        title: "本金",
        align: "right",
        dataIndex: "principal",
        key: "principal",
        render: (text) => (
          <NumberFormat
            value={text}
            displayType="text"
            thousandSeparator={true}
            fixedDecimalScale={true}
            prefix="$"
          />
        ),
      },
      {
        title: "績效記錄方式",
        dataIndex: "type",
        key: "type",
        render: (text) => {
          switch(text) {
            case TYPE_TRANSACTION:
              return "交易明細";
            case TYPE_PERFORMANCE:
              return "帳戶餘額";
            default:
              return "不明";
          }
        }
      },
      {
        title: "未實現餘額",
        align: "right",
        dataIndex: "balance",
        key: "balance",
        render: (text) => (
          <NumberFormat
            value={text}
            displayType="text"
            thousandSeparator={true}
            fixedDecimalScale={true}
            prefix="$"
          />
        ),
      },
      {
        title: "持有數量",
        align: "right",
        dataIndex: "inventory",
        key: "inventory"
      },
      {
        title: "已實現損益",
        align: "right",
        dataIndex: "realizedprofit",
        key: "realizedprofit"
      },
      {
        title: "備註",
        dataIndex: "note",
        key: "note",
        width: "120px",
      },
      {
        title: () => (
          <Tooltip
            placement="topRight"
            title="如商品已建立績效紀錄則無法刪除。"
          >
            <Space>
              動作
              <QuestionCircleOutlined />
            </Space>
          </Tooltip>
        ),
        key: "action",
        width: "13%",
        render: (data) => (
          <>
            {data.deletable ? (
              <>
                <Button
                  type="link"
                  onClick={this.deleteHoldingsVisible.bind(this, data)}
                >
                  刪除
                </Button>
              </>
            ) : null}
          </>
        ),
      },
    ];
    return (
      <Drawer
        width={"60%"}
        visible={this.props.visible}
        onClose={this.onClose}
        destroyOnClose={true}
      >
        <Typography.Title level={4}>
          帳戶 {this.state.accountDetail.account}
        </Typography.Title>
        <Descriptions size="small" column={{ lg: 3, md: 2, sm: 1, xs: 1 }}>
          <Descriptions.Item label="保管方">
            <Typography.Text type="secondary">
              {this.state.accountDetail.custodian.name}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="授權方式">
            <Typography.Text type="secondary">
              {this.state.accountDetail.authorizationmode.mode}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="建立日期">
            <Typography.Text className="gobal-color">
              {moment(this.state.accountDetail.enabledate).format(
                process.env.REACT_APP_DATE_FORMAT
              )}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="帳戶託管金額">
            <CurrencyNumberFormat currencyid={this.state.accountDetail.currencyid} value={this.state.accountDetail.principal} />
          </Descriptions.Item>
          <Descriptions.Item label="帳戶託管餘額">
            <CurrencyNumberFormat currencyid={this.state.accountDetail.currencyid} value={this.state.accountDetail.balance} />
          </Descriptions.Item>
        </Descriptions>
        <Divider />
        <Row justify="space-between">
          <Col>
            {this.state.holdings.length > 0
              ? `頁 ${this.state.pagination.current} / ${Math.ceil(
                  this.state.pagination.total / this.state.pagination.pagesize
                )}，共 ${this.state.pagination.total} 筆商品`
              : null}
          </Col>
          <Col>
            <Button
              key="createHoldings"
              onClick={this.createHoldingsDrawerVisible}
              className="button-color"
              style={{ marginBottom: "10px", borderRadius: 5, float: "right" }}
            >
              新增商品
            </Button>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={this.state.holdings}
          loading={this.state.loading}
          rowKey="id"
          pagination={{
            position: ["bottomLeft"],
            total: this.state.pagination.total,
            current: this.state.pagination.current,
            pageSize: this.state.pagination.pagesize,
            showSizeChanger: false,
            onChange: (page) => this.refresh(page),
          }}
          scroll={{
            x: 1200,
            scrollToFirstRowOnChange: true,
          }}
        />
        {!this.state.createHoldingsDrawerVisible ? null : (
          <CreateHoldingsDrawer
            visible={this.state.createHoldingsDrawerVisible}
            onClose={this.createHoldingsDrawerVisible}
            assets={this.props.assets}
            viewingaccount={this.props.accountDetail.id}
            reloadAccountDetail={this.refresh}
          />
        )}
        <HoldingsDetailDrawer
          visible={this.state.holdingDetailDrawerVisible}
          onClose={this.hideHoldingDetailDrawer}
          holdingsId={this.state.selectedHoldingId}
          holdingsData={this.state.selectHoldingData}
        />
        {!this.state.deleteHoldingsVisible ? null : (
          <DeleteHoldingsModal
            visible={this.state.deleteHoldingsVisible}
            onClose={this.deleteHoldingsVisible}
            viewing={this.state.viewing}
            reloadAccountDetail={this.refresh}
            reloadHoldingList={this.props.reloadList}
          />
        )}
      </Drawer>
    );
  }
}
export default AccountsDetailDrawer;
