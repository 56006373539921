import React from "react";
import { Button, Descriptions, notification, Spin, Typography } from "antd";
import Drawer from "../Drawer";
import PerformanceForm from "./PerformanceForm";
import moment from "moment";
import { createPerformances, getAccountDetail, getHoldingDetail } from "../../controller/api";
import { TYPE_PERFORMANCE, TYPE_TRANSACTION } from "../../consts/HoldingTypes";
class CreatePerformanceDetailDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fundaccount: null,
      holding: null,
      formData: {
        holdingid: props.holdingId,
        time: moment().format("YYYY-MM-DD HH:mm:ss"),
        balance: 0,
        realizedprofit: null,
        deposit: null,
        unrealizedprofit: null,
        tax: null,
        commission: null,
        note: null
      },

      // state
      buttonLoading: false,
    };
  }
  componentDidMount() {
    this.refresh();
  }
  refresh = async () => {
    await getAccountDetail(this.props.fundaccountId).then((response) => {
      let formData = this.state.formData;
      formData.balance = response.data.fundaccounts.balance;
      this.setState({
        fundaccount: response.data.fundaccounts,
        formData: formData,
      });
    });
    await getHoldingDetail(this.props.holdingId).then((response) => {
      this.setState({ holding: response.data.holdings });
    });
  };
  getHoldingTypeName = (type) => {
    switch (type) {
      case TYPE_TRANSACTION:
        return "交易明細";
      case TYPE_PERFORMANCE:
        return "帳戶餘額";
      default:
        return "不明";
    }
  };
  onValueChange = (field, value) => {
    let formData = this.state.formData;
    let oldBalance = this.state.fundaccount.balance;
    switch (field) {
      case 'balance':
        {
          let balance = value;
          let realizedprofit = balance - oldBalance;
          formData.realizedprofit = realizedprofit;
        }
        break;
      case 'realizedprofit':
        {
          let balance = formData.balance;
          let realizedprofit = value;
          let deposit = (balance - oldBalance) - realizedprofit;
          formData.deposit = deposit;
        }
        break;
      case 'deposit':
        {
          let balance = formData.balance;
          let deposit = value;
          let realizedprofit = (balance - oldBalance) - deposit;
          formData.realizedprofit = realizedprofit;
        }
        break;
      default:
        break;
    }
    formData[field] = value;
    this.setState({
      formData: formData
    });
  };
  onHandleSubmit = () => {
    let { time, balance, realizedprofit, deposit } = this.state;
    if (time === null) {
      notification["warning"]({ message: "請輸入時間" });
      return;
    }
    if (deposit === 0) {
      notification["warning"]({ message: "請輸入入出金金額" });
      return;
    }
    if (realizedprofit === 0) {
      notification["warning"]({ message: "請輸入已實現損益" });
      return;
    }
    if (balance === 0) {
      notification["warning"]({ message: "請輸入帳戶餘額" });
      return;
    }
    this.setState({ buttonLoading: true });
    let formData = {
      ...this.state.formData,
      date: this.state.formData.time
    };
    createPerformances(formData)
      .then((res) => {
        notification["success"]({
          message: res.data.message,
          duration: 3,
          onClose: this.onSave,
        });
      })
      .catch((err) => {
        notification["error"]({
          message: err.response.data.message,
          duration: 3,
        });
      })
      .finally(() => {
        this.setState({ buttonLoading: false });
      });
  };
  onSave = () => {
    if (this.props.onSave) {
      this.props.onSave();
    }
  };
  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };
  render() {
    return (
      <Drawer
        title="新增績效明細"
        width={"45%"}
        visible={this.props.visible}
        onClose={this.onClose}
        destroyOnClose={true}
      >
        <Descriptions size="small" column={{ lg: 3, md: 2, sm: 1, xs: 1 }}>
          <Descriptions.Item label="資金帳戶">
            <Typography.Text type="secondary">
              {this.state.fundaccount && this.state.fundaccount.account}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="本金">
            <Typography.Text type="secondary">
              {this.state.fundaccount && this.state.fundaccount.principal}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="餘額">
            <Typography.Text type="secondary">
              {this.state.fundaccount && this.state.fundaccount.balance}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="商品">
            <Typography.Text type="secondary">
              {this.state.holding && this.state.holding.assets.assetname}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="績效記錄方式">
            <Typography.Text type="secondary">
              {this.state.holding && this.getHoldingTypeName(this.state.holding.type)}
            </Typography.Text>
          </Descriptions.Item>
        </Descriptions>
        {
          this.state.holding && this.state.holding.type === TYPE_TRANSACTION && <>
            <Typography.Text type="warning">
              績效記錄方式為 "帳戶餘額" 才可直接新增績效，此類型為新增持有商品時設定
            </Typography.Text>
          </>
        }
        { /* 只有 holding type 為 performance 才能直接新增績效 */
          this.state.holding && this.state.holding.type === TYPE_PERFORMANCE && <>
            <Spin spinning={!this.state.holding}>
              <div>
                <PerformanceForm
                  fundaccountId={this.state.fundaccountId}
                  holdingId={this.state.holdingId}
                  values={this.state.formData}
                  onValueChange={this.onValueChange}
                />
              </div>
            </Spin>
            <div style={{ marginTop: "5px" }}>
              <Button
                type="primary"
                htmlType="submit"
                className="button-color mt"
                onClick={this.onHandleSubmit}
                loading={this.state.buttonLoading}
              >
                儲存
          </Button>
              <Button
                style={{ marginLeft: "5px" }}
                className="mt"
                onClick={this.onClose}
              >
                取消
          </Button>
            </div>
          </>
        }

      </Drawer>
    );
  }
}
export default CreatePerformanceDetailDrawer;
