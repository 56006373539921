import React from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Row,
  Col,
  Button,
  Checkbox,
} from "antd";
import moment from "moment";
import { checkIdcode, getAllManagers } from "../controller/api";
import { getLoginedUserId } from "../controller/auth";
class CustomerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: props.createFlag,
      managers: [],
      memberData: props.memberData,
      account_vertify: false,
    };
  }
  componentDidMount() {
    getAllManagers().then((res) => {
      if (!res) {
        return;
      }
      this.setState({
        managers: res.data.manager,
      });
      const index = res.data.manager.findIndex((user) => {
        return user.userId === getLoginedUserId();
      });
      const yourEmployee = index < 0 ? "" : res.data.manager[index].cnName;
      let memberData = { ...this.state.memberData };
      memberData.yourEmployee = yourEmployee;
      this.setState(
        {
          loaded: false,
          memberData,
        },
        () => {
          this.setState({ loaded: true });
        }
      );
    });
  }
  onInputChange = (name, e) => {
    const memberData = { ...this.state.memberData };
    memberData[name] = e.target.value;
    this.setState({ memberData });
  };
  onSelectChange = (name, value) => {
    const memberData = { ...this.state.memberData };
    memberData[name] = value;
    this.setState({ memberData });
  };
  onDaypickerChange = (name, date, dateString) => {
    const memberData = { ...this.state.memberData };
    memberData[name] = dateString;
    this.setState({ memberData });
  };
  onCheckboxChange = (e) => {
    const memberData = { ...this.state.memberData };
    memberData["enableaccount"] = e.target.checked;
    this.setState({ account_vertify: e.target.checked, memberData });
  };
  getInitialValues = () => {
    const memberData = { ...this.state.memberData };
    memberData.birth =
      memberData.birth === "" || memberData.birth === null
        ? undefined
        : moment(memberData.birth, process.env.REACT_APP_DATE_FORMAT);

    return memberData;
  };
  onHandleSubmit = () => {
    const state = this.state.memberData;
    this.props.onSubmit(state);
  };
  render() {
    const { Option } = Select;
    const _this = this;
    return this.state.loaded && this.state.memberData !== null ? (
      <Form
        name="customerForm"
        layout="vertical"
        onFinish={this.onHandleSubmit}
        initialValues={this.getInitialValues()}
        scrollToFirstError={true}
      >
        <Form.Item
          label="客戶名稱 (例如：公司、組織單位或暱稱)"
          name="name"
          rules={[{ required: true, message: "請輸入客戶名稱" }]}
        >
          <Input
            size="large"
            placeholder="例如：張董投資案"
            value={this.state.memberData.name}
            onChange={this.onInputChange.bind(this, "name")}
          />
        </Form.Item>
        <Form.Item
          label="客戶中文姓名"
          name="cnName"
          rules={[{ required: true, message: "請輸入客戶中文姓名" }]}
        >
          <Input
            size="large"
            placeholder="請輸入客戶中文姓名"
            value={this.state.memberData.cnName}
            onChange={this.onInputChange.bind(this, "cnName")}
          />
        </Form.Item>
        <Form.Item label="客戶英文名" name="enName">
          <Input
            size="large"
            placeholder="請輸入客戶英文名"
            value={this.state.memberData.enName}
            onChange={this.onInputChange.bind(this, "enName")}
          />
        </Form.Item>
        <Form.Item label="稱呼" name="title">
          <Select
            size="large"
            placeholder="請選擇稱呼"
            allowClear
            onChange={this.onSelectChange.bind(this, "title")}
          >
            <Option value="先生">先生</Option>
            <Option value="小姐">小姐</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="國籍身分"
          name="nationality"
          rules={[
            {
              required: this.state.account_vertify ? true : false,
              message: "請選擇國籍身分",
            },
          ]}
        >
          <Select
            size="large"
            placeholder="請選擇國籍身分"
            allowClear
            onChange={this.onSelectChange.bind(this, "nationality")}
            disabled={this.state.memberData.haveaccount ? true : false}
          >
            <Option value="台灣">台灣</Option>
            <Option value="新加坡">新加坡</Option>
          </Select>
        </Form.Item>
        <Row gutter="10">
          <Col xs={24} sm={12}>
            <Form.Item
              label="身分證字號"
              name="idCode"
              rules={[
                {
                  required: this.state.account_vertify ? true : false,
                  message: "請輸入身份證字號",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      _this.props.createFlag &&
                      _this.state.memberData.nationality === "台灣"
                    ) {
                      getFieldValue("idCode");
                      const data = { idCode: getFieldValue("idCode") };
                      if (getFieldValue("idCode") === "") {
                        return Promise.reject();
                      }
                      return checkIdcode({ ...data })
                        .then((res) => {
                          return Promise.resolve();
                        })
                        .catch((err) => {
                          console.log(err.response.data.message);
                          return Promise.reject(err.response.data.message);
                        });
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input
                size="large"
                placeholder="請輸入身分證字號"
                value={this.state.memberData.idCode}
                disabled={this.state.memberData.haveaccount ? true : false}
                onChange={this.onInputChange.bind(this, "idCode")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="護照號碼" name="passport">
              <Input
                size="large"
                placeholder="請輸入護照號碼"
                value={this.state.memberData.passport}
                // disabled={!this.props.createFlag ? true : false}
                onChange={this.onInputChange.bind(this, "passport")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="生日(西元)" name="birth">
          <DatePicker
            size="large"
            style={{ width: "100%" }}
            placeholder="請選擇日期"
            format={process.env.REACT_APP_DATE_FORMAT}
            value={this.state.memberData.birth}
            onChange={this.onDaypickerChange.bind(this, "birth")}
          />
        </Form.Item>
        <Row gutter="10">
          <Col xs={24} sm={6}>
            <Form.Item label="(日間) 區碼" name="dayZone">
              <Input
                size="large"
                placeholder="請輸入區碼"
                onChange={this.onInputChange.bind(this, "dayZone")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="聯絡號碼" name="dayTel">
              <Input
                size="large"
                placeholder="請輸入電話"
                onChange={this.onInputChange.bind(this, "dayTel")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <Form.Item label="分機" name="dayExt">
              <Input
                size="large"
                placeholder="請輸入分機"
                onChange={this.onInputChange.bind(this, "dayExt")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter="10">
          <Col xs={24} sm={6}>
            <Form.Item label="(夜間) 區碼" name="nightZone">
              <Input
                size="large"
                placeholder="請輸入區碼"
                onChange={this.onInputChange.bind(this, "nightZone")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="聯絡號碼" name="nightTel">
              <Input
                size="large"
                placeholder="請輸入電話"
                onChange={this.onInputChange.bind(this, "nightTel")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <Form.Item label="分機" name="nightExt">
              <Input
                size="large"
                placeholder="請輸入分機"
                onChange={this.onInputChange.bind(this, "nightExt")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="行動電話(台灣)" name="mobile">
          <Input
            size="large"
            placeholder="請輸入行動電話"
            value={this.state.memberData.mobile}
            onChange={this.onInputChange.bind(this, "mobile")}
          />
        </Form.Item>
        <Form.Item label="聯絡地址" name="contactAddress">
          <Input
            size="large"
            placeholder="請輸入聯絡地址"
            value={this.state.memberData.contactAddress}
            onChange={this.onInputChange.bind(this, "contactAddress")}
          />
        </Form.Item>
        <Form.Item label="戶籍地址" name="registeredAddress">
          <Input
            size="large"
            placeholder="請輸入戶籍地址"
            value={this.state.memberData.registeredAddress}
            onChange={this.onInputChange.bind(this, "registeredAddress")}
          />
        </Form.Item>
        <Form.Item
          label="電子郵件"
          name="email"
          rules={[
            {
              required: this.state.account_vertify ? true : false,
              message: "請輸入電子郵件",
            },
            { type: "email", message: "電子郵件格式錯誤" },
          ]}
        >
          <Input
            size="large"
            placeholder="請輸入電子郵件"
            value={this.state.memberData.email}
            disabled={this.state.memberData.haveaccount ? true : false}
            onChange={this.onInputChange.bind(this, "email")}
          />
        </Form.Item>
        <Form.Item label="客戶所屬行業別" name="industryType">
          <Input
            size="large"
            placeholder="請輸入行業別"
            value={this.state.memberData.industryType}
            onChange={this.onInputChange.bind(this, "industryType")}
          />
        </Form.Item>
        <Form.Item label="客戶所屬本業別" name="jobType">
          <Input
            size="large"
            placeholder="請輸入本業別"
            value={this.state.memberData.jobType}
            onChange={this.onInputChange.bind(this, "jobType")}
          />
        </Form.Item>
        <Form.Item
          label="策略無限使用者 ID"
          name="stranityuserid"
        >
          <Input
            size="large"
            style={{ width: "100%" }}
            placeholder="請輸入策略無限的 ID"
            min="0"
            value={this.state.memberData.stranityuserid}
            onChange={this.onInputChange.bind(this, "stranityuserid")}
          />
        </Form.Item>
        {this.props.createFlag ? (
          <Form.Item
            label="負責人"
            name="yourEmployee"
            rules={[{ required: true, message: "請輸入負責人" }]}
          >
            <Select
              size="large"
              placeholder="請輸入負責人"
              onChange={this.onSelectChange.bind(this, "yourEmployee")}
              allowClear
              optionLabelProp="label"
              value={this.state.memberData.yourEmployee}
            >
              {this.state.managers.map((item, i) => {
                return (
                  <Option
                    key={`manager-${i}`}
                    value={item.cnName}
                    label={item.cnName}
                  />
                );
              })}
            </Select>
          </Form.Item>
        ) : null}

        {!this.state.memberData.haveaccount ? (
          <Form.Item style={{ backgroundColor: "#F2F2F2", padding: 20 }}>
            <Checkbox onChange={this.onCheckboxChange}>啟用客戶帳戶</Checkbox>
            <div style={{ marginTop: 5 }}>
              啟用客戶帳戶，系統將寄送一封電子郵件，邀請您的客戶開始使用
              OneStone 資訊整合平台。
            </div>
          </Form.Item>
        ) : null}

        {this.props.createFlag ? (
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            style={{ minWidth: "150px" }}
            className="button-color mt"
          >
            建立客戶資料
          </Button>
        ) : (
          <>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              style={{ minWidth: "150px" }}
              className="button-color mt"
            >
              更新客戶資料
            </Button>
            {this.props.onAddPage ? (
              <Button
                size="large"
                htmlType="submit"
                style={{ minWidth: "150px", marginLeft: "5px" }}
                className="mt"
                onClick={() => this.props.goNext()}
              >
                上傳文件
              </Button>
            ) : null}
          </>
        )}
      </Form>
    ) : null;
  }
}
export default CustomerForm;
