import React from "react";
import { Row, Col, Form, Input, Button, notification } from "antd";
import { editPassword } from "../../controller/api";
class EditPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
    };
  }
  formRef = React.createRef();
  onSubmit = (values) => {
    this.setState({ sending: true }, () => {
      editPassword({
        current_password: values.oldPassword,
        password: values.newPassword,
        password_confirmation: values.confirmPassword,
      })
        .then((response) => {
          this.isChanged();
          notification["success"]({
            message: response.data.message,
            duration: 3,
          });
        })
        .catch((err) => {
          this.isChanged();
          notification["error"]({
            message: err.response.data.message,
            duration: 3,
          });
        });
    });
  };
  isChanged = () => {
    this.setState({ sending: false });
    this.formRef.current.resetFields();
  };
  render() {
    return (
      <Row style={{ margin: "20px 0" }}>
        <Col xs={24} sm={18}>
          <Form layout="vertical" ref={this.formRef} onFinish={this.onSubmit}>
            <Form.Item
              label="目前的密碼"
              name="oldPassword"
              rules={[
                { required: true, message: "請目前的密碼" },
                { min: 8, message: "最少 8 碼" },
              ]}
            >
              <Input.Password size="large" placeholder="請點擊開始輸入" />
            </Form.Item>
            <Form.Item
              label="請輸入新密碼"
              name="newPassword"
              rules={[
                { required: true, message: "請輸入新密碼" },
                { min: 8, message: "最少 8 碼" },
              ]}
            >
              <Input.Password size="large" placeholder="請點擊開始輸入" />
            </Form.Item>
            <Form.Item
              label="再次輸入新密碼"
              name="confirmPassword"
              rules={[
                { required: true, message: "請輸入密碼" },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("密碼不一樣，請重新輸入");
                  },
                }),
              ]}
            >
              <Input.Password size="large" placeholder="請點擊開始輸入" />
            </Form.Item>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              block
              loading={this.state.sending}
              className="button-color mt"
              style={{ width: "150px" }}
            >
              儲存變更
            </Button>
          </Form>
        </Col>
        <Col xs={24} sm={6}></Col>
      </Row>
    );
  }
}
export default EditPasswordPage;
