import React, { Component } from 'react'
import { Typography } from "antd"
import NumberFormat from "react-number-format";
import CurrencyTag from './CurrencyTag';
import { PropTypes } from 'prop-types';

/**
 * 貨幣數字格式
 * @augments {Component<Props, State>}
 */
class CurrencyNumberFormat extends Component {
  static propTypes = {
    /** 貨幣 Id */
    currencyid: PropTypes.string,
    /** 貨幣金額 */
    value: PropTypes.number
  }

  render() {
    let decimalScale = 2;
    switch (this.props.currencyid) {
      case 'USD':
        decimalScale = 2;
        break;
      case 'TWD':
      default:
        decimalScale = 0;
        break;
    }
    return (
      <Typography.Text className="gobal-color" style={this.props.style}>
        {this.props.currencyid && <CurrencyTag currencyid={this.props.currencyid} />}
        <NumberFormat
          value={this.props.value}
          displayType="text"
          thousandSeparator={true}
          fixedDecimalScale={true}
          decimalScale={decimalScale}
          prefix="$"
        />
      </Typography.Text>
    )
  }
}

export default CurrencyNumberFormat;
