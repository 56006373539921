import React from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Row,
  Col,
  Button,
} from "antd";
import moment from "moment";
import { getAllManagers, checkIdcode } from "../controller/api";
import { sendingState } from "../controller/observer";
import { changeSendingState } from "../controller/action";
import { Observer } from "mobx-react";
class EmpolyeeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: props.createFlag,
      sending: false,
      idError: false,
      managers: [],
      memberData: {
        id: "",
        cnName: "",
        enName: "",
        idCode: "",
        jobTitle: "",
        yourEmployee: undefined,
        birth: null,
        dayZone: "",
        dayTel: "",
        dayExt: "",
        nightZone: "",
        nightTel: "",
        nightExt: "",
        mobile: "",
        contactAddress: "",
        registeredAddress: "",
        email: "",
      },
    };
  }
  componentDidMount() {
    changeSendingState(false);
    getAllManagers().then((res) => {
      if (!res) {
        return;
      }
      this.setState({
        managers: res.data.manager,
      });
    });
    if (!this.props.createFlag) {
      const memberData = this.props.memberData;
      this.setState({ loaded: true, memberData });
    }
  }
  onInputChange = (name, e) => {
    const memberData = { ...this.state.memberData };
    memberData[name] = e.target.value;
    this.setState({ memberData });
  };
  onSelectChange = (name, value) => {
    const memberData = { ...this.state.memberData };
    memberData[name] = value;
    this.setState({ memberData });
  };
  onDaypickerChange = (name, date, dateString) => {
    const memberData = { ...this.state.memberData };
    memberData[name] = dateString;
    this.setState({ memberData });
  };
  onIdCodeChange = (e) => {
    const data = { idCode: e.target.value };
    checkIdcode({ ...data });
  };
  getInitialValues = () => {
    const memberData = { ...this.state.memberData };
    memberData.birth =
      memberData.birth === null
        ? undefined
        : moment(memberData.birth, process.env.REACT_APP_DATE_FORMAT);
    return memberData;
  };
  onHandleSubmit = () => {
    changeSendingState(true);
    this.props.onSubmit(this.state.memberData);
  };
  render() {
    const { Option } = Select;
    const _this = this;
    return this.state.loaded ? (
      <Form
        name="EmpolyeeForm"
        layout="vertical"
        onFinish={this.onHandleSubmit}
        initialValues={this.getInitialValues()}
        scrollToFirstError={true}
      >
        <Form.Item
          label="中文姓名"
          name="cnName"
          rules={[{ required: true, message: "請輸入中文姓名" }]}
        >
          <Input
            size="large"
            placeholder="請輸入中文姓名"
            value={this.state.memberData.cnName}
            onChange={this.onInputChange.bind(this, "cnName")}
          />
        </Form.Item>
        <Form.Item label="英文名" name="enName">
          <Input
            size="large"
            placeholder="請輸入英文名"
            value={this.state.memberData.enName}
            onChange={this.onInputChange.bind(this, "enName")}
          />
        </Form.Item>
        <Form.Item
          label="職稱"
          name="jobTitle"
          rules={[{ required: true, message: "請輸入職稱" }]}
        >
          <Input
            size="large"
            placeholder="請輸入職位名稱"
            value={this.state.memberData.jobTitle}
            onChange={this.onInputChange.bind(this, "jobTitle")}
          />
        </Form.Item>
        <Form.Item
          label="帳號"
          name="account"
          rules={[
            { required: true, message: "請輸入帳號" },
            { pattern: /^[0-9a-zA-Z._-]+$/, message: "格式錯誤請重新輸入" },
          ]}
        >
          <Input
            size="large"
            placeholder="請輸入帳號"
            value={this.state.memberData.account}
            disabled={!this.props.createFlag ? true : false}
            onChange={this.onInputChange.bind(this, "account")}
          />
        </Form.Item>
        <Form.Item
          label="身分證字號"
          name="idCode"
          rules={[
            { required: true, message: "請輸入身份證字號" },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (_this.props.createFlag) {
                  getFieldValue("idCode");
                  const data = { idCode: getFieldValue("idCode") };
                  if (getFieldValue("idCode") === "") {
                    return Promise.reject();
                  }
                  return checkIdcode({ ...data })
                    .then((res) => {
                      return Promise.resolve();
                    })
                    .catch((err) => {
                      return Promise.reject(err.response.data.message);
                    });
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <Input
            size="large"
            placeholder="請輸入身分證字號"
            value={this.state.memberData.idCode}
            disabled={!this.props.createFlag ? true : false}
            onChange={this.onInputChange.bind(this, "idCode")}
          />
        </Form.Item>
        <Form.Item
          label="生日(西元)"
          name="birth"
        >
          <DatePicker
            size="large"
            style={{ width: "100%" }}
            placeholder="請選擇日期"
            format={process.env.REACT_APP_DATE_FORMAT}
            value={this.state.memberData.birth}
            onChange={this.onDaypickerChange.bind(this, "birth")}
          />
        </Form.Item>
        <Form.Item
          label="電子郵件"
          name="email"
          rules={[
            { required: true, message: "請輸入電子郵件" },
            {
              pattern: /[A-Za-z0-9_.]+@[-\w]{2,63}(\.(\w){2,63})+/,
              message: "電子郵件格式錯誤",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="請輸入電子郵件"
            value={this.state.memberData.email}
            disabled={!this.props.createFlag ? true : false}
            onChange={this.onInputChange.bind(this, "email")}
          />
        </Form.Item>
        <Form.Item
          label="行動電話(台灣)"
          name="mobile"
        >
          <Input
            size="large"
            placeholder="請輸入行動電話"
            value={this.state.memberData.mobile}
            onChange={this.onInputChange.bind(this, "mobile")}
          />
        </Form.Item>
        <Row gutter="10">
          <Col xs={24} sm={6}>
            <Form.Item
              label="(日間) 區碼"
              name="dayZone"
              rules={[{ pattern: /^[0-9]+$/, message: "電話格式錯誤" }]}
            >
              <Input
                size="large"
                placeholder="請輸入區碼"
                onChange={this.onInputChange.bind(this, "dayZone")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="聯絡號碼"
              name="dayTel"
              rules={[{ pattern: /^[0-9]+$/, message: "電話格式錯誤" }]}
            >
              <Input
                size="large"
                placeholder="請輸入電話"
                onChange={this.onInputChange.bind(this, "dayTel")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <Form.Item
              label="分機"
              name="dayExt"
              rules={[{ pattern: /^[0-9]+$/, message: "電話格式錯誤" }]}
            >
              <Input
                size="large"
                placeholder="請輸入分機"
                onChange={this.onInputChange.bind(this, "dayExt")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter="10">
          <Col xs={24} sm={6}>
            <Form.Item
              label="(夜間) 區碼"
              name="nightZone"
              rules={[{ pattern: /^[0-9]+$/, message: "電話格式錯誤" }]}
            >
              <Input
                size="large"
                placeholder="請輸入區碼"
                onChange={this.onInputChange.bind(this, "nightZone")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="聯絡號碼"
              name="nightTel"
              rules={[{ pattern: /^[0-9]+$/, message: "電話格式錯誤" }]}
            >
              <Input
                size="large"
                placeholder="請輸入電話"
                onChange={this.onInputChange.bind(this, "nightTel")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <Form.Item
              label="分機"
              name="nightExt"
              rules={[{ pattern: /^[0-9]+$/, message: "電話格式錯誤" }]}
            >
              <Input
                size="large"
                placeholder="請輸入分機"
                onChange={this.onInputChange.bind(this, "nightExt")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="聯絡地址" name="contactAddress">
          <Input
            size="large"
            placeholder="請輸入聯絡地址"
            value={this.state.memberData.contactAddress}
            onChange={this.onInputChange.bind(this, "contactAddress")}
          />
        </Form.Item>
        <Form.Item label="戶籍地址" name="registeredAddress">
          <Input
            size="large"
            placeholder="請輸入戶籍地址"
            value={this.state.memberData.registeredAddress}
            onChange={this.onInputChange.bind(this, "registeredAddress")}
          />
        </Form.Item>
        <Form.Item label="直屬上級" name="yourEmployee">
          <Select
            size="large"
            placeholder="請選擇直屬上級"
            onChange={this.onSelectChange.bind(this, "yourEmployee")}
            allowClear
            optionLabelProp="label"
          >
            {this.state.managers.map((item, i) => {
              return (
                <Option
                  key={`manager-${i}`}
                  value={item.cnName}
                  label={item.cnName}
                ></Option>
              );
            })}
          </Select>
        </Form.Item>
        {this.props.createFlag ? (
          <Form.Item>
            <Checkbox defaultChecked disabled />
            傳送一封網站帳號資訊的電子郵件給新成員
          </Form.Item>
        ) : null}
        <Observer>
          {() => (
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              style={{ width: "150px" }}
              className="button-color mt"
              // onClick={this.onHandleSubmit}
              loading={sendingState.sending}
            >
              {!this.props.createFlag ? "更新個人資料" : "新增"}
            </Button>
          )}
        </Observer>
      </Form>
    ) : null;
  }
}
export default EmpolyeeForm;
