import React from "react";
import { Card, Tabs, notification, Skeleton } from "antd";
import * as Layout from "../../components/Layout";
import CustomerForm from "../../components/CustomerForm";
// import UploadFiles from "../../components/FileManager/UploadFiles";
// import NoteList from "../../components/NoteList";
import {
  getCustomerDetail,
  editCustomerData,
  apiUserForgetPassword,
} from "../../controller/api";
import { changWindowWidth } from "../../controller/action";
import { windowWidth } from "../../controller/observer";
import { toJS } from "mobx";
import { observer } from "mobx-react";
class EditCustomerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      memberData: null,
    };
    this.customerId = props.match.params.customerId;
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateWindowWidth);
    getCustomerDetail(this.customerId)
      .then((response) => {
        if (!response) {
          return;
        }

        let memberData = { ...response.data.customer };
        memberData.yourEmployee = response.data.yourEmployee;
        memberData.title = response.data.customer.title === null? undefined:response.data.customer.title
        memberData.nationality = response.data.customer.nationality === null? undefined:response.data.customer.nationality
        this.setState({
          memberData,
        });
      })
      .catch((error) => {
        console.log(error.response);
        notification["error"]({
          message: error.response.data.message,
          duration: 3,
        });
      });
  }
  updateWindowWidth = () => {
    changWindowWidth(window.innerWidth);
  };
  getResize = () => {
    let width = toJS(windowWidth.width);
    if (width <= "768") {
      return "top";
    } else {
      return "left";
    }
  };
  onSubmit = (data) => {
    let account = data.idCode;
    if (data.haveaccount) {
      delete data.idCode;
      delete data.email;
    }
    delete data.id;
    delete data.userId;
    delete data.created_at;
    delete data.updated_at;
    delete data.haveaccount;
    delete data.haveholdings;
    editCustomerData(this.customerId, { ...data }).then((response) => {
      if(!response){
        return;
      }
      if (data.enableaccount) {
        apiUserForgetPassword({ account })
          .then((res) =>
            notification["success"]({
              message: res.data.message,
              duration: 3,
            })
          )
          .catch(() =>
            notification["error"]({
              message: "認證信寄送失敗",
              duration: 3,
            })
          );
      }
      notification["success"]({
        message: response.data.message,
        duration: 3,
        onClose: () => this.props.history.push("/admin/customers"),
      });
    })
    .catch((error) => {
      console.log(error.response);
      notification["error"]({
        message: error.response.data.message,
        duration: 3,
      });
    });
  };
  render() {
    const { TabPane } = Tabs;
    return (
      <Layout.CMSLayout {...this.props}>
        <Card title="修改毅石客戶資料">
          <Tabs tabPosition={this.getResize()}>
            <TabPane tab="基本聯絡資訊" key="1">
              {this.state.memberData === null ? (
                <Skeleton active />
              ) : (
                <CustomerForm
                  createFlag={false}
                  memberData={this.state.memberData}
                  onSubmit={this.onSubmit}
                />
              )}
            </TabPane>
          </Tabs>
        </Card>
      </Layout.CMSLayout>
    );
  }
}
export default observer(EditCustomerPage);
