import React from "react";
import { Card, Row, Col, Steps, notification, Typography, Divider } from "antd";
import * as Layout from "../../components/Layout";
import CustomerForm from "../../components/CustomerForm";
import {
  createCustomerData,
  apiUserForgetPassword,
} from "../../controller/api";

class AddCustomerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      uncreated: true,
      memberData: {
        // id: "",
        name: "",
        cnName: "",
        enName: "",
        title: undefined,
        nationality: undefined,
        idCode: "",
        passport: "",
        birth: "",
        dayZone: "",
        dayTel: "",
        dayExt: "",
        nightZone: "",
        nightTel: "",
        nightExt: "",
        mobile: "",
        contactAddress: "",
        registeredAddress: "",
        email: "",
        industryType: "",
        jobType: "",
        yourEmployee: undefined,
      },
    };
  }
  onSubmit = (data) => {
    let nowValue = this.state.step + 1;
    createCustomerData({ ...data }).then((response) => {
      if (data.enableaccount) {
        apiUserForgetPassword({ account: data.idCode });
      }
      this.setState({ step: nowValue, memberData: response.data.customer });
      notification["success"]({
        message: response.data.message,
        duration: 3,
        onClose: () => this.props.history.push("/admin/customers"),
      });
    });
  };
  render() {
    const { Step } = Steps;
    const { Title } = Typography;
    return (
      <Layout.CMSLayout {...this.props}>
        <Card title="新增毅石客戶">
          <Row style={{ margin: "20px 0" }}>
            <Col xs={24} md={6}>
              <Steps direction="vertical" current={this.state.step}>
                <Step title="基本聯絡資訊" description="&nbsp;" />
              </Steps>
            </Col>
            <Col xs={24} md={18}>
              <Title level={4}>基本聯絡資訊</Title>
              <Divider />
              <CustomerForm
                createFlag={this.state.uncreated}
                memberData={this.state.memberData}
                onSubmit={this.onSubmit}
                onAddPage={true}
                goNext={this.goNext}
              />
            </Col>
          </Row>
        </Card>
      </Layout.CMSLayout>
    );
  }
}
export default AddCustomerPage;
