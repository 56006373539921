import React from "react";
import { Table, Divider, Badge } from "antd";
import { Link } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import * as Layout from "../components/Layout";
import { getMyCustomerListPage } from "../controller/api";
class CustomerListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      total: null,
    };
  }
  componentDidMount() {
    this.getCustomData(1);
  }
  getCustomData = (page) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        getMyCustomerListPage(`?page=${page}&count=10`).then((response) => {
          if (!response) {
            return;
          }
          this.setState({
            loading: false,
            total: response.data.total,
            data: response.data.mycustomers,
          });
        });
      }
    );
  };

  render() {
    const routes = [
      {
        path: "/admin",
        breadcrumbName: "首頁",
      },
      {
        path: "/customers",
        breadcrumbName: "我的客戶",
      },
    ];
    const columns = [
      {
        title: "客戶名稱",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <Link to={`/admin/customers/${record.id}`}>{text}</Link>
        ),
      },
      {
        title: "客戶姓名",
        dataIndex: "cnName",
        key: "cnName",
        responsive: ["md"],
        render: (text) => text,
      },
      {
        title: "行動電話",
        dataIndex: "mobile",
        responsive: ["md"],
        key: "mobile",
      },
      {
        title: "客戶負責人",
        dataIndex: "yourEmployee",
        key: "yourEmployee",
      },
      {
        title: "客戶帳號狀態",
        dataIndex: "haveaccount",
        key: "haveaccount",
        render: (status) =>
          status ? (
            <>
              <Badge status="success" /> 已啟用
            </>
          ) : (
            <Badge status="default" />
          ),
      },
      {
        title: "動作",
        key: "action",
        render: (text, rowData) => (
          <>
            {rowData.haveholdings ? (
              <>
                <Link to={`/admin/customers/${rowData.id}/performance`}>
                  查看績效
                </Link>
                <Divider type="vertical" />
              </>
            ) : null}

            <Link to={`/admin/customers/editCustomer/${rowData.id}`}>修改</Link>
          </>
        ),
      },
    ];

    return (
      <Layout.CMSLayout {...this.props}>
        <PageHeader
          pageTitle="我的客戶"
          routes={routes}
          extra={[
            <Link
              to="/admin/customers/addCustomer"
              key="addClient"
              type="primary"
              className="button-color"
              style={{ padding: "10px 15px", borderRadius: 5 }}
              onClick={this.AddClient}
            >
              新增客戶
            </Link>,
          ]}
        />
        <div className="main-content">
          <Table
            columns={columns}
            dataSource={this.state.data}
            loading={this.state.loading}
            rowKey="id"
            pagination={{
              position: ["bottomLeft"],
              total: this.state.total,
              onChange: (page) => this.getCustomData(page),
            }}
            scroll={{
              scrollToFirstRowOnChange: true,
              x: 600,
            }}
          />
        </div>
      </Layout.CMSLayout>
    );
  }
}
export default CustomerListPage;
