import React from "react";
import { Row, Col, notification, Skeleton } from "antd";
import EmpolyeeForm from "../../components/EmployeeForm";
import { changeSendingState } from "../../controller/action";
import { editEmployeeProfile, getEmployeeProfile } from "../../controller/api";
import { getLoginedUserId } from "../../controller/auth";
class EditEmployeeProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: null,
    };
    this.userId = getLoginedUserId();

  }
  componentDidMount() {
    getEmployeeProfile(this.userId)
      .then((response) => {
        if (!response) {
          return;
        }
        let userData = { ...response.data.employee };
        userData.yourEmployee = response.data.yourEmployee;
        userData.account = response.data.account;
        this.setState({
          userData,
        });
      })
      .catch((error) => {
        console.log(error.response);
        notification["error"]({
          message: error.response.data.message,
          duration: 3,
        });
      });
  }
  onSubmit = (data) => {
    delete data.idCode;
    delete data.email;
    delete data.account;
    editEmployeeProfile(this.userId, { ...data }).then((res) => {
      changeSendingState(false);
      notification["success"]({
        message: "更新成功",
        duration: 3,
      });
    });
  };
  render() {
    return (
      <Row style={{ margin: "20px 0" }}>
        <Col xs={24} sm={18}>
          {this.state.userData === null ? (
            <Skeleton active />
          ) : (
            <EmpolyeeForm
              createFlag={false}
              memberData={this.state.userData}
              onSubmit={this.onSubmit}
            />
          )}
        </Col>
        <Col xs={24} sm={6}></Col>
      </Row>
    );
  }
}
export default EditEmployeeProfilePage;
