import React from "react";
import {
  Row,
  Col,
  notification,
  Skeleton,
  Form,
  Input,
  Button,
} from "antd";
import moment from "moment";
import { editCustomerProfile, getCustomerProfile } from "../../controller/api";
import { getLoginedUserId } from "../../controller/auth";
class EditCustomerProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: null,
    };
    this.userId = getLoginedUserId();
  }
  componentDidMount() {
    getCustomerProfile(this.userId)
      .then((response) => {
        if (!response) {
          return;
        }
        let userData = { ...response.data.customer };
        this.setState({
          userData,
        });
      })
      .catch((error) => {
        console.log(error.response);
        notification["error"]({
          message: error.response.data.message,
          duration: 3,
        });
      });
  }
  onInputChange = (name, e) => {
    const userData = { ...this.state.userData };
    userData[name] = e.target.value;
    this.setState({ userData });
  };
  getInitialValues = () => {
    const userData = { ...this.state.userData };
    userData.birth =
      userData.birth === ""
        ? undefined
        : moment(userData.birth, process.env.REACT_APP_DATE_FORMAT);

    return userData;
  };
  onHandleSubmit = () => {
    const state = this.state.userData;
    delete state.idCode;
    delete state.email;
    editCustomerProfile(this.userId, { ...state }).then((response) => {
      notification["success"]({
        message: response.data.message,
        duration: 3,
      });
    });
  };
  render() {
    return (
      <Row style={{ margin: "20px 0" }}>
        <Col xs={24} sm={18}>
          {this.state.userData === null ? (
            <Skeleton active />
          ) : (
            <Form
              name="customerForm"
              layout="vertical"
              onFinish={this.onHandleSubmit}
              initialValues={this.getInitialValues()}
              scrollToFirstError={true}
            >
              <Form.Item label="UID" name="id">
                <Input
                  size="large"
                  disabled={true}
                  value={this.state.userData.id}
                />
              </Form.Item>
              <Form.Item
                label="中文姓名"
                name="cnName"
                rules={[{ required: true, message: "請輸入中文姓名" }]}
              >
                <Input
                  size="large"
                  placeholder="請輸入中文姓名"
                  value={this.state.userData.cnName}
                  onChange={this.onInputChange.bind(this, "cnName")}
                />
              </Form.Item>
              <Form.Item label="英文名" name="enName">
                <Input
                  size="large"
                  placeholder="請輸入英文名"
                  value={this.state.userData.enName}
                  onChange={this.onInputChange.bind(this, "enName")}
                />
              </Form.Item>
              <Row gutter="10">
                <Col xs={24} sm={12}>
                  <Form.Item label="身分證字號" name="idCode">
                    <Input
                      size="large"
                      placeholder="請輸入身分證字號"
                      value={this.state.userData.idCode}
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="護照號碼" name="passport">
                    <Input
                      size="large"
                      placeholder="請輸入護照號碼"
                      value={this.state.userData.passport}
                      // disabled={!this.props.createFlag ? true : false}
                      onChange={this.onInputChange.bind(this, "passport")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="電子郵件"
                name="email"
              >
                <Input
                  size="large"
                  placeholder="請輸入電子郵件"
                  value={this.state.userData.email}
                  disabled={!this.props.createFlag ? true : false}
                  onChange={this.onInputChange.bind(this, "email")}
                />
              </Form.Item>
              <Form.Item
                label="行動電話(台灣)"
                name="mobile"
                // rules={[{ required: true, message: "請輸入行動電話" }]}
              >
                <Input
                  size="large"
                  placeholder="請輸入行動電話"
                  value={this.state.userData.mobile}
                  onChange={this.onInputChange.bind(this, "mobile")}
                />
              </Form.Item>
              <Row gutter="10">
                <Col xs={24} sm={6}>
                  <Form.Item label="(日間) 區碼" name="dayZone">
                    <Input
                      size="large"
                      placeholder="請輸入區碼"
                      onChange={this.onInputChange.bind(this, "dayZone")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="聯絡號碼" name="dayTel">
                    <Input
                      size="large"
                      placeholder="請輸入電話"
                      onChange={this.onInputChange.bind(this, "dayTel")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6}>
                  <Form.Item label="分機" name="dayExt">
                    <Input
                      size="large"
                      placeholder="請輸入分機"
                      onChange={this.onInputChange.bind(this, "dayExt")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="10">
                <Col xs={24} sm={6}>
                  <Form.Item label="(夜間) 區碼" name="nightZone">
                    <Input
                      size="large"
                      placeholder="請輸入區碼"
                      onChange={this.onInputChange.bind(this, "nightZone")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="聯絡號碼" name="nightTel">
                    <Input
                      size="large"
                      placeholder="請輸入電話"
                      onChange={this.onInputChange.bind(this, "nightTel")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6}>
                  <Form.Item label="分機" name="nightExt">
                    <Input
                      size="large"
                      placeholder="請輸入分機"
                      onChange={this.onInputChange.bind(this, "nightExt")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="聯絡地址" name="contactAddress">
                <Input
                  size="large"
                  placeholder="請輸入聯絡地址"
                  value={this.state.userData.contactAddress}
                  onChange={this.onInputChange.bind(this, "contactAddress")}
                />
              </Form.Item>
              <Form.Item label="戶籍地址" name="registeredAddress">
                <Input
                  size="large"
                  placeholder="請輸入戶籍地址"
                  value={this.state.userData.registeredAddress}
                  onChange={this.onInputChange.bind(this, "registeredAddress")}
                />
              </Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                style={{ minWidth: "150px" }}
                className="button-color mt"
              >
                更新帳戶資料
              </Button>
            </Form>
          )}
        </Col>
        <Col xs={24} sm={6}></Col>
      </Row>
    );
  }
}
export default EditCustomerProfilePage;
