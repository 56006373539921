import React from "react";
import { Form, Button, Space, List, Input, Typography } from "antd";
import NoteEditDialog from "./NoteEditDialog";
import { noteDialogVisible,windowWidth } from "../controller/observer";
import { changWindowWidth,changeNoteDialogVisible } from "../controller/action";
import { Observer, observer } from "mobx-react";
import { toJS } from "mobx";
import { getNoteList, createNote, deleteNote } from "../controller/api";
import moment from "moment";
const { TextArea } = Input;
class NoteList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      itemLayout: "horizontal",
      message: "",
      noteList: [],
      currentEditMessage: null,
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateWindowWidth);
    this.getNoteList();
  }
  updateWindowWidth = () => {
    changWindowWidth(window.innerWidth)
  };
  getResize = () => {
    let width = toJS(windowWidth.width)
    if (width <= "768") {
      return "vertical";
    } else {
      return "horizontal";
    }
  };
  getNoteList = () => {
    getNoteList(this.props.customerId).then((res) => {
      this.setState({ loading: false, noteList: res.data.note });
    });
  };
  onTextareaChange = (e) => {
    this.setState({ message: e.target.value });
  };
  showDialog = (item) => {
    this.setState({
      currentEditMessage: { desciption: item.description, id: item.id },
    });
    changeNoteDialogVisible(true);
  };
  onSubmit = () => {
    const data = {
      description: this.state.message,
      customerId: this.props.customerId,
    };
    this.setState({ loading: true }, () => {
      createNote(data).then((res) => {
        this.getNoteList();
        this.setState({ message: "" });
      });
    });
  };
  onDelete = (item) => {
    this.setState({ loading: true }, () => {
      deleteNote(item.id).then((res) => this.getNoteList());
    });
  };
  render() {
    return (
      <>
        <List
          loading={this.state.loading}
          dataSource={this.state.noteList}
          itemLayout={this.getResize()}
          style={{ padding: "0 10px 15px 10px" }}
          renderItem={(item) =>
            this.state.noteList.length < 1 ? null : (
              <List.Item
                actions={[
                  <Typography.Link key="list-edit" onClick={this.showDialog.bind(this, item)}>
                    修改
                  </Typography.Link>,
                  <Typography.Link key="list-delete" onClick={this.onDelete.bind(this, item)}>
                    刪除
                  </Typography.Link>,
                ]}
              >
                <List.Item.Meta
                  title={
                    <Space>
                      <Typography.Text strong>{item.editor}</Typography.Text>
                      <Typography.Text type="secondary">
                        {moment(item.updated_at).format(process.env.REACT_APP_DATE_FORMAT+" A h:mm:ss")}
                      </Typography.Text>
                    </Space>
                  }
                  description={item.description}
                />
              </List.Item>
            )
          }
        />
        <Form onFinish={this.onSubmit}>
          <TextArea
            value={this.state.message}
            onChange={this.onTextareaChange}
            rows={4}
            placeholder="請輸入內容新增筆記"
          />
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            style={{ minWidth: "150px" }}
            className="button-color mt"
            disabled={
              this.state.message !== "" && this.state.message.trim()
                ? false
                : true
            }
          >
            送出
          </Button>
        </Form>
        <Observer>
          {() =>
            this.state.currentEditMessage === null ? null : (
              <NoteEditDialog
                visible={noteDialogVisible.visible}
                currentMessage={this.state.currentEditMessage.desciption}
                noteId={this.state.currentEditMessage.id}
                refresh={this.getNoteList}
              />
            )
          }
        </Observer>
      </>
    );
  }
}
export default observer(NoteList);
