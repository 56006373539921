import React from 'react'
import LoginPage from './containers/LoginPage'
import ForgetPage from './containers/ForgetPage'
import ResetPasswordPage from './containers/ResetPasswordPage'
import EmployeeListPage from './containers/EmployeeListPage'
import EmployeeDetailPage from './containers/Employee/EmployeeDetailPage'
import AddEmployeePage from './containers/Employee/AddEmployeePage'
import EditEmployeePage from './containers/Employee/EditEmployeePage'
import CustomerListPage from './containers/CustomerListPage'
import CustomerDetailPage from './containers/Customer/CustomerDetailPage'
import AddCustomerPage from './containers/Customer/AddCustomerPage'
import EditCustomerPage from './containers/Customer/EditCustomerPage'
import SettingPage from './containers/SettingPage'
import CustomerPerformanceOverviewPage from './containers/Customer/CustomerPerformanceOverviewPage';
import MyPerformanceOverviewPage from './containers/Customer/MyPerformanceOverviewPage';
import CustomerHoldingsPage from './containers/Administration/CustomerHoldingsPage'
import HoldingsListPage from './containers/Administration/HoldingsListPage'
import CustomerPerformancesPage from './containers/Administration/CustomerPerformancesPage'
import PerformanceListPage from './containers/Administration/PerformanceListPage'
import CreateBatchPerformanceDetailPageV2 from './containers/Administration/CreateBatchPerformanceDetailPageV2'
import CreateBatchTransactionDetailPage from './containers/Administration/CreateBatchTransactionDetailPage'
import { UserOutlined, DollarOutlined, FileTextOutlined } from "@ant-design/icons";

export const LoginPageRoutes = [
  {
    key: "login",
    component: LoginPage,
    path: "/",
  },
  {
    key: "forget",
    component: ForgetPage,
    path: "/forget",
  },
  {
    key: "resetpassword",
    component: ResetPasswordPage,
    path: "/resetPassword/:token",
  }
]

export const AdminPageRoutes = [
  // {
  //   key: "homepage",
  //   title: '首頁',
  //   icon: <HomeOutlined style={{ color: "#fff" }} />,
  //   component: Homepage,
  //   roles: ["admin", "employee", "customer"],
  //   path: "/admin",
  //   hidden: false,
  //   showChildren: false,
  // },
  {
    key: "settings",
    title: '個人資料設定',
    icon: <UserOutlined style={{ color: "#fff" }} />,
    component: SettingPage,
    roles: ["admin", "employee", "customer"],
    path: "/admin/settingPage",
    hidden: true,
  },
  {
    key: "performance",
    title: '我的投資績效',
    icon: <DollarOutlined style={{ color: "#fff" }} />,
    component: MyPerformanceOverviewPage,
    roles: ["customer"],
    path: "/admin/performance",
    hidden: false,
    showChildren: false,
  },
  {
    key: "customers",
    title: '我的客戶',
    icon: <UserOutlined style={{ color: "#fff" }} />,
    component: CustomerListPage,
    roles: ["admin", "employee"],
    path: "/admin/customers",
    hidden: false,
    children: [
      {
        key: "customer-add",
        title: '新增客戶',
        icon: null,
        component: AddCustomerPage,
        roles: ["admin", "employee"],
        path: "/admin/customers/addCustomer",
        hidden: true,
      },
      {
        key: "customers-edit",
        title: '編輯客戶',
        icon: null,
        component: EditCustomerPage,
        roles: ["admin", "employee"],
        path: "/admin/customers/editCustomer/:customerId",
        hidden: true,
      },
      {
        key: "customer-view",
        title: '查看客戶',
        icon: null,
        component: CustomerDetailPage,
        roles: ["admin", "employee"],
        path: "/admin/customers/:customerId",
        hidden: true,
      },
      {
        key: "customer-performance",
        title: '查看客戶績效',
        icon: null,
        component: CustomerPerformanceOverviewPage,
        roles: ["admin", "employee"],
        path: "/admin/customers/:customerId/performance",
        hidden: true,
      },
    ]
  },
  {
    key: "employees",
    title: '毅石成員',
    icon: <UserOutlined style={{ color: "#fff" }} />,
    component: EmployeeListPage,
    roles: ["admin", "employee"],
    path: "/admin/employees",
    hidden: false,
    children: [
      {
        key: "employees-add",
        title: '新增成員',
        icon: null,
        component: AddEmployeePage,
        roles: ["admin", "employee"],
        path: "/admin/employees/addEmployee",
        hidden: true,
      },
      {
        key: "employees-view",
        title: '查看成員',
        icon: null,
        component: EmployeeDetailPage,
        roles: ["admin", "employee"],
        path: "/admin/employees/:id",
        hidden: true,
      },
      {
        key: "employees-edit",
        title: '編輯成員',
        icon: null,
        component: EditEmployeePage,
        roles: ["admin", "employee"],
        path: "/admin/employees/editEmployee/:id",
        hidden: true,
      },
    ]
  },
  {
    key: "administration",
    title: '行政作業',
    icon: <FileTextOutlined style={{ color: "#fff" }} />,
    roles: ["admin", "employee"],
    // path: "/admin/administration",
    // component: CustomerHoldingsPage,
    hidden: false,
    children: [
      {
        default: true,
        key: "customerholdings",
        title: '持有資訊',
        icon: null,
        component: CustomerHoldingsPage,
        roles: ["admin", "employee"],
        path: "/admin/administration/customerholdings",
        hidden: false,
      },
      {
        key: "holdingaccounts",
        title: '資金帳戶',
        icon: null,
        component: HoldingsListPage,
        roles: ["admin", "employee"],
        path: "/admin/administration/customerholdings/:customerId",
        hidden: true,
      },
      {
        key: "createbatchtransactiondetail",
        title: '交易批次新增',
        icon: null,
        component: CreateBatchTransactionDetailPage,
        roles: ["admin", "employee"],
        path: "/admin/administration/customerholdings/:customerId/batch-create",
        hidden: true,
      },
      {
        key: "customerperformances",
        title: '績效明細',
        icon: null,
        component: CustomerPerformancesPage,
        roles: ["admin", "employee"],
        path: "/admin/administration/customerperformances",
        hidden: false,
      },
      {
        key: "customerperformancesDetail",
        title: '客戶績效明細',
        icon: null,
        component: PerformanceListPage,
        roles: ["admin", "employee"],
        path: "/admin/administration/customerperformances/:customerId",
        hidden: true,
      },
      {
        key: "createbatchustomerperformances",
        title: '績效批次新增',
        icon: null,
        component: CreateBatchPerformanceDetailPageV2,
        roles: ["admin", "employee"],
        path: "/admin/administration/customerperformances/:customerId/create",
        hidden: true,
      },
    ]
  },
]