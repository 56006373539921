import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { AdminPageRoutes } from "../PageRoutes";
import { HomeOutlined } from "@ant-design/icons";
import { haveRoles } from "../controller/auth";
function MainMenu(props) {
  const pathArray = props.path.split("/");
  const onMenuSelect = () => {
    // 找第一層路徑是否有符合的
    for (let route of AdminPageRoutes) {
      if (route.path === props.path) {
        return route.key;
      }
      // 若有子選單就再比對子選單的路徑
      if (route.children) {
        let childrens = route.children;
        for (let children of childrens) {
          let path = children.path;
          let parentPath = path.substr(0, path.indexOf(":")-1);
          let propsPath = props.path;
          let propsParentPath = propsPath.substr(0, propsPath.lastIndexOf("/"));
          let parent = childrens.find((children) => children.path === parentPath);
          if (path === props.path) {
            return children.key;
          }
          if (parent && parent.path === propsParentPath) {
            return parent.key;
          }
        }
      }
    }
  };

  const onMenuOpen = () =>
    pathArray.includes("administration") ? ["administration"] : [];
  return (
    <Menu
      mode="inline"
      className="menu"
      theme="dark"
      selectedKeys={onMenuSelect()}
      defaultOpenKeys={onMenuOpen()}
    >
      <Menu.Item
        key="homepage"
        icon={<HomeOutlined style={{ color: "#fff" }} />}
      >
        <Link to="/admin">首頁</Link>
      </Menu.Item>
      {AdminPageRoutes.map((route) => {
        if (route.hidden) {
          return null;
        }
        let isAllChildrenHidden = (childrens) => {
          if (!childrens) return true;
          let result = true;
          childrens.forEach((children) => {
            if (children.hidden === false) result = false;
          });
          return result;
        };
        return !isAllChildrenHidden(route.children) ? (
          <Menu.SubMenu
            key={route.key}
            icon={route.icon}
            style={{ display: haveRoles(route.roles) ? "block" : "none" }}
            children={route.children.map((child) => {
              if (child.hidden) {
                return null;
              }
              return (
                <Menu.Item
                  key={child.key}
                  icon={child.icon}
                  style={{
                    display: haveRoles(child.roles) ? "block" : "none",
                  }}
                >
                  <Link to={child.path}>{child.title}</Link>
                </Menu.Item>
              );
            })}
            title={
              route.path ? (
                <Link to={route.path}>{route.title}</Link>
              ) : (
                route.title
              )
            }
          />
        ) : (
          <Menu.Item
            key={route.key}
            icon={route.icon}
            style={{ display: haveRoles(route.roles) ? "block" : "none" }}
          >
            <Link to={route.path}>{route.title}</Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );
}

export default MainMenu;
