import React from "react";
import { Form, Input, Button, Typography, notification } from "antd";
import * as Layout from "../components/Layout";
import {
  apiUserGetPasswordToken,
  apiUserResetPassword,
} from "../controller/api";
class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      newPassword: "",
      confirmPassword: "",
      response: null,
    };
  }
  componentDidMount() {
    apiUserGetPasswordToken(this.props.match.params.token)
      .then((res) => {
        let response = res.data[0];
        this.setState({ response });
      })
      .catch((err) => {
        console.log(err.response);
        this.setState({ loading: false });
        notification["error"]({
          message: err.response.data.message,
          duration: 3,
          onClose: () => this.props.history.push("/"),
        });
      });
  }
  onInputChange = (name, e) => {
    const state = { ...this.state };
    state[name] = e.target.value;
    this.setState(state);
  };
  onSubmit = () => {
    this.setState({ loading: true }, () => {
      let newPassword = this.state.newPassword;
      let confirmPassword = this.state.confirmPassword;
      let data = {
        password: newPassword,
        password_confirmation: confirmPassword,
        email: this.state.response.email,
        token: this.state.response.token,
      };
      apiUserResetPassword(data)
        .then(() => {
          this.setState({ loading: false });
          notification["success"]({
            message: "您已成功更新密碼，請重新登入。",
            duration: 3,
            onClose: () => this.props.history.push("/"),
          });
        })
        .catch((err) => {
          console.log(err.response);
          this.setState({ loading: false });
          notification["error"]({
            message: err.response.data.message,
            duration: 3,
            onClose: () => this.props.history.push("/"),
          });
        });
    });
  };
  render() {
    const { Title } = Typography;
    return (
      <Layout.VertifyLayout {...this.props}>
        <Title
          level={3}
          style={{ color: "#fff", textAlign: "center", marginBottom: "8%" }}
        >
          設定新密碼
          <br />
          即可開始使用毅石資訊整合平台
        </Title>
        <Form layout="vertical" onFinish={this.onSubmit}>
          <Form.Item
            label="請輸入新密碼"
            name="newPassword"
            rules={[
              { required: true, message: "請新輸入密碼" },
              { min: 8, message: "最少 8 碼" },
            ]}
          >
            <Input.Password
              size="large"
              placeholder="請點擊開始輸入"
              value={this.state.newPassword}
              onChange={this.onInputChange.bind(this, "newPassword")}
            />
          </Form.Item>
          <Form.Item
            label="再次輸入新密碼"
            name="confirmPassword"
            rules={[
              { required: true, message: "請輸入密碼" },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("密碼不一樣，請重新輸入");
                },
              }),
            ]}
          >
            <Input.Password
              size="large"
              placeholder="請點擊開始輸入"
              value={this.state.confirmPassword}
              onChange={this.onInputChange.bind(this, "confirmPassword")}
            />
          </Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            block
            className="button-color mt"
            loading={this.state.loading}
          >
            完成設定
          </Button>
        </Form>
      </Layout.VertifyLayout>
    );
  }
}
export default ResetPasswordPage;
