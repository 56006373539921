import React from "react";
import PageHeader from "../components/PageHeader";
import * as Layout from "../components/Layout";
class HomePage extends React.Component {
  componentDidMount(){
    this.props.history.replace('/admin')
  }
  render() {
    const routes = [
      {
        path: "/admin",
        breadcrumbName: "首頁",
      },
    ];
    return (
      <Layout.CMSLayout { ...this.props }>
        <PageHeader
          pageTitle="歡迎使用毅石資訊整合平台"
          children=" 毅石秉持最審慎敬業的態度，針對高端家族提供專業的財富與資本管理，達到家族財富傳承、企業永續經營的目的，並同時符合ESG的標竿。"
          routes={routes}
        />
      </Layout.CMSLayout>
    );
  }
}
export default HomePage;
