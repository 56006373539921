import React from "react";
import { Typography, Form, Input, Button, notification } from "antd";
import * as Layout from "../components/Layout";
import { LeftOutlined } from "@ant-design/icons";
import { createBrowserHistory } from "history";
import { apiUserForgetPassword } from "../controller/api";

const history = createBrowserHistory();
const goBack = () => {
  history.goBack();
};
class ForgetPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      account: "",
    };
  }
  onInputChange = (name, e) => {
    const state = { ...this.state };
    state[name] = e.target.value;
    this.setState(state);
  };
  onSubmit = () => {
    if (this.state.account !== "") {
      this.setState({loading:true},()=>{
      apiUserForgetPassword({ account: this.state.account })
      .then((res) => {
        this.setState({loading:false})
        notification["success"]({
          message: "已寄信到您註冊的信箱，請至您的信箱收信",
          duration: 3,
          onClose: () => this.props.history.push("/"),
        });
      })
      .catch(err=>{
        console.log(err)
        this.setState({loading:false})
        notification["error"]({
          message: err.response.data.message,
          duration: 3,
          onClose: () => this.props.history.push("/"),
        });
      });
    })
  }
  };
  render() {
    return (
      <Layout.LoginLayout {...this.props}>
        <Typography.Link onClick={goBack} style={{ position: "absolute", left: 20, top: 20 }}>
          <LeftOutlined style={{ fontSize: 25, color: "#fff" }} />
        </Typography.Link>
        <Typography.Title
          level={3}
          style={{ color: "#fff", textAlign: "center", marginBottom: "8%" }}
        >
          忘記密碼
        </Typography.Title>
        <Form layout="vertical" onFinish={this.onSubmit}>
          <Form.Item
            label="帳號"
            name="account"
            rules={[{ required: true, message: "請輸入身份證字號或帳號" }]}
          >
            <Input
              size="large"
              placeholder="請輸入身份證字號或帳號"
              value={this.state.account}
              onChange={this.onInputChange.bind(this, "account")}
            />
          </Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            block
            className="button-color mt"
            loading={this.state.loading}
          >
            寄送認證信
          </Button>
        </Form>
      </Layout.LoginLayout>
    );
  }
}
export default ForgetPage;
