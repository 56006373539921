import React from "react";
import { Modal, Typography, notification } from "antd";
import { deleteCategory } from "../../controller/api";
import { changeUploadListVisible } from "../../controller/action";
class DeleteCategoryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  handleOk = () => {
    this.setState({ loading: true }, () => {
      deleteCategory(this.props.category.id)
        .then((response) => {
          this.setState({ loading: false }, () => {
            notification["success"]({
              message: response.data.message,
              duration: 3,
              onClose: () => {
                this.props.changeModalVisible();
                changeUploadListVisible(false, null, "");
              },
            });
          });
        })
        .catch((err) => {
          this.setState({ loading: false })
          console.log(err);
        });
    });
  };
  handleCancel = () => {
    this.props.changeModalVisible();
  };
  render() {
    return (
      <Modal
        title={`您正在刪除${this.props.category.name}`}
        visible={this.props.visible}
        destroyOnClose={true}
        okText="刪除"
        cancelText="取消"
        onOk={this.handleOk}
        okButtonProps={{ loading: this.state.loading, type: "danger" }}
        onCancel={this.handleCancel}
      >
        您確定要刪除{" "}
        <Typography.Text type="warning">
          {this.props.category.name}
        </Typography.Text>
        ，以及底下所有內容？
      </Modal>
    );
  }
}
export default DeleteCategoryModal;
