import { notification } from 'antd';
import axios from 'axios';
import { getLoginedToken, getLoginedTokenType, login, logout, tokenIsExpired } from "./auth";
const isDevEnv = process.env.NODE_ENV === "development"
// const APIRoot = isDevEnv ? '//localhost/api' : `${window.location.origin}/api`,
const APIRoot = isDevEnv ? process.env.REACT_APP_API_DOMAIN : `${window.location.origin}/api`
// 未登入
const notTokenRequest = axios.create({
  baseURL: APIRoot,
  validateStatus: (status) => status >= 200 && status < 300
});
// Add a response interceptor
notTokenRequest.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (typeof error.response === 'undefined') {
    console.error('A network error occurred. '
      + 'This could be a CORS issue or a dropped internet connection. '
      + 'It is not possible for us to know.')
  }
  // console.log(error.response)
  return Promise.reject(error)
});
export const apiUserLogin = data => notTokenRequest.post('/auth/login', data);
export const apiUserForgetPassword = data => notTokenRequest.post('/password/create', data);
export const apiUserGetPasswordToken = token => notTokenRequest.get(`/password/find/${token}`);
export const apiUserResetPassword = data => notTokenRequest.post('/password/reset', data);

// 登入後
const tokenRequest = axios.create({
  baseURL: APIRoot,
  validateStatus: (status) => status >= 200 && status < 300
});
var isRefreshingToken = false
// request interceptor
tokenRequest.interceptors.request.use(async config => {
  const oldToken = getLoginedToken();
  const oldTokenType = getLoginedTokenType();
  config.headers['Authorization'] = `${oldTokenType} ${oldToken}`

  // 檢查是否 Renew Access Token (預設 1 小時)
  if (tokenIsExpired()) {
    if (isRefreshingToken) {
      do {
        console.log("Token is refreshing, waiting...")
        await new Promise((resolve) => setTimeout(() => resolve(true), 100))
      } while(isRefreshingToken);
      const newToken = getLoginedToken();
      const newTokenType = getLoginedTokenType();
      console.log('Got new token', newToken);
      config.headers['Authorization'] = `${newTokenType} ${newToken}`;
      return config;
    } else {
      console.log("Refreshing token ...", config);
    }
    // Renew Access Token
    try {
      isRefreshingToken = true
      const data = {};
      const requestConfig = { headers: { Authorization: `${oldTokenType} ${oldToken}` }};
      const res = await axios.post(`${APIRoot}/auth/token/refresh`, data, requestConfig);
      const newTokenType = res.data.token_type;
      const newToken = res.data.token;
      const userId = res.data.id;
      const roles = res.data.roles;
      const expiresIn =  res.data.expires_in;
      console.log("Got new token", newToken)
  
      login(newTokenType, newToken, userId, roles, expiresIn);
      config.headers['Authorization'] = `${newTokenType} ${newToken}`;
      return config;
    } catch (error) {
      // Renew 失敗，代表超出 Refresh TTL (預設 2 週) 則自動登出
      notification.error({
        message: "您登入超時，請重新登入",
        duration: 3,
        onClose: () => {
          window.location.href = '/';
          logout();
        }
      });
      console.warn("Token expired. redirect to login page.");
      console.warn(error.response ? error.response.data.message:error);
      return config;
    } finally {
      isRefreshingToken = false
    }
  }
  return config;
});

// 基本資料
export const getCustodianData = () => tokenRequest.get(`/custodians`)
export const getAuthorizationmodes = () => tokenRequest.get(`/authorizationmodes`)
export const getAssets = () => tokenRequest.get(`/assets`)
export const getAllManagers = () => tokenRequest.get('/manager')
export const checkIdcode = data => tokenRequest.post('/checkIdCode', data)

// 帳戶資訊
export const editPassword = data => tokenRequest.post('/auth/reset/password', data)

// 員工基本資料
export const getEmployeeProfile = (userId) => tokenRequest.get(`/employee/profile/${userId}`)
export const editEmployeeProfile = (userId, data) => tokenRequest.put(`/employee/profile/${userId}`, data)

// 客戶基本資料
export const getCustomerProfile = (userId) => tokenRequest.get(`/customer/profile/${userId}`)
export const editCustomerProfile = (userId, data) => tokenRequest.put(`/customer/profile/${userId}`, data)

// 員工管理
export const getEmployeeList = () => tokenRequest.get('/employees')
export const getEmployeeListPage = page => tokenRequest.get(`/employees?page=${page}`)
export const getEmployeeDetail = employeeId => tokenRequest.get(`/employees/${employeeId}`)
export const createEmployeeData = data => tokenRequest.post(`/employees`, data)
export const editEmployeeData = (employeeId, data) => tokenRequest.put(`/employees/${employeeId}`, data)

// 客戶管理
export const getMyCustomerListPage = (parameter) => tokenRequest.get(`/mycustomers${parameter}`)
export const getCustomerList = () => tokenRequest.get(`/customers`)
export const getCustomerListPage = (parameter) => tokenRequest.get(`/customers${parameter}`)
export const getCustomerDetail = customerId => tokenRequest.get(`/customers/${customerId}`)
export const createCustomerData = data => tokenRequest.post(`/customers`, data)
export const editCustomerData = (customerId, data) => tokenRequest.put(`/customers/${customerId}`, data)

// 客戶檔案管理
export const getCategoryList = (customerId) => tokenRequest.get(`/customerfilescategories?customerId=${customerId}`)
export const createCategory = (data) => tokenRequest.post(`/customerfilescategories`, data)
export const editCategory = (categoryId, data) => tokenRequest.put(`/customerfilescategories/${categoryId}`, data)
export const deleteCategory = (categoryId) => tokenRequest.delete(`/customerfilescategories/${categoryId}`)
export const getUploadFiles = (categoryId) => tokenRequest.get(`/customerfilescategories/${categoryId}`)
export const fileUpload = (data, config) => tokenRequest.post('/customerfiles', data, config)
export const editFile = (categoryId, data) => tokenRequest.put(`/customerfiles/${categoryId}`, data)
export const moveFile = (data) => tokenRequest.post(`/customerfiles/move`, data)
export const deleteFile = (fileId) => tokenRequest.delete(`/customerfiles/${fileId}`)

// 客戶筆記
export const createNote = data => tokenRequest.post('/customernotes', data)
export const getNoteList = (customerId) => tokenRequest.get(`/customernotes/${customerId}`)
export const editNote = (noteId, data) => tokenRequest.put(`/customernotes/${noteId}`, data)
export const deleteNote = (noteId) => tokenRequest.delete(`/customernotes/${noteId}`)

// 我的績效
// TODO 待移除 userId 欄位
export const getMyPerformanceOveriew = (userId, parameter) => tokenRequest.get(`/performancesoverview/my?${parameter}`)
export const getMyPerformanceTrend = (userId, parameter) => tokenRequest.get(`/performancesoverview/my/trend?${parameter}`)
export const getMyPerformanceDetail = (userId, parameter) => tokenRequest.get(`/performancesoverview/my/detail?${parameter}`)
export const getMyPerformanceAssets = () => tokenRequest.get(`/performancesoverview/my/assets`)
export const getMyPerformanceFundaccounts = () => tokenRequest.get(`/performancesoverview/my/fundaccounts`)

// 客戶績效
export const getCustomerPerformanceOveriew = (customerId, parameter) => tokenRequest.get(`/performancesoverview/customer/${customerId}?${parameter}`)
export const getCustomerPerformanceTrend = (customerId, parameter) => tokenRequest.get(`/performancesoverview/customer/${customerId}/trend?${parameter}`)
export const getCustomerPerformanceDetail = (customerId, parameter) => tokenRequest.get(`/performancesoverview/customer/${customerId}/detail?${parameter}`)
export const getCustomerPerformanceAssets = (customerId) => tokenRequest.get(`/performancesoverview/customer/${customerId}/assets`)
export const getCustomerPerformanceFundaccounts = (customerId) => tokenRequest.get(`/performancesoverview/customer/${customerId}/fundaccounts`)

// 帳戶資訊
export const getAccountsTotal = (customerid) => tokenRequest.get(`/customers/${customerid}/fundaccounts/count`)
export const getAccounts = (customerid, parameter) => tokenRequest.get(`/customers/${customerid}/fundaccounts${parameter}`)
export const getAccountDetail = (fundaccountid) => tokenRequest.get(`/fundaccounts/${fundaccountid}`)
export const createFundAccountTransfer = (customerid, data) => tokenRequest.post(`/customers/${customerid}/fundaccounts`, data)
export const createFundAccount = (data) => tokenRequest.post(`/fundaccounts`, data)
export const editFundAccount = (fundaccountid, data) => tokenRequest.put(`/fundaccounts/${fundaccountid}`, data)
export const deleteFundAccount = (fundaccountid) => tokenRequest.delete(`/fundaccounts/${fundaccountid}`)
export const getFundAccountAssets = (fundaccountid) => tokenRequest.get(`/fundaccounts/${fundaccountid}/holdings/assets`)

// 商品持有資訊
export const createHoldings = (data) => tokenRequest.post(`/holdings`, data)
export const getHoldingsTotal = (fundaccountid) => tokenRequest.get(`/fundaccounts/${fundaccountid}/holdings/count`)
export const getHoldings = (fundaccountid, parameter) => tokenRequest.get(`/fundaccounts/${fundaccountid}/holdings${parameter}`)
export const getHoldingDetail = (holdingid) => tokenRequest.get(`/holdings/${holdingid}`)
export const deleteHoldings = (holdingid) => tokenRequest.delete(`/holdings/${holdingid}`)
export const updateHoldingPresentValue = (holdingid, data) => tokenRequest.patch(`/holdings/${holdingid}/presentvalue`, data)

// 商品持有資訊-績效明細
export const createBatchPerformances = (data) => tokenRequest.post(`/performances/batch`, data)
export const createPerformances = (data) => tokenRequest.post(`/performances`, data)
export const deletePerformances = (performancesid) => tokenRequest.delete(`/performances/${performancesid}`)
export const getCurrencies = () => tokenRequest.get('/currencies/')
export const getPerformanceHistoriesTotal = (holdingid) => tokenRequest.get(`/holdings/${holdingid}/performances/count`)
export const getPerformanceHistories = (holdingid, parameter) => tokenRequest.get(`/holdings/${holdingid}/performances${parameter}`)

// 商品持有資訊-交易明細
export const getHoldingTranscations = (holdingid) => tokenRequest.get(`/holdings/${holdingid}/transactions`)
export const createHoldingTranscations = (holdingid, data) => tokenRequest.post(`/holdings/${holdingid}/transactions`, data)
export const createTranscations = (data) => tokenRequest.post('/transactions', data)
export const deleteTranscations = (transactionid) => tokenRequest.delete(`/transactions/${transactionid}`);

export const createBatchTranscations = (holdingid, data) => tokenRequest.post(`/holdings/${holdingid}/transactions/batch`, data)
