import React from "react";
import { Modal, Input, Typography, notification } from "antd";
import { editFile } from "../../controller/api";
class FileEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      file: props.file,
      changedName: "",
    };
  }
  onInputChange = (e) => {
    this.setState({ changedName: e.target.value });
  };
  handleOk = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        editFile(this.state.file.uid, { fileName: this.state.changedName })
          .then((response) => {
            this.setState({ loading: false }, () => {
              notification["success"]({
                message: response.data.message,
                duration: 3,
                onClose: () => {
                  this.props.changeModalVisible();
                  this.props.refreshList();
                },
              });
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };
  handleCancel = () => {
    this.props.changeModalVisible();
  };
  render() {
    return (
      <Modal
        title="修改檔案名稱"
        visible={this.props.visible}
        destroyOnClose={true}
        okText="確定"
        cancelText="取消"
        onOk={this.handleOk}
        okButtonProps={{
          className:"button-color",
          loading: this.state.loading,
          disabled: this.state.changedName !== "" || this.state.changedName.trim() ? false : true,
        }}
        onCancel={this.handleCancel}
      >
        <Typography.Text>
          您正在修改檔案 &nbsp;
          <Typography.Text type="warning">
            {this.state.file.name}
          </Typography.Text>
        </Typography.Text>
        <Input
          style={{ marginTop: 5 }}
          onChange={this.onInputChange}
          placeholder="請輸入檔案名稱"
        />
      </Modal>
    );
  }
}
export default FileEditModal;
