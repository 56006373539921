import React from "react";
import {  Row, Col, Typography } from "antd";
import NumberFormat from "react-number-format";
import moment from "moment";
import RorTag from "../RorTag";
class PerformanceOverview extends React.Component {
  render() {
    return (
      <>
        <Typography.Title
          level={4}
          style={{ fontWeight: "normal", marginBottom: 20 }}
        >
          投資總績效 ( 截至：
          {moment(this.props.overview.asof).format(process.env.REACT_APP_DATE_FORMAT)} )
        </Typography.Title>
        <Row gutter={[16, 16]}>
          <Col sm={8} lg={6}>
            <Typography.Text>帳戶託管金額 ({this.props.overview.currency})</Typography.Text>
            <div className="gobal-color number-size-large">
              <NumberFormat
                value={this.props.overview.principal}
                displayType="text"
                thousandSeparator={true}
                fixedDecimalScale={true}
                prefix="$"
              />
            </div>
          </Col>
          <Col sm={8} lg={6}>
            <Typography.Text>帳戶託管餘額 ({this.props.overview.currency})</Typography.Text>
            <div
              className="gobal-color number-size-large"
              style={{ whiteSpace: "nowrap" }}
            >
              <NumberFormat
                value={this.props.overview.balance}
                displayType="text"
                thousandSeparator={true}
                fixedDecimalScale={true}
                prefix="$"
              />
              <RorTag value={parseFloat(this.props.overview.roi)} />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
export default PerformanceOverview;
