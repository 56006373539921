import React from "react";
import { Form, Input, Select, DatePicker, InputNumber } from "antd";
import CurrencySelect from "../CurrencySelect";
class AccountForm extends React.Component {
  render() {
    return (
      <>
        <Form.Item
          label="帳戶名稱"
          name="account"
          rules={[{ required: true, message: "請輸入資金帳戶名稱" }]}
        >
          <Input size="large" placeholder="客戶名稱+帳號數字" />
        </Form.Item>
        {this.props.createflag ? (
          <Form.Item
            label="保管方"
            name="custodiansid"
            rules={[{ required: true, message: "請選擇保管方" }]}
          >
            <Select size="large" placeholder="請選擇保管方" allowClear>
              {this.props.custodian.map((row) => (
                <Select.Option key={row.id} value={row.id}>
                  {row.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
        <Form.Item
          label="授權方式"
          name="authorizationmodesid"
          rules={[{ required: true, message: "請資金帳戶授權方式" }]}
        >
          <Select size="large" placeholder="請資金帳戶授權方式" allowClear>
            {this.props.authorizationmodes.map((row) => (
              <Select.Option key={row.id} value={row.id}>
                {row.mode}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {this.props.createflag ? (
          <>
            <Form.Item
              label="託管日期"
              name="enabledate"
              rules={[
                { required: true, message: "請輸入資金帳戶託管日期" },
              ]}
            >
              <DatePicker
                size="large"
                style={{ width: "100%" }}
                placeholder="請選擇日期"
                format={process.env.REACT_APP_DATE_FORMAT}
              />
            </Form.Item>
            <Form.Item
              label="本金"
              name="principal"
              rules={[{ required: true, message: "請輸入帳戶初始本金" }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                size="large"
                min="0"
                placeholder="請輸入帳戶初始本金"
                onChange={this.props.onInputNumberChange}
              />
            </Form.Item>
            <Form.Item
              label="幣別"
              name="currencyid"
              rules={[{ required: true, message: "請選擇幣別" }]}
            >
              <CurrencySelect size="large" placeholder="請選擇幣別" allowClear />
            </Form.Item>
          </>
        ) : null}
      </>
    );
  }
}
export default AccountForm;
