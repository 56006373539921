
import { Button, DatePicker, Drawer, InputNumber, Form, Descriptions, Typography, Spin, notification } from 'antd'
import React, { Component } from 'react'
import moment from "moment";
import { getHoldingDetail, updateHoldingPresentValue } from '../../controller/api';

export class UpdateHoldingPresentValueDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            holding: null,
            isLoading: false,
        };
    }
    componentDidMount() {
        this.refresh();
    }
    refresh = async () => {
        this.setState({ isLoading: true });
        await getHoldingDetail(this.props.holdingId).then((response) => {
            this.setState({
                holding: response.data.holdings
            });
        });
        this.setState({ isLoading: false });
    };
    getInitialValues = () => {
        let price = 0;
        if (this.state.holding) {
            price = this.state.holding.unrealizedbalance / this.state.holding.quantity;
        }
        return {
            unitprice: price,
            date: moment(),
        }
    };
    onHandleSubmit = (values) => {
        console.log('Success:', values);
        let data = {
            unitprice: values.unitprice,
            date: values.date.format("YYYY/MM/DD")
        };
        console.log(data);
        updateHoldingPresentValue(this.props.holdingId, data)
            .then((res) => {
                notification["success"]({
                    message: "更新成功",
                    duration: 3,
                    onClose: () => {
                        this.refresh();
                    },
                });
            })
            .catch((error) =>
                notification["error"]({
                    message: error.response.data ? error.response.data.message : error.message,
                    duration: 3,
                })
            );
    };
    disabledDate = (current) => {
        // Can not select days after today and today
        return current && current > moment();
    }
    render() {
        return (
            <Drawer
                title="更新商品現值"
                width={"45%"}
                visible={this.props.visible}
                onClose={this.onClose}
                destroyOnClose={true}
            >
                <Spin spinning={this.state.isLoading}>
                    <Descriptions size="small" column={{ lg: 3, md: 2, sm: 1, xs: 1 }}>
                        <Descriptions.Item label="商品">
                            <Typography.Text type="secondary">
                                {this.state.holding && this.state.holding.assets.assetname}
                            </Typography.Text>
                        </Descriptions.Item>
                        <Descriptions.Item label="持有數量">
                            <Typography.Text type="secondary">
                                {this.state.holding && this.state.holding.quantity}
                            </Typography.Text>
                        </Descriptions.Item>
                        <Descriptions.Item label="本金">
                            <Typography.Text type="secondary">
                                {this.state.holding && this.state.holding.principal}
                            </Typography.Text>
                        </Descriptions.Item>
                        <Descriptions.Item label="現值">
                            <Typography.Text type="secondary">
                                {this.state.holding && this.state.holding.unrealizedbalance}
                            </Typography.Text>
                        </Descriptions.Item>
                        <Descriptions.Item label="平均單價">
                            <Typography.Text type="secondary">
                                {this.state.holding && this.state.holding.unrealizedbalance / this.state.holding.quantity}
                            </Typography.Text>
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
                {this.state.holding &&
                    <Form
                        name="presentValueForm"
                        layout="vertical"
                        onFinish={this.onHandleSubmit}
                        initialValues={this.getInitialValues()}
                        scrollToFirstError={true}
                    >
                        <Form.Item
                            label="日期"
                            name="date"
                            rules={[{ required: true, message: '請輸入日期' }]}
                        >
                            <DatePicker
                                disabledDate={this.disabledDate}
                                showTime={true}
                                size="large"
                                style={{ width: "100%" }}
                                placeholder="請選擇日期"
                                format="YYYY-MM-DD HH:mm:ss"
                            />
                        </Form.Item>
                        <Form.Item
                            label="最新單價"
                            name="unitprice"
                            rules={[{ required: true, message: '請輸入單位價格' }]}
                        >
                            <InputNumber
                                size="large"
                                style={{ width: "100%" }}
                                placeholder="請輸入金額"
                                min="0"
                            />
                        </Form.Item>
                        <div style={{ marginTop: "5px" }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="button-color mt"
                            >
                                儲存
                    </Button>
                            <Button
                                style={{ marginLeft: "5px" }}
                                className="mt"
                                onClick={this.props.onClose}
                            >
                                取消
                    </Button>
                        </div>
                    </Form>
                }
            </Drawer>
        )
    }
}

export default UpdateHoldingPresentValueDrawer
