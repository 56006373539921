import React from "react";
import { Tabs } from "antd";
import PageHeader from "../components/PageHeader";
import EditEmployeeProfilePage from "./Profile/EditEmployeeProfilePage";
import EditCustomerProfilePage from "./Profile/EditCustomerProfilePage";
import EditPasswordPage from "./Profile/EditPasswordPage";
import * as Layout from "../components/Layout";
import { getLoginedRoles } from "../controller/auth";

class SettingPage extends React.Component {
  constructor(props) {
    super(props);
    this.roles = getLoginedRoles();
  }
  render() {
    const routes = [
      {
        path: "/admin",
        breadcrumbName: "首頁",
      },
      {
        path: "/SettingPage",
        breadcrumbName: "設定",
      },
    ];
    const { TabPane } = Tabs;
    return (
      <Layout.CMSLayout {...this.props}>
        <PageHeader
          pageTitle="設定"
          children=" 管理您在 ONE STONE 毅石資本服務使用的基本資訊與帳號密碼"
          routes={routes}
        />
        <div className="main-content" style={{ padding: "25px 25px" }}>
          <Tabs type="card">
            {this.roles.includes("employee") ? (
              <TabPane tab="基本資料" key="1">
                <EditEmployeeProfilePage />
              </TabPane>
            ) : null}
            {this.roles.includes("customer") ? (
              <TabPane tab="帳戶資料" key="2">
                <EditCustomerProfilePage />
              </TabPane>
            ) : null}
            <TabPane tab="設定密碼" key="3">
              <EditPasswordPage />
            </TabPane>
          </Tabs>
        </div>
      </Layout.CMSLayout>
    );
  }
}
export default SettingPage;
