import React from "react";
import {
  Row,
  Col,
  Table,
  Select,
  Typography,
  Divider,
  Spin,
  Button,
  notification,
  Tabs,
  Descriptions,
} from "antd";
import * as Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import {
  getCustomerDetail as getApiCustomerDetail,
  getAccounts as getApiAccounts,
  getHoldings as getApiHoldings,
  getPerformanceHistories as getApiPerformanceHistories,
  deletePerformances as deleteApiPerformances,
  getAccountDetail as getApiAccountDetail,
  getHoldingDetail as getApiHoldingDetail,
  getHoldingTranscations as getApiHoldingTranscations
} from "../../controller/api";
import moment from "moment";
import CreatePerformanceDetailDrawer from "../../components/Performance/CreatePerformanceDetailDrawer";
import CurrencyNumberFormat from "../../components/CurrencyNumberFormat";
import { TYPE_PERFORMANCE } from "../../consts/HoldingTypes";
import UpdateHoldingPresentValueDrawer from "../../components/Performance/UpdateHoldingPresentValueDrawer";
import RorText from "../../components/RorText";
class PerformanceListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      customerid: props.match.params.customerId,
      customername: "",
      fundaccounts: [],
      holdings: [],
      performances: [],
      transcations: [],
      pagination: { total: 0, pagesize: 10, current: 1 },
      selectedfundaccount: {
        id: null,
        data: null,
      },
      selectedholding: {
        id: null,
        data: null,
      },
      isCreatePerformanceDetailDrawerVisible: false,
      isUpdateHoldingPresentValueDrawerVisible: false
    };
  }
  componentDidMount() {
    this.getCustomerDetail();
    this.getFundaccounts();
  }
  getCustomerDetail = () => {
    getApiCustomerDetail(this.state.customerid).then((res) => {
      this.setState({
        customername: res.data.customer.name,
      });
    });
  };
  getFundaccounts = () => {
    getApiAccounts(this.state.customerid, `?all=true`).then((res) => {
      this.setState({
        fundaccounts: res.data.fundaccounts,
      });
    });
  };
  getFundaccountDetail = async (id) => {
    getApiAccountDetail(id).then((res) => {
      this.setState((state, props) => {
        let selectedfundaccount = { ...state.selectedfundaccount };
        selectedfundaccount.id = res.data.fundaccounts.id;
        selectedfundaccount.data = res.data.fundaccounts;
        return { selectedfundaccount, loading: false };
      });
    });
  };
  getHoldings = (fundaccountId) => {
    getApiHoldings(fundaccountId, `?all=true`).then((res) => {
      this.setState({
        holdings: res.data.holdings,
      });
    });
  };
  getHoldingDetail = async (holdingId) => {
    if (!holdingId) return;
    getApiHoldingDetail(holdingId).then((res) => {
      this.setState(
        (state, props) => {
          let selectedholding = { ...state.selectedholding };
          selectedholding.id = res.data.holdings.id;
          selectedholding.data = res.data.holdings; // single row
          console.log(selectedholding.data);
          return { selectedholding, loading: false };
        },
        () => {
          this.getPerformances(1);
        }
      );
    });
    getApiHoldingTranscations(holdingId).then((res) => {
      let transcations = res.data.transactions;
      this.setState({
        transcations: transcations
      });
    });
  };
  getPerformances = (page) => {
    const holdingsid = this.state.selectedholding.id;
    const pagination = { ...this.state.pagination };
    getApiPerformanceHistories(
      holdingsid,
      `?page=${page}&count=${pagination.pagesize}`
    )
      .then((res) => {
        let performances = [...res.data.performances];
        pagination.total = pagination.pagesize * res.data.totalpage;
        pagination.current = page;
        this.setState({
          performances,
          pagination,
        });
      })
      .catch((err) => console.log(err));
  };
  onSelectFundAccount = (fundAccountId) => {
    let selectedfundaccount = { ...this.state.selectedfundaccount };
    selectedfundaccount.id = fundAccountId;
    this.setState(
      {
        selectedfundaccount,
        selectedholding: { id: null, data: null },
        performances: [],
        loading: true,
      },
      () => {
        this.getHoldings(fundAccountId);
        this.reloadPage();
      }
    );
  };
  onSelectHolding = (holdingId) => {
    let selectedholding = { ...this.state.selectedholding };
    selectedholding.id = holdingId;
    this.setState({ selectedholding, performances: [], loading: true }, () => {
      this.getHoldingDetail(holdingId);
    });
  };
  deletePerformanceItem = (data) => {
    this.setState({ loading: true }, () => {
      deleteApiPerformances(data.id)
        .then((res) => {
          notification["success"]({
            message: res.data.message,
            duration: 3,
            onClose: () => {
              this.reloadPage();
            },
          });
        })
        .catch((error) =>
          notification["error"]({
            message: error.message,
            duration: 3,
          })
        );
    });
  };
  hidePerformanceDetail = () => {
    this.setState({
      isCreatePerformanceDetailDrawerVisible: false,
    });
  };
  showPerformanceDetail = () => {
    this.setState({
      isCreatePerformanceDetailDrawerVisible: true,
    });
  };
  hideUpdateHoldingPresentValueDrawer = () => {
    this.setState({
      isUpdateHoldingPresentValueDrawerVisible: false
    });
  }
  showUpdateHoldingPresentValueDrawer = () => {
    this.setState({
      isUpdateHoldingPresentValueDrawerVisible: true
    });
  }
  reloadPage = () => {
    this.getFundaccountDetail(this.state.selectedfundaccount.id).then(() => {
      return this.getHoldingDetail(this.state.selectedholding.id);
    });
  };
  savePerformance = () => {
    this.hidePerformanceDetail();
    this.reloadPage();
  };

  saveHoldingPresentValue = () => {
    this.hideUpdateHoldingPresentValueDrawer();
    this.reloadPage();
  };
  render() {
    const routes = [
      {
        path: "/admin",
        breadcrumbName: "首頁",
      },
      {
        path: "/administration",
        breadcrumbName: "行政作業",
      },
      {
        path: "/customerperformances",
        breadcrumbName: "績效明細",
      },
      {
        path: "/:customerId",
        breadcrumbName: this.state.customername,
      },
    ];
    const transactionColumns = [
      {
        title: "交易時間",
        dataIndex: "date",
        key: "date",
        defaultSortOrder: "descend",
        sorter: (a, b) => new Date(a.date) - new Date(b.date),
      },
      {
        title: "動作",
        dataIndex: "action",
        key: "action",
        render: (value, record) => value === "buy" ? "買進" : "賣出"
      },
      {
        title: "商品",
        dataIndex: "",
        key: ""
      },
      {
        title: "數量",
        dataIndex: "quantity",
        key: "quantity"
      },
      {
        title: "成交價格",
        dataIndex: "unitprice",
        key: "unitprice"
      },
      {
        title: "金額",
        dataIndex: "subtotal",
        key: "subtotal"
      },
      {
        title: "本金",
        dataIndex: "afterprincipal",
        key: "afterprincipal"
      },
      {
        title: "未實現餘額",
        dataIndex: "afterequity",
        key: "afterequity"
      },
      {
        title: "未實現損益",
        dataIndex: "afterunrealized",
        key: "afterunrealized"
      },
      {
        title: "已實現損益",
        dataIndex: "realized",
        key: "realized"
      }
    ];
    const performanceColumns = [
      {
        title: "績效記錄時間",
        dataIndex: "date",
        key: "date",
        width: "180px",
        defaultSortOrder: "descend",
        sorter: (a, b) => new Date(a.date) - new Date(b.date),
        render: (text) => {
          return moment(text).format(
            process.env.REACT_APP_DATE_FORMAT + " (dd) HH:mm"
          );
        },
      },
      {
        title: "帳戶託管金額",
        align: "right",
        dataIndex: "principal",
        key: "principal",
        render: (text) => <CurrencyNumberFormat style={{ color: 'black' }} value={text} />,
      },
      {
        align: "right",
        title: "未實現損益",
        dataIndex: "unrealizedprofit",
        key: "unrealizedprofit",
        render: (text) => <CurrencyNumberFormat style={{ color: 'black' }} value={text} />,
      },
      {
        align: "right",
        title: "累積未實現損益",
        dataIndex: "unrealizedbalance",
        key: "unrealizedbalance",
        render: (text) => <CurrencyNumberFormat style={{ color: 'black' }} value={text} />,
      },
      {
        align: "right",
        title: "已實現損益",
        dataIndex: "realizedprofit",
        key: "realizedprofit",
        render: (text) => <CurrencyNumberFormat style={{ color: 'black' }} value={text} />,
      },
      {
        align: "right",
        title: "累積已實現損益",
        dataIndex: "realizedbalance",
        key: "realizedbalance",
        render: (text) => <CurrencyNumberFormat style={{ color: 'black' }} value={text} />,
      },
      {
        align: "right",
        title: "帳戶託管餘額",
        dataIndex: "balance",
        key: "balance",
        render: (text) => <CurrencyNumberFormat style={{ color: 'black' }} value={text} />,
      },
      {
        align: "right",
        title: "報酬率",
        dataIndex: "ror",
        key: "ror",
        render: (value, record) => <RorText value={value} />
      },
      {
        title: "備註",
        dataIndex: "note",
        key: "note",
        width: "120px",
      },
      {
        title: "動作",
        key: "action",
        width: "80px",
        render: (data) => (
          <>
            {this.state.selectedholding.data !== null ? (
              data.deletable && this.state.selectedholding.data.assetid !== 1 ? (
                <>
                  <Button
                    type="link"
                    onClick={this.deletePerformanceItem.bind(this, data)}
                  >
                    刪除
                  </Button>
                </>
              ) : null
            ) : null}
          </>
        ),
      },
    ];
    return (
      <Layout.CMSLayout {...this.props}>
        <PageHeader pageTitle={this.state.customername} routes={routes} />
        <div className="main-content">
          <Spin spinning={this.state.loading}>
            <Row>
              <Col md={6} sm={24} xs={24}>
                選擇資金帳戶
                  <Select
                  style={{ width: "100%" }}
                  placeholder="請選擇帳戶"
                  onChange={this.onSelectFundAccount}
                  value={
                    this.state.selectedfundaccount !== null
                      ? this.state.selectedfundaccount.id
                      : null
                  }
                >
                  {this.state.fundaccounts.length > 0
                    ? this.state.fundaccounts.map((item) => (
                      <Select.Option
                        key={"account-" + item.id}
                        value={item.id}
                      >
                        {item.account}
                      </Select.Option>
                    ))
                    : null}
                </Select>
              </Col>
              <Col md={18} sm={24} xs={24}>
                <div style={{ backgroundColor: "#eee", borderRadius: 5, padding: 10, margin: 10 }}>
                  <Typography.Title level={3} style={{ fontSize: 14 }}>資金帳戶資訊</Typography.Title>
                  {this.state.selectedfundaccount.data !== null ? (
                    <Descriptions column={{ md: 2, sm: 1, xs: 1 }} size="small">
                      <Descriptions.Item label="保管方">{this.state.selectedfundaccount.data.custodian.name}</Descriptions.Item>
                      <Descriptions.Item label="授權方式">{this.state.selectedfundaccount.data.authorizationmode.mode}</Descriptions.Item>
                      <Descriptions.Item label="帳戶託管金額">
                        <CurrencyNumberFormat
                          currencyid={this.state.selectedfundaccount.data.currencyid}
                          value={this.state.selectedfundaccount.data.principal}
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label="帳戶託管餘額">
                        <CurrencyNumberFormat
                          value={this.state.selectedfundaccount.data.balance}
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label="報酬率">
                        <RorText
                          value={this.state.selectedfundaccount.data.accror}
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label="已實現損益">
                        <CurrencyNumberFormat
                          value={this.state.selectedfundaccount.data.realized}
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label="未實現損益">
                        <CurrencyNumberFormat
                          value={this.state.selectedfundaccount.data.unrealized}
                        />
                      </Descriptions.Item>

                    </Descriptions>
                  ) : (
                      <Typography type="secondary">您尚未選擇帳戶</Typography>
                    )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={24} xs={24}>
                選擇投資商品
                    <Select
                  style={{ width: "100%" }}
                  placeholder="請選擇投資商品"
                  onChange={this.onSelectHolding}
                  value={this.state.selectedholding.id !== null ? this.state.selectedholding.id : null}
                >
                  {this.state.selectedfundaccount != null && this.state.holdings.length > 0 ? this.state.holdings.map((item, index) => {
                    return (
                      <Select.Option key={"assetsid" + index} value={item.id}>
                        {item.assetname}
                      </Select.Option>
                    );
                  }) : null}
                </Select>
              </Col>
              <Col md={18} sm={24} xs={24}>
                <div style={{ backgroundColor: "#eee", borderRadius: 5, padding: 10, margin: 10 }}>
                  <Typography.Title level={3} style={{ fontSize: 14 }}>
                    投資商品資訊
                    </Typography.Title>
                  {this.state.selectedholding.data !== null ? (
                    <Descriptions column={{ md: 2, sm: 2, xs: 2 }} size="small">
                      <Descriptions.Item label="本金">
                        <CurrencyNumberFormat value={this.state.selectedholding.data.principal} />
                      </Descriptions.Item>
                      <Descriptions.Item label="餘額">
                        <CurrencyNumberFormat value={this.state.selectedholding.data.balance} />
                      </Descriptions.Item>
                      <Descriptions.Item label="未實現損益">
                        <CurrencyNumberFormat value={this.state.selectedholding.data.unrealizedprofit} />
                      </Descriptions.Item>
                      <Descriptions.Item label="報酬率">
                        <RorText
                          value={this.state.selectedholding.data.ror}
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label="已實現損益">
                        <CurrencyNumberFormat value={this.state.selectedholding.data.realizedprofit} />
                      </Descriptions.Item>

                    </Descriptions>
                  ) : (
                      <Typography type="secondary">您尚未選擇商品</Typography>
                    )}
                </div>

              </Col>
            </Row>
            <Tabs>
              <Tabs.TabPane tab="績效明細" key="1">
                <Button
                  key="createPerformanceDetail"
                  onClick={this.showPerformanceDetail}
                  className="button-color"
                  style={{ marginBottom: "10px", borderRadius: 5, float: "right" }}
                  disabled={
                    this.state.selectedholding.data === null ||
                    this.state.selectedholding.data.assetid === 1
                  }
                >
                  新增績效明細
                </Button>
                <Button
                  disabled={
                    this.state.selectedholding.data === null ||
                    this.state.selectedholding.data.type === TYPE_PERFORMANCE
                  }
                  className="button-color"
                  style={{ marginRight: "5px", marginBottom: "10px", borderRadius: 5, float: "right" }}
                  onClick={this.showUpdateHoldingPresentValueDrawer}
                >
                  更新現值
                </Button>
                <Divider />
                <Table
                  columns={performanceColumns}
                  dataSource={this.state.performances}
                  rowKey="id"
                  pagination={{
                    position: ["bottomLeft"],
                    total: this.state.pagination.total,
                    current: this.state.pagination.current,
                    pageSize: this.state.pagination.pagesize,
                    showSizeChanger: false,
                    onChange: (page) => this.getPerformances(page),
                  }}
                  scroll={{
                    x: 1400,
                    scrollToFirstRowOnChange: true,
                  }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="交易明細" key="2">
                <Table
                  columns={transactionColumns}
                  dataSource={this.state.transcations}
                  rowKey="id"
                  scroll={{
                    x: 1000,
                    scrollToFirstRowOnChange: true,
                  }}
                />
              </Tabs.TabPane>
            </Tabs>
          </Spin>
          {this.state.isUpdateHoldingPresentValueDrawerVisible &&
            <UpdateHoldingPresentValueDrawer
              holdingId={this.state.selectedholding.id}
              visible={this.state.isUpdateHoldingPresentValueDrawerVisible}
              onSave={this.saveHoldingPresentValue}
              onClose={this.hideUpdateHoldingPresentValueDrawer}
            />}
          {this.state.isCreatePerformanceDetailDrawerVisible &&
            <CreatePerformanceDetailDrawer
              visible={this.state.isCreatePerformanceDetailDrawerVisible}
              fundaccountId={this.state.selectedfundaccount.id}
              holdingId={this.state.selectedholding.id}
              onSave={this.savePerformance}
              onClose={this.hidePerformanceDetail}
            />}
        </div>
      </Layout.CMSLayout>
    );
  }
}
export default PerformanceListPage;
