import React from "react";
import { Form, Button } from "antd";
import Drawer from "../Drawer";
import AccountForm from "./AccountForm";
import { editFundAccount } from "../../controller/api";
class EditAccountDrawer extends React.Component {
  getInitialValues = () => {
    return {
      account: this.props.viewing.account,
      authorizationmodesid: this.props.viewing.authorizationmodesid,
    };
  };
  onHandleSubmit = (props) => {
    editFundAccount(this.props.viewing.id, props).then(() => {
      this.onClose();
      this.props.reloadList(1);
    });
  };
  onClose = () => {
    this.setState({ count: 0, assetsData: [] }, () => this.props.onClose());
  };
  render() {
    return (
      <Drawer
        title="修改帳戶"
        width={"30%"}
        visible={this.props.visible}
        onClose={this.onClose}
        destroyOnClose={true}
      >
        <Form
          name="AccountForm"
          layout="vertical"
          onFinish={this.onHandleSubmit}
          initialValues={this.getInitialValues()}
          scrollToFirstError={true}
        >
          <AccountForm
            createflag={false}
            authorizationmodes={this.props.authorizationmodes}
          />

          <div style={{ marginTop: "5px" }}>
            <Button
              type="primary"
              htmlType="submit"
              className="button-color mt"
            >
              儲存
            </Button>
            <Button
              style={{ marginLeft: "5px" }}
              className="mt"
              onClick={this.onClose}
            >
              取消
            </Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}
export default EditAccountDrawer;
