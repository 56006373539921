import React from "react";
import { Button, notification } from "antd";
import Drawer from "../Drawer";
import TranscationForm from "./TransactionForm";
import moment from "moment";
import { createHoldingTranscations } from "../../controller/api";
class CreateTransactionDetailDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      action: null,
      quantity: null,
      subtotal: null,
      unitprice: null,
      note: "",
      buttonLoading: false,
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.holdingsId !== prevProps.holdingsId) {
      this.reset();
    }
  }
  reset = () => {
    this.setState({
      date: moment(),
      action: null,
      quantity: null,
      subtotal: null,
      unitprice: null,
      note: null,
      buttonLoading: false,
    });
  };
  onValueChange = (name, value) => {
    let state = this.state;
    state[name] = value;
    switch (name) {
      case "subtotal":
      case "quantity":
        if (state.subtotal && state.quantity) {
          state.unitprice = state.subtotal / state.quantity;
        }
        break;
      case "note":
        state[name] = value.target.value;
        break;
      default:
        break;
    }
    this.setState(state);
  };
  onDateChange = (time) => {
    this.setState({ date: time });
  };
  onSelectChange = (name, value) => {
    let state = this.state;
    state[name] = value;
    this.setState(state);
  };
  onHandleSubmit = () => {
    let { date, action, subtotal, quantity } = this.state;
    if (date === null) {
      notification["warning"]({ message: "請輸入時間" });
    } else if (action === null) {
      notification["warning"]({ message: "請選擇交易類型" });
    } else if (subtotal === 0 && quantity === 0) {
      notification["warning"]({ message: "請輸入金額" });
    } else {
      this.setState({ buttonLoading: true }, () => {
        let data = {
          "holdingid": this.props.holdingsId,
          "date": moment(this.state.date).format("YYYY-MM-DD HH:mm:ss"),
          "action": this.state.action,
          "quantity": this.state.quantity,
          "unitprice": this.state.unitprice,
          "note": this.state.note,
        };
        createHoldingTranscations(this.props.holdingsId, data)
          .then((res) => {
            notification["success"]({
              message: res.data.message,
              duration: 3,
              onClose: this.onSave,
            });
          })
          .catch((err) => {
            notification["error"]({
              message: err.response.data.message,
              duration: 3,
              onClose: this.onClose,
            });
          });
      });
    }
  };
  onSave = () => {
    this.reset();
    if (this.props.onSave) {
      this.props.onSave();
    }
  };
  onClose = () => {
    this.reset();
    if (this.props.onClose) {
      this.props.onClose();
    }
  };
  render() {
    return (
      <Drawer
        title={`新增交易明細`}
        width={"45%"}
        visible={this.props.visible}
        onClose={this.onClose}
        destroyOnClose={true}
      >
        <div>
          <TranscationForm
            fielddata={this.state}
            createMulti={false}
            size={"large"}
            optionClass="cloumn-style mg"
            onDateChange={this.onDateChange}
            onSelectChange={this.onSelectChange}
            onValueChange={this.onValueChange}
          />
        </div>

        <div style={{ marginTop: "5px" }}>
          <Button
            type="primary"
            htmlType="submit"
            className="button-color mt"
            onClick={this.onHandleSubmit}
            loading={this.state.buttonLoading}
          >
            儲存
          </Button>
          <Button
            style={{ marginLeft: "5px" }}
            className="mt"
            onClick={this.onClose}
          >
            取消
          </Button>
        </div>
      </Drawer>
    );
  }
}
export default CreateTransactionDetailDrawer;
