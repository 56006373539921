import React, { Component } from 'react'
import { Select } from "antd";
import { getCurrencies } from "../controller/api"
import { currencyStore } from "../controller/observer"
import { Observer } from "mobx-react"
export class CurrencySelect extends Component {
    componentDidMount() {
        this.fetchCurrencies();
    }
    fetchCurrencies = () => {
        if (currencyStore.currencies.length > 0) return;
        return getCurrencies().then((response) => {
            if (response.data) {
                currencyStore.currencies = response.data;
            }
        });
    }
    render() {
        return (
            <Observer>
                {() =>
                    <Select {...this.props} size="large" placeholder="請選擇幣別" allowClear>
                        {
                            currencyStore.currencies.map((currency) => <Select.Option key={currency.id} value={currency.id}>{currency.currencyname}</Select.Option>)
                        }
                    </Select>
                }
            </Observer>
        )
    }
}

export default CurrencySelect;
