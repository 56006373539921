import React from "react";
import {
  Form,
  Button,
  Input,
  Select,
  InputNumber,
  DatePicker,
  notification,
} from "antd";
import Drawer from "../Drawer";
import locale from "antd/es/date-picker/locale/zh_TW";
import { createFundAccountTransfer } from "../../controller/api";
import moment from "moment";
class AccountDebitCreditDrawer extends React.Component {
  getInitialValues = () => {
    return {
      types: "deposit",
      lastdate: "",
      amount: "",
      note: "",
    };
  };
  onHandleSubmit = (props) => {
    let data = props;
    data.lastdate = moment(props.lastdate).format("YYYY-MM-DD HH:mm:ss");
    data.fundaccountsid = this.props.accountid;
    createFundAccountTransfer(this.props.customerId, data)
      .then((res) =>
        notification["success"]({
          message: res.data.message,
          duration: 3,
          onClose: () => {
            this.props.onClose();
            this.props.getfundaccounts(1);
          },
        })
      )
      .catch((err) =>
        notification["error"]({
          message: "新增失敗",
          duration: 3,
          onClose: () => {
            this.props.onClose();
          },
        })
      );
  };
  render() {
    return (
      <Drawer
        title="帳戶入出金"
        width={"30%"}
        visible={this.props.visible}
        onClose={this.props.onClose}
        destroyOnClose={true}
      >
        <Form
          name="AccountForm"
          layout="vertical"
          onFinish={this.onHandleSubmit}
          initialValues={this.getInitialValues()}
          scrollToFirstError={true}
        >
          <Form.Item
            label="入出金類型"
            name="types"
            rules={[{ required: true, message: "請選擇類型" }]}
          >
            <Select style={{ width: "100%" }} size="large">
              <Select.Option value="deposit">帳戶入金</Select.Option>
              <Select.Option value="withdraw">帳戶出金</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="時間"
            name="lastdate"
            rules={[{ required: true, message: "請選擇時間" }]}
          >
            <DatePicker
              size="large"
              style={{ width: "100%" }}
              placeholder="請選擇日期"
              showTime={true}
              locale={locale}
              format={`${process.env.REACT_APP_DATE_FORMAT} (dd) HH:mm`}
              onSelect={this.dayTimePickerBlur}
              allowClear={false}
            />
          </Form.Item>
          <Form.Item
            label="金額"
            name="amount"
            rules={[{ required: true, message: "請輸入金額" }]}
          >
            <InputNumber
              size="large"
              style={{ width: "100%" }}
              placeholder="請輸入金額"
              min="0"
            />
          </Form.Item>
          <Form.Item name="note" label="備註說明">
            <Input size="large" />
          </Form.Item>

          <div style={{ marginTop: "5px" }}>
            <Button
              type="primary"
              htmlType="submit"
              className="button-color mt"
            >
              儲存
            </Button>
            <Button
              style={{ marginLeft: "5px" }}
              className="mt"
              onClick={this.props.onClose}
            >
              取消
            </Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}
export default AccountDebitCreditDrawer;
