import React, { Component } from 'react'
import { Tag } from "antd"
import { PropTypes } from 'prop-types';

/**
 * 幣別標籤
 * @augments {Component<Props, State>}
 */
export class CurrencyTag extends Component {
    static propTypes = {
        /** 幣別 */
        currencyid: PropTypes.string
    };
    render() {
        return (
            <Tag color="#29221B">
                {this.props.currencyid}
            </Tag>
        )
    }
}

export default CurrencyTag
