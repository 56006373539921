import { observable } from 'mobx'

export const windowWidth = observable({
  width: window.innerWidth
});
export const sendingState = observable({
  sending: false
});
export const noteDialogVisible = observable({
  visible: false
});
export const changeToUploadList = observable({
  visible: false,
  id: null,
  cateName: ''
});
export const currencyStore = observable({
  currencies: []
});