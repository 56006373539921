import { action } from 'mobx'
import { windowWidth,sendingState, noteDialogVisible, changeToUploadList } from './observer'
export const changWindowWidth = action(
  (width)=>{
    windowWidth.width = width
  }
)
export const changeSendingState = action(
  (sending) => {
    sendingState.sending = sending
  }
)
export const changeNoteDialogVisible = action(
  (visible) => {
    noteDialogVisible.visible = visible
  }
)
export const changeUploadListVisible = action(
  (visible, id, name) => {
    changeToUploadList.visible = visible
    changeToUploadList.id = id
    changeToUploadList.cateName = name
  }
)