import React from "react";
import {
  Spin,
  DatePicker,
  Button,
  Row,
  Card,
  Typography,
  Divider,
  notification,
  Col,
  message,
  Descriptions,
} from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import * as Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import {
  getCustomerDetail,
  getAccounts,
  getHoldings,
  createBatchTranscations,
} from "../../controller/api";
import TransactionItemForm from "../../components/Transcation/TransactionItemForm";
import moment from "moment";
import locale from "antd/es/date-picker/locale/zh_TW";
import { Link } from "react-router-dom";
import CurrencyNumberFormat from "../../components/CurrencyNumberFormat";

class CreateBatchTransactionDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      customerid: props.match.params.customerId,
      customername: "",
      date: moment(),
      fundaccounts: [],
      holdings: [],
      createsuccessed: [],
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    this.getCustomerDetail();
    this.getFundaccounts()
      .then(() => {
        this.state.fundaccounts.forEach((item) => {
          let id = item.id;
          this.getHoldings(id);
        });
      })
      .then(() => {
        this.setState({ loading: false });
      });
  }
  getCustomerDetail = () => {
    getCustomerDetail(this.state.customerid)
      .then((res) => {
        this.setState({
          customername: res.data.customer.cnName,
        });
      })
      .catch((error) => {
        notification["error"]({
          message: error.message,
          duration: 3,
        });
      });
  };
  getFundaccounts = async () => {
    await getAccounts(this.state.customerid, `?all=true`)
      .then((res) => {
        const fundaccounts = res.data.fundaccounts;
        this.setState({ fundaccounts });
      })
      .catch((error) => {
        notification["error"]({
          message: error.message,
          duration: 3,
        });
      });
  };
  getHoldings = (id) => {
    let fundaccounts = [...this.state.fundaccounts];
    getHoldings(id, `?all=true`)
      .then((res) => {
        fundaccounts.forEach((fundaccount) => {
          if (fundaccount.id === id) {
            fundaccount.holdings = res.data.holdings
          }
        });
        let holdings = { ...this.state.holdings };

        res.data.holdings.forEach((holding) => {
          // 略過策略無限
          if (holding.assetid === 1) return;
          // 初始化 holding 資料
          holdings[holding.id] = {
            balance: holding.balance,
            transcations: [],
            sended: false,
          };
        });

        this.setState({ holdings, fundaccounts });
      })
      .catch((error) => {
        notification["error"]({
          message: error.message,
          duration: 3,
        });
      });
  };
  calculateValues = (holdingId) => {
    let holdings = { ...this.state.holdings };
    const transcation = [...holdings[holdingId].transcations];
    let lastBalance = holdings[holdingId].balance;
    transcation.forEach((row) => {
      row.balance = lastBalance;
      switch (row.action) {
        case "buy":
          row.balance = Number(row.balance) - row.subtotal;
          lastBalance = row.balance;
          break;
        case "sell":
          row.balance = Number(row.balance) + row.subtotal;
          lastBalance = row.balance;
          break;
        default:
          lastBalance = Number(row.balance);
      }
    });
    this.setState({ holdings });
  };
  onValueChange = (holdingId, index, name, value) => {
    let holdings = this.state.holdings;
    const transcation = holdings[holdingId].transcations[index];
    transcation[name] = value;
    if (name === "note") {
      transcation[name] = value.target.value;
    }
    if (transcation['quantity'] && transcation['subtotal']) {
      transcation['unitprice'] = transcation['subtotal'] / transcation['quantity'];
    }
    this.setState({ holdings }, () => this.calculateValues(holdingId));
  };
  onDateChange = (value) => {
    this.setState({ date: value });
  };
  onTimeChange = (holdingId, index, time) => {
    let holdings = this.state.holdings;
    holdings[holdingId].transcations[index].date = time;
    this.setState({ holdings });
  };
  onSelectChange = (holdingId, index, name, value) => {
    let holdings = this.state.holdings;
    const transcation = holdings[holdingId].transcations[index];
    transcation[name] = value;
    switch (value) {
      case "buy":
        transcation.balance = 1;
        break;
      case "sell":
        transcation.balance = 2;
        break;
      default:
        transcation.balance = 0;
    }
    this.setState({ holdings }, () => this.calculateValues(holdingId));
  };
  onAddItem = (holdingId) => {
    const holdings = { ...this.state.holdings };
    const transcations = holdings[holdingId].transcations;
    holdings[holdingId].sended = false;
    // 初始化 transcations 資料
    transcations.push({
      date: undefined,
      action: null,
      subtotal: 1000,
      quantity: 100,
      unitprice: 10,
      balance: null,
      note: null,
    });
    this.setState({ holdings }, () => {
      console.log(this.state);
    });
  };
  onDeleteItem = (holdingId, index) => {
    const holdings = { ...this.state.holdings };
    holdings[holdingId].transcations.splice(index, 1);
    this.setState({ holdings }, () => this.calculateValues(holdingId));
  };

  validForm = () => {
    let verified = false;
    let holdings = this.state.holdings;
    let holdingIds = Object.keys(holdings);
    holdingIds.forEach((holdingId) => {
      let transactions = holdings[holdingId].transcations;
      //有新增績效資料就會跑進來迴圈內
      transactions.forEach((transaction) => {
        //判斷欄位是否有填寫
        if (transaction.date === undefined) {
          verified = false;
          message.warning("請輸入時間");
        } else if (transaction.action === null) {
          verified = false;
          message.warning("請選擇交易類型");
        } else if (transaction.quantity === 0) {
          verified = false;
          message.warning("請輸入數量");
        } else if (transaction.subtotal === 0) {
          verified = false;
          message.warning("請輸入金額");
        } else {
          verified = true;
        }
      });
    });
    return verified;
  };
  submitHoldingTranscations = async (holdingId, data) => {
    let { holdings, createsuccessed } = this.state;

    // 若已儲存則不再送出
    if (holdings[holdingId].sended) {
      return;
    }
    this.setState({ loading: true });
    await createBatchTranscations(holdingId, data).then((res) => {
      holdings[holdingId].sended = true;
      createsuccessed.push(holdingId);
      this.setState({ holdings, createsuccessed });
    })
      .catch((error) => {
        if (error.response.status === 400) {
          notification["error"]({
            message: error.response.data.message,
            duration: 3,
          });
        } else {
          notification["error"]({
            message: error.message,
            duration: 3,
          });
        }
        console.log(error);
      });
    this.setState({ loading: false });
  };
  onHandleSubmit = () => {
    let { holdings, createsuccessed } = this.state;
    let holdingIds = Object.keys(holdings);
    let holdingsData = [];

    let vertified = this.validForm();
    // 檢驗不成功則不送出資料
    if (!vertified) {
      return;
    }

    holdingIds.forEach((holdingId) => {
      let transcations = holdings[holdingId].transcations;
      const holdingData = { id: holdingId, data: [] };

      //有新增績效資料就會跑進來迴圈內
      transcations.forEach((transcation) => {
        let transcationData = Object.assign({}, transcation);
        transcationData.date = moment(this.state.date).format("YYYY-MM-DD") + " " + moment(transcation.date).format("HH:mm:ss");
        transcationData.holdingid = holdingId;
        holdingData.data.push(transcationData);
      });
      if (holdingData.data.length > 0) {
        holdingsData.push(holdingData);
      }
    });
    let promises = holdingsData.map((holdingData) => this.submitHoldingTranscations(holdingData.id, holdingData.data));
    Promise.all(promises).then((response) => {
      if (holdings.length === createsuccessed.length) {
        notification["success"]({
          message: "交易新增成功",
          duration: 3,
          onClose: () => this.props.history.push('/admin/administration/customerholdings'),
        });
      }
    });
  };
  render() {
    const routes = [
      {
        path: "/admin",
        breadcrumbName: "首頁",
      },
      {
        path: "/administration",
        breadcrumbName: "行政作業",
      },
      {
        path: "/customerholdings",
        breadcrumbName: "持有資訊",
      },
      {
        path: "/batch-create",
        breadcrumbName: "批次建立交易明細",
      },
    ];
    return (
      <Layout.CMSLayout {...this.props}>
        <PageHeader pageTitle={this.state.customername} routes={routes} />
        <div className="main-content">
          {this.state.fundaccounts.length > 0 ? (
            <Spin spinning={this.state.loading}>
              <div name="PerformanceForm">
                <Row gutter={[0, 15]}>
                  <Col>
                    <div>交易日期</div>
                    <DatePicker
                      size="large"
                      placeholder="請選擇日期"
                      locale={locale}
                      format={`${process.env.REACT_APP_DATE_FORMAT} (dd)`}
                      onChange={this.onDateChange}
                      autoFocus={true}
                      value={this.state.date}
                    />
                  </Col>
                </Row>
                {this.state.fundaccounts.map((fundaccount) => (
                  <Card key={fundaccount.id} style={{ marginBottom: 10 }}>
                    <Typography.Title level={4}>
                      資金帳戶：{fundaccount.account}
                    </Typography.Title>
                    <Descriptions size="small">
                      <Descriptions.Item label="保管方">{fundaccount.custodian.name}</Descriptions.Item>
                      <Descriptions.Item label="帳戶託管金額"><CurrencyNumberFormat currencyid={fundaccount.currencyid} value={fundaccount.principal} /></Descriptions.Item>
                      <Descriptions.Item label="帳戶託管餘額"><CurrencyNumberFormat currencyid={fundaccount.currencyid} value={fundaccount.balance} /></Descriptions.Item>
                      <Descriptions.Item label="最後新增日期">{fundaccount.lastdate}</Descriptions.Item>
                    </Descriptions>

                    {fundaccount.holdings === undefined ? (
                      <Card
                        style={{
                          backgroundColor: "#eee",
                          margin: "10px 0",
                        }}
                      >
                        <Typography.Text type="secondary">
                          尚無投資商品
                        </Typography.Text>
                      </Card>
                    ) : (
                        fundaccount.holdings.map((holding) => (
                          <Card
                            key={holding.id}
                            style={
                              this.state.holdings[holding.id] !== undefined &&
                                this.state.holdings[holding.id].sended
                                ? {
                                  backgroundColor: "#fff",
                                  margin: "10px 0",
                                }
                                : {
                                  backgroundColor: "#eee",
                                  margin: "10px 0",
                                }
                            }
                          >
                            <Typography.Title level={4}>
                              {this.state.holdings[holding.id] !==
                                undefined &&
                                this.state.holdings[holding.id].sended ? (
                                  <>
                                    <CheckCircleTwoTone /> &nbsp;
                              </>
                                ) : null}
                              {holding.assetname}
                            </Typography.Title>
                            <Descriptions size="small">
                              <Descriptions.Item label="本金">{holding.principal}</Descriptions.Item>
                              <Descriptions.Item label="持有數量">{holding.quantity}</Descriptions.Item>
                              <Descriptions.Item label="未實現餘額">{parseInt(holding.balance)}</Descriptions.Item>
                            </Descriptions>
                            <Divider style={{ margin: "10px 0" }} />
                            {holding.assetname === '台灣指數程式' ? (
                              <Typography.Text
                                type="secondary"
                                style={{ marginTop: 10 }}
                              >
                                此商品已連結程式自動匯入
                              </Typography.Text>
                            ) : holding.type !== "transaction" ? (
                              <Typography.Text
                                type="secondary"
                                style={{ marginTop: 10 }}
                              >
                                此商品為直接輸入帳戶餘額
                              </Typography.Text>
                            ) : (
                                this.state.holdings[holding.id] && <>
                                  <div style={{ overflow: "auto" }}>
                                    {this.state.holdings[holding.id].transcations.map((transcation, index) => (
                                      <div key={index} className="row-style">
                                        <TransactionItemForm
                                          fielddata={transcation}
                                          size="regular"
                                          disabled={this.state.holdings[holding.id].sended}
                                          onTimeChange={this.onTimeChange.bind(this, holding.id, index)}
                                          onSelectChange={this.onSelectChange.bind(this, holding.id, index)}
                                          onValueChange={this.onValueChange.bind(this, holding.id, index)}
                                          onDeleteItem={this.onDeleteItem.bind(this, holding.id, index)}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                  {this.state.holdings[holding.id]
                                    .sended ? null : (
                                      <Button
                                        className="addItembutton"
                                        block
                                        onClick={this.onAddItem.bind(this, holding.id)}
                                      >
                                        新增交易明細
                                      </Button>
                                    )}
                                </>
                              )}
                          </Card>
                        ))
                      )}
                  </Card>
                ))}
                <div style={{ marginTop: "5px" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="button-color mt"
                    onClick={this.onHandleSubmit}
                    disabled={
                      Object.keys(this.state.holdings).length === 0
                    }
                  >
                    儲存
                  </Button>
                  <Link
                    style={{ padding: "10px 15px", borderRadius: 5 }}
                    to="/admin/administration/customerperformances"
                  >
                    回上一頁
                  </Link>
                </div>
              </div>
            </Spin>
          ) : "目前無任何資金帳戶及持有商品，請先新增資新帳戶及持有商品"}
        </div>
      </Layout.CMSLayout>
    );
  }
}
export default CreateBatchTransactionDetailPage;
