import React from "react";
import { Layout, Result} from "antd";
export default class PageNotFoundPage extends React.Component {
  render() {
    const { Header, Content, Footer } = Layout;
    return (
      <Layout id="CMS-wrapper" className="full-page" {...this.props}>
        <Layout>
          <Header className="site-header">
          </Header>
          <Content className="site-main">
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
            />
          </Content>
          <Footer className="site-footer">
            One Stone © Copyright All Rights Reserved
          </Footer>
        </Layout>
      </Layout>
    );
  }
}
