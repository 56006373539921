import React from "react";
import { Row, Col, Typography, Radio, Space } from "antd";
import moment from "moment";
import ReactEcharts from "echarts-for-react";
import { PropTypes } from "prop-types";
import "moment/locale/zh-tw";

/**
 * 績效圖表
 * @augments {Component<Props, State>}
 */
class PerformanceChartV2 extends React.Component {
  static propTypes = {
    /** 期間類型更動事件 */
    onPeriodTypeChange: PropTypes.func.isRequired,
    /** 圖表資料 */
    chartData: PropTypes.object.isRequired,
    /** 預設值 */
    default: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
    };
  }
  getOption = () => {
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
      },
      legend: {
        data: ["累計損益", "帳戶託管金額", "當期損益", "報酬率"],
      },
      grid: {
        left: "50px",
        right: "50px",
        top: "50px",
      },
      dataZoom: [
        { type: "inside" },
        {
          type: "slider",
          borderColor: "#eee",
          dataBackground: {
            lineStyle: { color: "rgba(201, 169, 95, 0.9)" },
            areaStyle: { color: "rgba(201, 169, 95, 0.9)" },
          },
          fillerColor: "rgba(87, 38, 0, 0.2)",
          handleSize: "70%",
          handleIcon:
            "M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
          handleStyle: {
            color: "#fff",
            shadowBlur: 3,
            shadowColor: "rgba(0, 0, 0, 0.5)",
            shadowOffsetX: 2,
            shadowOffsetY: 2,
          },
        },
      ],
      xAxis: [
        {
          type: "category",
          data: this.props.chartData.name,
          axisLabel: {
            interval: this.props.default.periodtype === "day" ? "auto" : 0,
            formatter: (value, index) => {
              let data = moment(value).format(
                process.env.REACT_APP_DATE_FORMAT
              );
              if (this.props.default.periodtype === "day") {
                let date = moment(data).dates();
                return date;
              } else if (this.props.default.periodtype === "week") {
                let dates = value.split(" - ");
                let from = dates[0];
                let date = moment(from).format("MM/DD");
                return date;
              } else {
                let dates = value.split(" - ");
                let from = dates[0];
                return index === 0 || moment(from).dates() / 7 <= 1
                  ? moment(from).format("YYYY/MM")
                  : null;
              }
            },
          },
          axisLine: {
            lineStyle: {
              color: "#aaa",
            },
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          position: "right",
          axisLabel: {
            formatter: function (value, index) {
              let number = value / 1000;
              return number + "k";
            },
          },
          axisLine: {
            lineStyle: {
              color: "#aaa",
            },
          },
        },
        {
          type: "value",
          position: "left",
          axisLabel: {
            formatter: "{value} %",
          },
          axisLine: {
            lineStyle: {
              color: "#aaa",
            },
          },
        },
      ],
      series: [
        {
          name: "累計損益",
          type: "line",
          itemStyle: {
            color: "#C9A95F",
          },
          symbolSize: 8,
          smooth: true,
          data: this.props.chartData.accumulatedprofit,
        },
        {
          name: "帳戶託管金額",
          type: "line",
          itemStyle: {
            color: "darkred",
          },
          symbolSize: 8,
          smooth: true,
          data: this.props.chartData.principal,
        },
        {
          name: "當期損益",
          type: "bar",
          itemStyle: {
            color: "#cccccc",
          },
          data: this.props.chartData.profit,
        },
        {
          name: "報酬率",
          type: "line",
          itemStyle: {
            color: "lightgreen",
          },
          yAxisIndex: 1,
          data: this.props.chartData.ror,
        },
      ],
    };
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.chartData !== prevProps.chartData) {
      console.log("chart updated");
      this.setState({
        option: this.getOption(),
      });
    }
  }
  render() {
    return (
      <>
        <Typography.Title
          level={4}
          style={{ fontWeight: "normal", marginBottom: 20 }}
        >
          投資總績效走勢
        </Typography.Title>
        <Row gutter={[10, 10]}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Radio.Group
              defaultValue={"day"}
              onChange={this.props.onPeriodTypeChange}
            >
              <Space>
                <Radio.Button value={"day"}>天</Radio.Button>
                <Radio.Button value={"week"}>週</Radio.Button>
                <Radio.Button value={"month"}>月</Radio.Button>
              </Space>
            </Radio.Group>
          </Col>
        </Row>

        <ReactEcharts
          option={this.state.option}
          notMerge={true}
          showLoading={this.props.loading}
          onEvents={{ click: this.props.onClick }}
        />
      </>
    );
  }
}
export default PerformanceChartV2;
