import React from "react";
import { Table, Button, Typography } from "antd";
import { changeUploadListVisible } from "../../controller/action";
import { getCategoryList } from "../../controller/api";
import CreateCategoryModal from "./CreateCategoryModal";
class CategoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      createModalVisible: false,
      categoryList: [],
    };
  }
  componentDidMount() {
    this.getCategories();
  }
  getCategories = () => {
    getCategoryList(this.props.customerId).then((res) => {
      this.setState({ loading: false, categoryList: res.data.data });
    });
  };
  getUploadList = (row) => {
    changeUploadListVisible(true, row.id, row.categoryName);
  };
  createModalVisibleChanged = () => {
    this.setState({
      createModalVisible: !this.state.createModalVisible,
    });
  };
  render() {
    const columns = [
      {
        dataIndex: "categoryName",
        render: (text, record) => (
          <Typography.Link onClick={this.getUploadList.bind(this, record)}>{text}</Typography.Link>
        ),
      },
    ];
    return (
      <>
        <Button
          type="primary"
          className="button-color"
          style={{ minWidth: 150, margin: "10px 0" }}
          onClick={this.createModalVisibleChanged}
        >
          新增分類
        </Button>
        <Table
          showHeader={false}
          loading={this.state.loading}
          columns={columns}
          rowKey="id"
          className="categoryList"
          dataSource={
            this.state.categoryList.length > 0 ? this.state.categoryList : null
          }
          onRow={(record) => {
            return {
              onClick: this.getUploadList.bind(this, record),
            };
          }}
        />
        <CreateCategoryModal
          customerId={this.props.customerId}
          visible={this.state.createModalVisible}
          changeModalVisible={this.createModalVisibleChanged}
          refreshList={this.getCategories}
        />
      </>
    );
  }
}
export default CategoryList;
