import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PageNotFoundPage from './containers/PageNotFoundPage'
import HomePage from './containers/HomePage'
import { LoginPageRoutes, AdminPageRoutes } from './PageRoutes'
import { haveRoles } from './controller/auth';

class App extends React.Component {
  renderCMSLayout = () => {
    return (<Switch>
        {AdminPageRoutes.map((route) => route.children !== undefined ?
          route.children.map((child) => haveRoles(route.roles) ? <Route key={child.key} path={child.path} component={child.component} exact/> : null)
          : null
        )}
        {AdminPageRoutes.map((route) => haveRoles(route.roles) && route.path !== undefined ?
          <Route key={route.key} path={route.path} component={route.component} exact /> :
          null
        )}
        <Route component={HomePage} exact/>
        <Route component={PageNotFoundPage} />
      </Switch>);
  }

  renderLoginLayout = () => (
    <Switch>
      {LoginPageRoutes.map((route) => <Route key={route.key} path={route.path} exact component={route.component} />)}
      <Route component={PageNotFoundPage} />
    </Switch>
  )

  render() {
    return (
      <div className="App">
        <Router basename="/">
          <Switch>
            <Route path="/admin/:page?/:subpage?/:thirdpage?/:fourthpage?" exact render={this.renderCMSLayout} />
            <Route path="/resetPassword/:token?" exact render={this.renderLoginLayout} />
            <Route path="/:page?" exact render={this.renderLoginLayout} />
            <Route component={PageNotFoundPage} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
