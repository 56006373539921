import React from "react";
import { Card, Row, Col, Steps, notification } from "antd";
import * as Layout from "../../components/Layout";
import EmployeeForm from "../../components/EmployeeForm";
import {
  createEmployeeData,
  apiUserForgetPassword,
} from "../../controller/api";
import { changeSendingState } from "../../controller/action";
class AddEmployeePage extends React.Component {
  onSubmit = (data) => {
    createEmployeeData({ ...data })
      .then((response) => {
        apiUserForgetPassword({ account: data.idCode });
        notification["success"]({
          message: response.data.message,
          duration: 3,
          onClose: () => this.props.history.push("/admin/employees"),
        });
      })
      .catch((error) => {
        console.log(error.response);
        changeSendingState(false);
        notification["error"]({
          message: error.response.data.message,
          duration: 3,
        });
      });
  };
  render() {
    const { Step } = Steps;
    return (
      <Layout.CMSLayout {...this.props}>
        <Card title="新增毅石成員資料">
          <Row style={{ margin: "20px 0" }}>
            <Col xs={24} sm={6}>
              <Steps direction="vertical">
                <Step title="基本聯絡資訊" />
              </Steps>
            </Col>
            <Col xs={24} sm={18}>
              <EmployeeForm createFlag={true} onSubmit={this.onSubmit} />
            </Col>
          </Row>
        </Card>
      </Layout.CMSLayout>
    );
  }
}
export default AddEmployeePage;
