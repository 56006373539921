import React, { Component } from 'react'
import { Typography, Table } from "antd";
import moment from "moment";
import CurrencyNumberFormat from '../CurrencyNumberFormat';
import CurrencyTag from '../CurrencyTag';
import RorText from '../RorText';

export class PerformanceDetailV2 extends Component {
    expandedRowRender = (record, index, indent) => {
        const columns = [
            { title: '商品分類', dataIndex: 'assetclass', key: 'assetclass' },
            { title: '商品名稱', dataIndex: 'assetname', key: 'assetname' },
            { title: '持有成本', dataIndex: 'principal', key: 'principal', align: 'right', render: (text) => <CurrencyNumberFormat value={parseFloat(text)} /> },
            { title: '未實現損益', dataIndex: 'unrealizedprofit', key: 'unrealizedprofit', align: 'right', render: (text) => <CurrencyNumberFormat value={parseFloat(text)} /> },
            {
                title: '報酬率', dataIndex: 'profitratio', key: 'profitratio',
                render: (text, record) => {
                    return <RorText value={record.ror} />
                    //return <RorText principal={parseFloat(record.principal)} balance={parseFloat(record.principal) + parseFloat(record.unrealizedprofit)} />
                }
            },
            { title: '已實現損益', dataIndex: 'realizedprofit', key: 'realizedprofit', align: 'right', render: (text) => <CurrencyNumberFormat value={parseFloat(text)} /> },
        ];
        return <Table rowKey="id" columns={columns} dataSource={record.holdings} pagination={false} />;
    }
    render() {
        const columns = [
            { title: "帳戶名稱", dataIndex: 'account', key: 'account' },
            { title: "交易幣別", dataIndex: 'currencyid', key: 'currencyid', render: (text) => <CurrencyTag currencyid={text} /> },
            { title: "帳戶託管金額", dataIndex: 'principal', key: 'principal', align: 'right', render: (text) => <CurrencyNumberFormat value={parseFloat(text)} /> },
            { title: "帳戶託管餘額", dataIndex: 'balance', key: 'balance', align: 'right', render: (text) => <CurrencyNumberFormat value={parseFloat(text)} /> },
            { title: "未實現損益", dataIndex: 'unrealizedprofit', key: 'unrealizedprofit', align: 'right', render: (text) => <CurrencyNumberFormat value={parseFloat(text)} /> },
            { title: "權益值", dataIndex: 'equity', key: 'equity', align: 'right', render: (text) => <CurrencyNumberFormat value={parseFloat(text)} /> },
            { title: "年化報酬率", dataIndex: 'irr', key: 'irr', align: 'right', render: (value, record, index) => <RorText value={parseFloat(value)}  />},
            { title: "累計報酬率", dataIndex: 'accror', key: 'ror', align: 'right', render: (value, record, index) => <RorText value={value}  />},
            { title: "累計手續費", dataIndex: 'acccommission', key: 'commission', align: 'right', render: (text) => <CurrencyNumberFormat value={parseFloat(text)} /> },
            { title: "累計交易稅", dataIndex: 'acctax', key: 'tax', align: 'right', render: (text) => <CurrencyNumberFormat value={parseFloat(text)} /> },
            { title: "累計已實現損益", dataIndex: 'accrealizedprofit', key: 'realizedprofit', align: 'right', render: (text) => <CurrencyNumberFormat value={parseFloat(text)} /> },
            { title: "當期報酬率", dataIndex: 'curror', key: 'ror', align: 'right', render: (value, record, index) => <RorText value={value}  />},
            { title: "當期手續費", dataIndex: 'curcommission', key: 'commission', align: 'right', render: (text) => <CurrencyNumberFormat value={parseFloat(text)} /> },
            { title: "當期交易稅", dataIndex: 'curtax', key: 'tax', align: 'right', render: (text) => <CurrencyNumberFormat value={parseFloat(text)} /> },
            { title: "當期已實現損益", dataIndex: 'currealizedprofit', key: 'realizedprofit', align: 'right', render: (text) => <CurrencyNumberFormat value={parseFloat(text)} /> },
        ];
        return (
            <>
                <Typography.Title level={4} style={{ fontWeight: "normal" }}>持有明細</Typography.Title>
                <Typography.Text>
                    期間 {moment(this.props.from).format(process.env.REACT_APP_DATE_FORMAT)} ~ {moment(this.props.to).format(process.env.REACT_APP_DATE_FORMAT)}
                </Typography.Text>
                <Table
                    columns={columns}
                    expandable={{ expandedRowRender: this.expandedRowRender }}
                    dataSource={this.props.details}
                    rowKey="id"
                    style={{ marginTop: 20 }}
                    scroll={{
                        x: 2000,
                        scrollToFirstRowOnChange: true,
                    }}
                />
                <Typography.Text>※ 本資料數值因參考匯率及非即時價格而有時間落差，僅供參考。</Typography.Text>
            </>
        )
    }
}

export default PerformanceDetailV2;