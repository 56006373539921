import React from "react";
import { Layout, Menu, Avatar, Dropdown, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import MainMenu from "./MainMenu";
import BG from "../assets/image/bg.jpg";
import Logo from "../assets/image/logo.png";
import axios from "axios";
import 'moment/locale/zh-tw';
import { getLoginedToken, getLoginedTokenType, isLogin, isLogout, logout } from "../controller/auth";
const copyright = "One Stone © Copyright All Rights Reserved";

export function LoginLayout({ history, children }) {
  if (isLogin()) {
    history.push("/admin");
  }
  return (
    <div
      id="login-wrapper"
      className="full-page"
      style={{ backgroundImage: `url('${BG}')` }}
    >
      <div className="main">
        <div className="form">{children}</div>
        <div className="copyright">{copyright}</div>
      </div>
    </div>
  );
}
export function VertifyLayout({ history, children }) {
  if (isLogin()) {
    history.push("/admin");
  }
  return (
    <div id="colored-wrapper" className="full-page">
      <div className="form">{children}</div>
      <div className="copyright">{copyright}</div>
    </div>
  );
}
export function CMSLayout({ location, history, children }) {
  const { Header, Content, Footer, Sider } = Layout;
  const dropdownMenu = (
    <Menu>
      <Menu.Item key="settingPage">
        <Link to="/admin/settingPage">設定</Link>
      </Menu.Item>
      <Menu.Item key="logout">
        <Typography.Link style={{color:'rgba(0, 0, 0, 0.65)'}} onClick={handelLogout.bind(this, history)}>登出</Typography.Link>
      </Menu.Item>
    </Menu>
  );
  if (isLogout()) {
    //if token null
    history.push("/");
  }
  return (
    <Layout id="CMS-wrapper" className="full-page">
      <Sider
        className="slider"
        breakpoint="lg"
        collapsedWidth="0"
        onCollapse={(type) => {
          !type
            ? document.querySelector(".ant-layout.main").classList.add("overlay")
            : document.querySelector(".ant-layout.main").classList.remove("overlay");
        }}
      >
        <div className="logo">
          <img src={Logo} alt="毅石" />
        </div>
        <MainMenu path={location.pathname} />
      </Sider>
      <Layout className="main">
        <Header className="site-header">
          <Dropdown
            overlay={dropdownMenu}
            trigger={["click"]}
            placement="bottomRight"
            overlayClassName="dropdown"
          >
            <Typography.Link onClick={(e) => e.preventDefault()}>
              <Avatar
                size={35}
                style={{ backgroundColor: "#29221B" }}
                icon={<UserOutlined />}
              />
            </Typography.Link>
          </Dropdown>
        </Header>
        <Content className="site-main">{children}</Content>
        <Footer className="site-footer">{copyright}</Footer>
      </Layout>
    </Layout>
  );
}
function handelLogout(history) {
  const tokenType = getLoginedTokenType();
  const token = getLoginedToken();
  axios
    .post(
      `${process.env.REACT_APP_API_DOMAIN}/auth/logout`,
      {},
      {
        headers: {
          Authorization: `${tokenType} ${token}`,
        },
        validateStatus: (status) => status >= 200 && status < 300,
      }
    )
    .then(() => {
      logout();
      history.push("/");
    })
    .catch(() => {
      logout();
      history.push("/");
    });
}
