import React from "react";
import { Card, Tabs, notification, Skeleton } from "antd";
import * as Layout from "../../components/Layout";
import EmployeeForm from "../../components/EmployeeForm";
import { getEmployeeDetail, editEmployeeData } from "../../controller/api";
import { changWindowWidth, changeSendingState } from "../../controller/action";
import { windowWidth } from "../../controller/observer";
import { toJS } from "mobx";
import { observer } from "mobx-react";
class EditEmployeePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      memberData: null,
    };
    this.employeeId = props.match.params.id;
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateWindowWidth);
    getEmployeeDetail(this.employeeId)
      .then((response) => {
        if (!response) {
          return;
        }
        let memberData = { ...response.data.employee };
        delete memberData.id;
        delete memberData.userId;
        delete memberData.employeeId;
        delete memberData.created_at;
        delete memberData.updated_at;
        if (response.data.yourEmployee !== null) {
          memberData.yourEmployee = response.data.yourEmployee;
        }
        memberData.account = response.data.account;
        this.setState({
          memberData,
        });
      })
      .catch((error) => {
        console.log(error.response);
        changeSendingState(false);
        notification["error"]({
          message: error.response.data.message,
          duration: 3,
        });
      });
  }
  updateWindowWidth = () => {
    changWindowWidth(window.innerWidth)
  };
  getResize = () => {
    let width = toJS(windowWidth.width)
    if (width < "576") {
      return "top";
    } else {
      return "left";
    }
  };
  onSubmit = (data) => {
    delete data.idCode;
    delete data.email;
    delete data.account;
    editEmployeeData(this.employeeId, { ...data }).then((res) => {
      notification["success"]({
        message: "更新成功",
        duration: 3,
        onClose: () => this.props.history.push("/admin/employees"),
      });
    });
  };
  render() {
    const { TabPane } = Tabs;
    return (
      <Layout.CMSLayout {...this.props}>
        <Card title="修改毅石成員資料">
          <Tabs tabPosition={this.getResize()} style={{ width: "100%" }}>
            <TabPane tab="基本聯絡資訊" key="1">
              {this.state.memberData === null ? (
                <Skeleton active />
              ) : (
                <EmployeeForm
                  createFlag={false}
                  memberData={this.state.memberData}
                  onSubmit={this.onSubmit}
                />
              )}
            </TabPane>
          </Tabs>
        </Card>
      </Layout.CMSLayout>
    );
  }
}
export default observer(EditEmployeePage);
