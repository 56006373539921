import React from "react";
import { Modal, Input } from "antd";
import { changeNoteDialogVisible } from "../controller/action";
import { editNote } from "../controller/api";
class NoteEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      confirmLoading: false,
    };
  }
  onTextareaChange = (e) => {
    this.setState({ message: e.target.value });
  };
  onSubmit = () => {
    editNote(this.props.noteId, {
      description: this.state.message,
    }).then((res) => this.props.refresh());
    changeNoteDialogVisible(false);
  };
  onCancel = () => {
    changeNoteDialogVisible(false);
  };
  render() {
    return (
      <Modal
        title="修改筆記內容"
        visible={this.props.visible}
        okText="修改"
        onOk={this.onSubmit}
        okButtonProps={{
          className: "button-color",
          disabled: this.state.message === "" ? true : false,
        }}
        cancelText="取消"
        onCancel={this.onCancel}
        confirmLoading={this.state.confirmLoading}
        destroyOnClose={true}
      >
        <Input.TextArea
          defaultValue={this.props.currentMessage}
          onChange={this.onTextareaChange}
          rows={4}
        />
      </Modal>
    );
  }
}
export default NoteEditDialog;
