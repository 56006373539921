import React from "react";
import { Descriptions, Tabs, Typography, Skeleton } from "antd";
import PageHeader from "../../components/PageHeader";
import * as Layout from "../../components/Layout";
import { getEmployeeDetail } from "../../controller/api";
class EmployeeDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeId: props.match.params.id,
      memberData: null,
    };
    this.employeeId = props.match.params.id;
    this.getEmployeeData();
  }
  getEmployeeData = () => {
    getEmployeeDetail(this.employeeId).then((response) => {
      this.setState({ memberData: response.data.employee });
    });
  };

  render() {
    let routes = null;
    this.state.memberData === null
      ? (routes = [])
      : (routes = [
          {
            path: "/admin",
            breadcrumbName: "首頁",
          },
          {
            path: "/employees",
            breadcrumbName: "毅石成員",
          },
          {
            path: `/${this.props.match.params.id}`,
            breadcrumbName: this.state.memberData.cnName,
          },
        ]);
    const { TabPane } = Tabs;
    const none = <Typography.Text type="secondary">尚未填寫</Typography.Text>;
    return (
      <Layout.CMSLayout {...this.props}>
        {this.state.memberData !== null ? (
          <>
            <PageHeader
              pageTitle={this.state.memberData.cnName}
              routes={routes}
            />
            <div className="main-content">
              <Tabs defaultActiveKey="1" type="card">
                <TabPane tab="基本資料" key="1">
                  <Skeleton active loading={this.state.memberData === null} />
                  <Descriptions
                    className="descriptions-table"
                    bordered
                    column={1}
                  >
                    <Descriptions.Item label="毅石職稱">
                      {this.state.memberData.jobTitle === "" ||
                      this.state.memberData.jobTitle === null
                        ? none
                        : this.state.memberData.jobTitle}
                    </Descriptions.Item>
                    <Descriptions.Item label="中文姓名">
                      {this.state.memberData.cnName === "" ||
                      this.state.memberData.cnName === null
                        ? none
                        : this.state.memberData.cnName}
                    </Descriptions.Item>
                    <Descriptions.Item label="英文名">
                      {this.state.memberData.enName === "" ||
                      this.state.memberData.enName === null
                        ? none
                        : this.state.memberData.enName}
                    </Descriptions.Item>
                    <Descriptions.Item label="電話(日間)">
                      {(this.state.memberData.dayZone,
                      this.state.memberData.dayTel) === null
                        ? none
                        : `${this.state.memberData.dayZone} - ${
                            this.state.memberData.dayTel
                          } ${
                            this.state.memberData.dayExt === null
                              ? ""
                              : `分機 ${this.state.memberData.dayExt}`
                          }`}
                    </Descriptions.Item>
                    <Descriptions.Item label="電話(夜間)">
                      {(this.state.memberData.nightZone,
                      this.state.memberData.nightTel) === null
                        ? none
                        : `${this.state.memberData.nightZone} - ${
                            this.state.memberData.nightTel
                          } ${
                            this.state.memberData.nightExt === null
                              ? ""
                              : `分機 ${this.state.memberData.nightExt}`
                          }`}
                    </Descriptions.Item>
                    <Descriptions.Item label="行動電話">
                      {this.state.memberData.mobile === "" ||
                      this.state.memberData.mobile === null
                        ? none
                        : this.state.memberData.mobile}
                    </Descriptions.Item>
                    <Descriptions.Item label="聯絡地址">
                      {this.state.memberData.contactAddress === "" ||
                      this.state.memberData.contactAddress === null
                        ? none
                        : this.state.memberData.contactAddress}
                    </Descriptions.Item>
                    <Descriptions.Item label="電子郵件">
                      {this.state.memberData.email === ""
                        ? none
                        : this.state.memberData.email}
                    </Descriptions.Item>
                  </Descriptions>
                </TabPane>
              </Tabs>
            </div>
          </>
        ) : (
          <Skeleton active />
        )}
      </Layout.CMSLayout>
    );
  }
}
export default EmployeeDetailPage;
