import React from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import * as Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import { getCustomerListPage } from "../../controller/api";
import CurrencyNumberFormat from "../../components/CurrencyNumberFormat";
import CurrencyTag from "../../components/CurrencyTag";
import RorText from "../../components/RorText";
class CustomerHoldingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      total: 0,
      customerData: [],
    };
  }
  componentDidMount() {
    this.getCustomerData(1);
  }
  getCustomerData = (page) => {
    getCustomerListPage(`?rule=false&count=10&page=${page}`).then((res) => {
      let customerData = [];
      res.data.customers.forEach((customer) => {
        customerData.push(customer);
      });
      this.setState({
        loading: false,
        total: res.data.total,
        customerData,
      });
    });
  };
  render() {
    const routes = [
      {
        path: "/admin",
        breadcrumbName: "首頁",
      },
      {
        path: "/administration",
        breadcrumbName: "行政作業",
      },
      {
        path: "/customerholdings",
        breadcrumbName: "持有資訊",
      },
    ];

    const columns = [
      {
        title: "客戶名稱",
        dataIndex: "name",
        key: "name",
        fixed: 'left',
        render: (text, record) => (
          <Link
            to={`/admin/administration/customerholdings/${record.id}`}
          >
            {text}
          </Link>
        ),
      },
      {
        title: "幣別",
        render: (value, record) => <CurrencyTag currencyid={record.currencyto} />
      },
      {
        title: "帳戶託管金額",
        dataIndex: "fundaccountsprincipal",
        key: "fundaccountsprincipal",
        align: "right",
        render: (value, record) => <CurrencyNumberFormat value={value} />
      },
      {
        title: "帳戶託管餘額",
        dataIndex: "fundaccountsbalance",
        key: "fundaccountsbalance",
        align: "right",
        render: (value, record) => <CurrencyNumberFormat value={value} />
      },
      {
        title: "未實現餘額",
        dataIndex: "holdingsbalance",
        key: "holdingsbalance",
        align: "right",
        render: (value, record) => <CurrencyNumberFormat value={value} />
      },
      {
        title: "權益值",
        dataIndex: "equity",
        key: "equity",
        align: "right",
        render: (value, record) => <CurrencyNumberFormat value={value} />
      },
      {
        title: "報酬率",
        align: "right",
        dataIndex: "ror",
        key: "ror",
        render: (value, record) => {
          return <RorText value={value} />
        }
      },
      {
        title: "手續費", 
        dataIndex: 'commission', 
        key: 'commission', 
        align: 'right', 
        render: (text) => <CurrencyNumberFormat value={parseFloat(text)} />
      },
      { 
        title: "交易稅", 
        dataIndex: 'tax', 
        key: 'tax', 
        align: 'right', 
        render: (text) => <CurrencyNumberFormat value={parseFloat(text)} />
      },
      {
        title: "已實現損益",
        align: "right",
        dataIndex: "totalprofit",
        key: "totalprofit",
        render: (text, record) => <CurrencyNumberFormat value={parseFloat(text)} />
      },
      {
        title: "動作",
        key: "actions",
        width: 200,
        render: (text, record) => (
          <Link
            to={`/admin/administration/customerholdings/${record.id}/batch-create`}
          >
            批次建立交易明細
          </Link>
        ),
      },
    ];
    return (
      <Layout.CMSLayout {...this.props}>
        <PageHeader pageTitle="持有資訊" routes={routes} />
        <div className="main-content">
          <Table
            columns={columns}
            dataSource={this.state.customerData}
            loading={this.state.loading}
            rowKey="id"
            pagination={{
              position: ["bottomLeft"],
              total: this.state.total,
              onChange: (page) => this.getCustomerData(page),
            }}
            scroll={{
              x: 1400,
              scrollToFirstRowOnChange: true,
            }}
          />
        </div>
      </Layout.CMSLayout>
    );
  }
}
export default CustomerHoldingsPage;
