import React from "react";
import {
  Table,
  Upload,
  Button,
  message,
  Typography,
  Space,
  Divider,
  PageHeader,
  Dropdown,
  Menu,
} from "antd";
import {
  CaretDownOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import EditCategoryModal from "./EditCategoryModal";
import DeleteCategoryModal from "./DeleteCategoryModal";
import FileDeleteModal from "./FileDeleteModal";
import FileMoveModal from "./FileMoveModal";
import FileEditModal from "./FileEditModal";
import { getUploadFiles, fileUpload } from "../../controller/api";
import { changeUploadListVisible } from "../../controller/action";
import { changeToUploadList } from "../../controller/observer";
import { Observer } from "mobx-react";
import { getLoginedToken } from "../../controller/auth";
class UploadFiles extends React.Component {
  constructor(props) {
    super(props);
    this.waitUploadCount = 0;
    this.state = {
      loading: true,
      category: {
        id: changeToUploadList.id,
        name: changeToUploadList.cateName,
      },
      selectedRowKeys: [],
      selectedFile: null,
      fileList: [],
      uploadingTotal: 0,
      uploadingList: [],
      uploadingFailList: [],
      cateModalVisible: false,
      cateDeleteModalVisible: false,
      fileMoveModalVisible: false,
      fileEditModalVisible: false,
      fileDeleteModalVisible: false,
    };
  }
  onCateRenameClicked = () => {
    this.setState({ cateModalVisible: !this.state.cateModalVisible });
  };
  onCateDeleteClicked = () => {
    this.setState({
      cateDeleteModalVisible: !this.state.cateDeleteModalVisible,
    });
  };
  onDeleteFileClicked = () => {
    if (!this.state.fileDeleteModalVisible) {
      this.setState({
        fileDeleteModalVisible: true,
      });
    } else {
      this.setState({
        fileDeleteModalVisible: false,
        selectedRowKeys: [],
      });
    }
  };
  onMoveFileClicked = () => {
    if (!this.state.fileMoveModalVisible) {
      this.setState({
        fileMoveModalVisible: true,
      });
    } else {
      this.setState({
        fileMoveModalVisible: false,
        selectedRowKeys: [],
      });
    }
  };
  onEditFileClicked = (file, e) => {
    if (!this.state.fileEditModalVisible) {
      this.setState({
        fileEditModalVisible: true,
        selectedFile: file,
      });
    } else {
      this.setState({
        fileEditModalVisible: false,
        selectedFile: null,
      });
    }
  };
  componentDidMount() {
    this.getFileList();
  }
  getFileList = () => {
    const token = getLoginedToken();
    getUploadFiles(this.state.category.id).then((response) => {
      if (!response) {
        return;
      }
      this.setState({ loading: false }, () => {
        const files = [];
        response.data.files.forEach((item) => {
          files.push({
            key: item.id,
            uid: item.id,
            name: item.fileName + "." + item.fileExt,
            status: "done",
            url: `${process.env.REACT_APP_API_DOMAIN}/customerfiles/${
              this.props.customerId
            }/download/${item.id}?token=${token}`,
          });
        });
        this.setState({ fileList: files });
      });
    });
  };
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  beforeUpload = async (fileList) => {
    this.setState({
      uploadingTotal: fileList.length,
      uploadingList: fileList.map((file) => {
        return {
          uid: file.uid,
        };
      }),
    });
    for (const file of fileList) {
      await this.customRequest(file);
    }
  };
  onSuccess = (response, file) => {
    let uploadingList = [...this.state.uploadingList];
    const index = uploadingList.findIndex((item) => item.uid === file.uid);
    uploadingList.splice(index, 1);
    message.success(`${file.name} 上傳成功`);
    this.setState({ uploadingList }, () => {
      if (uploadingList.length === this.state.uploadingFailList.length) {
        this.setState({
          uploadingList: [],
          uploadingFailList: [],
          uploadingTotal: 0,
        });
        this.waitUploadCount = 0;
        this.getFileList();
      }
      return Promise.resolve(true);
    });
  };
  onError = (msg, file) => {
    let uploadingFailList = [...this.state.uploadingFailList];
    uploadingFailList.push(file);
    console.log("onError", file, uploadingFailList);
    message.error(msg);
    this.setState({ uploadingFailList }, () => {
      if (this.state.uploadingList.length === uploadingFailList.length) {
        this.setState({
          uploadingList: [],
          uploadingFailList: [],
          uploadingTotal: 0,
        });
        this.waitUploadCount = 0;
        this.getFileList();
      }
      return Promise.resolve(true);
    });
  };
  customRequest = (file) => {
    if (file.size > 10485760) {
      return this.onError(
        `"${file.name}" 無法上傳，請確認格式及檔案大小`,
        file
      );
    } else {
      const formData = new FormData();
      formData.append("customerId", this.props.customerId);
      formData.append("categoryId", this.state.category.id);
      formData.append("customerFile", file);
      fileUpload(formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          if (!response) {
            message.error("系統錯誤，請聯繫管理員");
            return Promise.reject(false);
          }
          return this.onSuccess(response, file);
        })
        .catch((err) => {
          console.log(err);
          return this.onError(err.response.data.message, file);
        });
    }
  };
  render() {
    const _this = this;
    const uploadProps = {
      accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, .ppt, .pptx",
      multiple: true,
      showUploadList: false,
      beforeUpload(file, fileList) {
        _this.waitUploadCount++;
        if (_this.waitUploadCount === fileList.length) {
          _this.beforeUpload(fileList);
        }
        return false;
      },
    };
    const columns = [
      {
        title: "名稱",
        dataIndex: "name",
        render: (text, record) => (
          <Typography.Link href={record.url} target="_blank">
            {text}
          </Typography.Link>
        ),
      },
      {
        title: "動作",
        width: "25%",
        render: (text, record) => (
          <>
            <Typography.Link href={record.url} link="true" target="_blank">
              下載
            </Typography.Link>
            <Divider type="vertical" />
            <Typography.Link
              onClick={this.onEditFileClicked.bind(this, record)}
            >
              重新命名
            </Typography.Link>
          </>
        ),
      },
    ];

    return (
      <>
        <div>
          <PageHeader
            onBack={() => changeUploadListVisible(false)}
            subTitle={
              <Dropdown
                overlay={() => (
                  <Menu>
                    <Menu.Item onClick={this.onCateRenameClicked}>
                      重新命名
                    </Menu.Item>
                    <Menu.Item onClick={this.onCateDeleteClicked}>
                      刪除分類
                    </Menu.Item>
                  </Menu>
                )}
              >
                <Typography.Link
                  onClick={(e) => e.preventDefault()}
                  style={{ cursor: "pointer", color:'rgba(0, 0, 0, 0.65)' }}
                >
                  <Observer>{() => changeToUploadList.cateName}</Observer>
                  &nbsp;
                  <CaretDownOutlined />
                </Typography.Link>
              </Dropdown>
            }
            style={{ padding: "5px 10px" }}
            extra={
              <div
                style={{
                  height: 35,
                }}
              >
                <Space
                  style={{
                    display:
                      this.state.selectedRowKeys.length > 0 ? "flex" : "none",
                  }}
                >
                  <Button key="2" onClick={this.onMoveFileClicked}>
                    移動檔案
                  </Button>
                  <Button key="1" danger onClick={this.onDeleteFileClicked}>
                    刪除檔案
                  </Button>
                </Space>
              </div>
            }
          />
        </div>
        <div style={{ display: "block" }}>
          <Upload.Dragger {...uploadProps}>
            <p style={{ padding: "20px 0" }}>
              <Typography.Text>
                請拖曳檔案至虛線區域或點擊進行上傳
              </Typography.Text>
              <br />
              <Typography.Text type="warning">
                上傳格式支援 .jpg, .jpeg, .png, .pdf, .doc, .docx, .ppt,
                .pptx，檔案上傳限制 10 MB
              </Typography.Text>
            </p>
            <Button>
              <UploadOutlined /> 上傳文件
            </Button>
          </Upload.Dragger>
        </div>
        {this.state.uploadingTotal > 0 ? (
          <Typography.Text>
            <Space style={{ marginTop: 20 }}>
              <LoadingOutlined />
              {this.state.uploadingTotal - this.state.uploadingList.length}/
              {this.state.uploadingTotal}
              個檔案上傳中
              {this.state.uploadingFailList.length > 0 ? (
                <Typography.Text type="danger">
                  ，{this.state.uploadingFailList.length} 個檔案失敗
                </Typography.Text>
              ) : null}
            </Space>
          </Typography.Text>
        ) : null}
        <Table
          rowSelection={{
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
          }}
          loading={this.state.loading}
          style={{ marginTop: 20 }}
          columns={columns}
          dataSource={this.state.fileList}
        />
        <EditCategoryModal
          category={this.state.category}
          visible={this.state.cateModalVisible}
          refreshList={this.getFileList}
          changeModalVisible={this.onCateRenameClicked}
        />
        <DeleteCategoryModal
          visible={this.state.cateDeleteModalVisible}
          category={this.state.category}
          refreshList={this.getFileList}
          changeModalVisible={this.onCateDeleteClicked}
        />
        <FileDeleteModal
          visible={this.state.fileDeleteModalVisible}
          category={this.state.category}
          files={this.state.selectedRowKeys}
          refreshList={this.getFileList}
          changeModalVisible={this.onDeleteFileClicked}
        />
        {this.state.fileMoveModalVisible ? (
          <FileMoveModal
            customerId={this.props.customerId}
            visible={this.state.fileMoveModalVisible}
            files={this.state.selectedRowKeys}
            refreshList={this.getFileList}
            changeModalVisible={this.onMoveFileClicked}
          />
        ) : null}
        {this.state.selectedFile === null ? null : (
          <FileEditModal
            visible={this.state.fileEditModalVisible}
            category={this.state.category}
            file={this.state.selectedFile}
            refreshList={this.getFileList}
            changeModalVisible={this.onEditFileClicked}
          />
        )}
      </>
    );
  }
}
export default UploadFiles;
