import React from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import * as Layout from "../components/Layout";
import {  getEmployeeListPage } from "../controller/api";
class EmployeeListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      total: null,
    };
  }
  componentDidMount() {
    this.getEmployeeData(1);
  }
  getEmployeeData = (page) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        getEmployeeListPage(page).then((response) => {
          if (!response) {
            return;
          }
          this.setState({
            loading: false,
            total: response.data.total,
            data: response.data.data,
          });
        });
      }
    );
  };
  render() {
    const routes = [
      {
        path: "/admin",
        breadcrumbName: "首頁",
      },
      {
        path: "/employees",
        breadcrumbName: "毅石成員",
      },
    ];
    const columns = [
      {
        title: "姓名",
        dataIndex: "cnName",
        key: "cnName",
        render: (text, record) => (
          <Link to={`/admin/employees/${record.id}`}>{text}</Link>
        ),
      },
      {
        title: "職稱",
        dataIndex: "jobTitle",
        key: "jobTitle",
      },
      {
        title: "行動電話",
        dataIndex: "mobile",
        key: "mobile",
        responsive: ["md"],
      },
      {
        title: "動作",
        key: "action",
        render: (text, record) => (
          <>
            {record.editable ? (
              <>
                <Link to={`/admin/employees/editEmployee/${record.id}`}>
                  修改
                </Link>
              </>
            ) : null}
          </>
        ),
      },
    ];

    return (
      <Layout.CMSLayout {...this.props}>
        <PageHeader
          pageTitle="毅石成員"
          routes={routes}
          extra={[
            <Link
              to="/admin/employees/addEmployee"
              key="addMember"
              type="primary"
              className="button-color"
              style={{ padding: "10px 15px", borderRadius: 5 }}
              onClick={this.AddMember}
            >
              新增毅石成員
            </Link>,
          ]}
        />
        <div className="main-content">
          <Table
            columns={columns}
            dataSource={this.state.data}
            loading={this.state.loading}
            rowKey="id"
            pagination={{
              position: ["bottomLeft"],
              total: this.state.total,
              onChange: (page) => this.getEmployeeData(page),
            }}
            scroll={{
              scrollToFirstRowOnChange: true,
            }}
          />
        </div>
      </Layout.CMSLayout>
    );
  }
}
export default EmployeeListPage;
