import React from "react";
import { Spin, Card, message, Row, Col, Select, DatePicker, Form } from "antd";
import * as Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import PerformanceOverview from "../../components/Performance/PerformanceOverview";
import moment from "moment";
import PerformanceChartV2 from "../../components/Performance/PerformanceChartV2";
import PerformanceDetailV2 from "../../components/Performance/PerformanceDetailV2";
import TW from "antd/es/date-picker/locale/zh_TW";

import {
  getCustomerPerformanceOveriew,
  getCustomerPerformanceTrend,
  getCustomerPerformanceDetail,
  getCustomerPerformanceAssets,
  getCustomerPerformanceFundaccounts,
} from "../../controller/api";

const { Option } = Select;
const { RangePicker } = DatePicker;

class CustomerPerformanceOverviewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: props.match.params.customerId,
      loadingOverview: true,
      loadingChart: true,
      loadingDetail: true,
      overviewData: null,
      chartData: null,
      chartSeries: null,
      assets: null,
      fundaccounts: null,
      details: null,
      detailFrom: null,
      detailTo: null,
      option: {
        assetid: "all",
        from: moment().subtract(30 * 6, "days").format("YYYY-MM-DD"),
        to: moment().subtract(1, "days").format("YYYY-MM-DD"),
        periodtype: "day",
      },
    };
  }
  componentDidMount() {
    this.fetchAssets();
    this.fetchFundaccounts();
    this.refresh();
  }
  refresh = () => {
    this.fetchPerformanceOverview(
      this.state.option.from,
      this.state.option.to,
      this.state.option.assetid,
      this.state.option.fundaccountid
    ).then(() => {
      this.refreshChart();
      this.fetchPerformanceDetail(
        this.state.option.from,
        this.state.option.to,
        this.state.option.assetid,
        this.state.option.fundaccountid
      );
    });
  };
  refreshChart = () => {
    this.fetchPerformanceChartData(
      this.state.option.periodtype,
      this.state.option.from,
      this.state.option.to,
      this.state.option.assetid,
      this.state.option.fundaccountid
    );
  };
  onPeriodChange = (date, dateString) => {
    const option = { ...this.state.option };
    option.from = dateString[0];
    option.to = dateString[1];
    if (option.from === "" || option.to === "") return;
    this.setState({ option }, () => this.refresh());
  };
  onPeriodTypeChange = (e) => {
    const option = { ...this.state.option };
    option.periodtype = e.target.value;
    this.setState({ option }, () => this.refreshChart());
  };
  onSelectAsset = (value) => {
    const option = { ...this.state.option };
    option.assetid = value;
    this.setState({ option }, () => this.refresh());
  };
  onSelectFundaccounts = (value) => {
    const option = { ...this.state.option };
    option.fundaccountid = value;
    this.setState({ option }, () => this.refresh());
  };
  fetchAssets = () => {
    return getCustomerPerformanceAssets(this.state.customerId).then((response) => {
      let assets = response.data.map((row) => {
        row.name = row.classname + ' - ' + row.assetname;
        return row;
      });
      this.setState({
        assets: assets
      });
    });
  }
  fetchFundaccounts = () => {
    return getCustomerPerformanceFundaccounts(this.state.customerId).then((response) => {
      this.setState({
        fundaccounts: response.data
      });
    });
  }
  getQueryParameter = (from, to, assetid, fundaccountid) => {
    let queryString = `from=${from}&to=${to}`;
    if (fundaccountid && fundaccountid !== "all") queryString += `&fundaccountid=${fundaccountid}`;
    if (assetid && assetid !== "all") queryString += `&assetid=${assetid}`;
    return queryString;
  };
  fetchPerformanceOverview = (from, to, assetid, fundaccountid) => {
    let queryString = this.getQueryParameter(from, to, assetid, fundaccountid);
    this.setState({ loadingOverview: true });
    return getCustomerPerformanceOveriew(this.state.customerId, queryString)
      .then((response) => {
        let overviewData = response.data;
        overviewData.roi = (((response.data.balance - response.data.principal) / response.data.principal) * 100).toFixed(2);
        this.setState({
          overviewData: overviewData,
          loadingOverview: false,
        });
      })
      .catch((err) => {
        message.error({
          content: err.response.data.message,
          duration: 3,
          onClose: () => this.props.history.push("/admin/customers"),
        });
      });
  };
  getPeriodDates = (from, to, periodType) => {
    // 完整日期陣列
    let periodDates = [];
    let fromDate = moment(from);
    let toDate = moment(to);
    while (fromDate <= toDate) {
      let dateString = moment(fromDate).format("YYYY-MM-DD");
      periodDates.push(dateString);
      fromDate = fromDate.add(1, "days");
      if (dateString === toDate.format("YYYY-MM-DD")) {
        break;
      }
    }
    return periodDates;
  };
  fillDateSeries = (from, to, periodType, seriesData) => {
    if (periodType !== "day") return seriesData; // TODO 週/月/年也會需要補資料
    let results = [];
    let periodDates = this.getPeriodDates(from, to, periodType);
    periodDates.forEach((date) => {
      const currentRawRow = seriesData.find((row) => {
        return moment(row.from).format("YYYY-MM-DD") === date;
      });
      let lastRow = results[results.length - 1];
      lastRow = lastRow ? Object.assign({}, lastRow) : {};
      lastRow.profit = 0; // 當期損益不具連續性，不應參考先前資料
      lastRow.realizedprofit = 0; // 當期損益不具連續性，不應參考先前資料
      lastRow.unrealizedprofit = 0; // 當期損益不具連續性，不應參考先前資料
      let currentRow = currentRawRow ? currentRawRow : lastRow;
      let row = {
        ...currentRow,
        date: date,
        raw: currentRawRow,
      };
      results.push(row);
    });
    return results;
  };
  fetchPerformanceChartData = (periodtype, from, to, assetid, fundaccountid) => {
    this.setState({ loadingChart: true });
    if (!periodtype) periodtype = 'day';
    let queryString = this.getQueryParameter(from, to, assetid, fundaccountid);
    queryString += `&periodtype=${periodtype}`;

    getCustomerPerformanceTrend(this.state.customerId, queryString).then((response) => {
      let responseData = response.data;
      let seriesData = this.fillDateSeries(from, to, periodtype, responseData.series);
      const nameSeries = seriesData.map((data) => data.name);
      const accumulatedProfitSeries = seriesData.map((data) => data.accumulatedprofit);
      const profitSeries = seriesData.map((data) => data.profit);
      const principalSeries = seriesData.map((data) => data.principal);
      const rorSeries = seriesData.map((data) => Math.round(data.ror * 10000)/100);
      this.setState({
        loadingChart: false,
        chartSeries: seriesData,
        chartData: {
          name: nameSeries,
          accumulatedprofit: accumulatedProfitSeries,
          profit: profitSeries,
          principal: principalSeries,
          ror: rorSeries
        },
      });
    });
  };
  fetchPerformanceDetail = (from, to, assetid, fundaccountid) => {
    let queryString = this.getQueryParameter(from, to, assetid, fundaccountid);
    this.setState({ loadingDetail: true });
    getCustomerPerformanceDetail(this.state.customerId, queryString).then(
      (response) => {
        let data = response.data;
        this.setState({
          loadingDetail: false,
          detailFrom: from,
          detailTo: to,
          details: data,
        });
      }
    );
  };
  onClickChart = (e) => {
    if( this.state.chartSeries ) {
      let item = this.state.chartSeries[e.dataIndex];
      let from = item.from;
      let to = item.to;
      this.fetchPerformanceDetail(
        from,
        to,
        this.state.option.assetid,
        this.state.option.fundaccountid
      );
    }
  };
  render() {
    const routes =
      this.state.overviewData === null
        ? []
        : [
          {
            path: "/admin",
            breadcrumbName: "首頁",
          },
          {
            path: "/customers",
            breadcrumbName: "我的客戶",
          },
          {
            breadcrumbName: `${this.state.overviewData.name} 的投資績效`,
          },
        ];
    return (
      <Layout.CMSLayout {...this.props}>
        {this.state.overviewData === null ? null : (
          <>
            <PageHeader
              pageTitle={`${this.state.overviewData.name} 的投資績效`}
              routes={routes}
            >
              <Row
                justify="space-between"
              >
                <Col>
                  {this.state.assets && (
                    <>
                      <Form.Item label="商品分類">
                        <Select
                          defaultValue="all"
                          style={{ width: 200 }}
                          onChange={this.onSelectAsset}
                        >
                          <Option value="all">全部</Option>
                          {this.state.assets.map((asset, i) => (
                            <Option key={asset.id} value={asset.id}>
                              {asset.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </>
                  )}
                </Col>
                <Col>
                  {this.state.fundaccounts && (
                    <>
                      <Form.Item label="帳戶">
                        <Select
                          defaultValue="all"
                          style={{ width: 200 }}
                          onChange={this.onSelectFundaccounts}
                        >
                          <Option value="all">全部</Option>
                          {this.state.fundaccounts.map((fundaccounts, i) => (
                            <Option key={fundaccounts.id} value={fundaccounts.id}>
                              {fundaccounts.account}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </>
                  )}
                </Col>
                <Col>
                  <Row gutter={[10, 10]}>
                    <Col>
                      <RangePicker
                        inputReadOnly={true}
                        format={process.env.REACT_APP_DATE_FORMAT}
                        defaultValue={[
                          moment(this.state.option.from),
                          moment(this.state.option.to),
                        ]}
                        locale={TW}
                        onChange={this.onPeriodChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </PageHeader>
          </>
        )}
        {this.state.overviewData === null ? null : (
          <>
            <Card style={{ marginTop: 20 }}>
              <Spin spinning={this.state.loadingOverview}>
                <PerformanceOverview overview={this.state.overviewData} />
              </Spin>
            </Card>
          </>
        )}
        <Card style={{ marginTop: 20 }}>
          <Spin spinning={this.state.loadingChart}>
            {this.state.chartData === null ? null : (
              <PerformanceChartV2
                chartData={this.state.chartData}
                default={this.state.option}
                onPeriodTypeChange={this.onPeriodTypeChange}
                onClick={this.onClickChart}
              />
            )}
          </Spin>
        </Card>
        <Card style={{ marginTop: 20 }}>
          <Spin spinning={this.state.loadingDetail}>
            {this.state.details === null ? null : (
              <PerformanceDetailV2
                from={this.state.detailFrom}
                to={this.state.detailTo}
                details={this.state.details}
              />
            )}
          </Spin>
        </Card>
      </Layout.CMSLayout>
    );
  }
}
export default CustomerPerformanceOverviewPage;
