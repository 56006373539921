import React, { Component } from 'react'
import { Tag } from "antd"
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { PropTypes } from 'prop-types';

/**
 * 報酬率標籤
 * @augments {Component<Props, State>}
 */
export class RorTag extends Component {
    static propTypes = {
        /** 報酬率 */
        value: PropTypes.number
      }
    render() {
        let color = "";
        let icon = <></>;
        if( this.props.value < 0 ) {
            color = "#18b118";
            icon = <CaretDownOutlined />;
        } else if( this.props.value > 0 ) {
            color = "#de3131";
            icon = <CaretUpOutlined />;
        }
        return (
            <Tag color={color} className="tag-size">{icon} {this.props.value} %</Tag>
        )
    }
}

export default RorTag
