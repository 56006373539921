import React from "react";
import { Modal } from "antd";

import { deleteFile } from "../../controller/api";
class FileDeleteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  handleOk = () => {
    this.setState({ loading: true }, () => {
      this.props.files.forEach((key, index) => {
        deleteFile(key)
          .then((res) => {
            if (index + 1 === this.props.files.length) {
              this.setState({ loading: false });
              this.props.refreshList();
              this.props.changeModalVisible();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    });
  };
  handleCancel = () => {
    this.props.changeModalVisible();
  };
  render() {
    return (
      <Modal
        title={"刪除 " + this.props.files.length + " 個項目"}
        visible={this.props.visible}
        destroyOnClose={true}
        okText="刪除"
        cancelText="取消"
        onOk={this.handleOk}
        okButtonProps={{ loading: this.state.loading, type: "danger" }}
        onCancel={this.handleCancel}
      >
        <p>
          確定要從&nbsp;{this.props.category.name}&nbsp;刪除&nbsp;{this.props.files.length}&nbsp;個項目？
        </p>
      </Modal>
    );
  }
}
export default FileDeleteModal;
