import React from "react";
import { Table, Button, Divider, Space, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import * as Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import CreateAccountDrawer from "../../components/Account/CreateAccountDrawer";
import EditAccountDrawer from "../../components/Account/EditAccountDrawer";
import DeleteAccountModal from "../../components/Account/DeleteAccountModal";
import AccountDebitCreditDrawer from "../../components/Account/AccountDebitCreditDrawer";
import AccountsDetailDrawer from "../../components/Account/AccountsDetailDrawer";
import CurrencyNumberFormat from "../../components/CurrencyNumberFormat";

import {
  getCustomerDetail,
  getCustodianData,
  getAuthorizationmodes,
  getAssets,
  getAccounts,
} from "../../controller/api";
import CurrencyTag from "../../components/CurrencyTag";
import RorText from "../../components/RorText";
class HoldingsListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      customerid: props.match.params.customerId,
      customername: "",
      fundaccounts: [],
      custodians: [],
      authorizationmodes: [],
      assets: [],
      createDrawerVisible: false,
      editDrawerVisible: false,
      accountDebitCreditDrawerVisible: false,
      detailDrawerVisible: false,
      deleteAccountVisible: false,
      viewingAccount: null,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
    };
  }
  componentDidMount() {
    this.getCustomerDetail();
    this.getFundaccounts(1);
    this.getCustodian();
    this.getAuthorizationmodes();
    this.getAssets();
  }
  getCustomerDetail = () => {
    getCustomerDetail(this.state.customerid).then((res) => {
      this.setState({
        customername: res.data.customer.name,
      });
    });
  };
  getFundaccounts = (page) => {
    if (!page) page = 1;
    getAccounts(this.state.customerid, `?count=${10}&page=${page}`).then(
      (res) => {
        let pagination = { ...this.state.pagination };
        pagination.total = res.data.fundaccountsqty;
        pagination.current = page;
        this.setState({
          loading: false,
          fundaccounts: res.data.fundaccounts,
          pagination,
        });
      }
    );
  };
  getCustodian = () => {
    getCustodianData().then((res) =>
      this.setState({ custodians: res.data.custodians })
    );
  };
  getAuthorizationmodes = () => {
    getAuthorizationmodes().then((res) => {
      this.setState({ authorizationmodes: res.data.authorizationmodes });
    });
  };
  getAssets = () => {
    getAssets().then((res) => {
      this.setState({
        assets: res.data.assets,
      });
    });
  };
  createDrawerVisible = () => {
    this.setState({
      createDrawerVisible: !this.state.createDrawerVisible,
    });
  };
  editDrawerVisible = (data) => {
    this.setState({
      editDrawerVisible: !this.state.editDrawerVisible,
      viewingAccount: data,
    });
  };
  accountDebitCreditDrawerVisible = (data) => {
    this.setState({
      accountDebitCreditDrawerVisible: !this.state
        .accountDebitCreditDrawerVisible,
      viewingAccount: data,
    });
  };
  detailDrawerVisible = (account) => {
    this.getFundaccounts();
    this.setState({
      detailDrawerVisible: !this.state.detailDrawerVisible,
      viewingAccount: account,
    });
  };
  deleteAccountVisible = (account) => {
    this.setState({
      viewingAccount: account,
      deleteAccountVisible: !this.state.deleteAccountVisible,
    });
  };
  render() {
    const routes = [
      {
        path: "/admin",
        breadcrumbName: "首頁",
      },
      {
        path: "/administration",
        breadcrumbName: "行政作業",
      },
      {
        path: "/customerholdings",
        breadcrumbName: "持有資訊",
      },
      {
        path: "/:customerId",
        breadcrumbName: this.state.customername,
      },
    ];

    const columns = [
      {
        title: "帳戶名稱",
        key: "account",
        fixed: 'left',
        render: (data) => {
          return (
            <Button
              type="link"
              onClick={this.detailDrawerVisible.bind(this, data)}
            >
              {data.account === null ? "尚未命名" : data.account}
            </Button>
          );
        },
      },
      {
        title: "幣別",
        render: (value, record) => <CurrencyTag currencyid={record.currencyid} />
      },
      {
        title: "帳戶託管金額",
        key: "principal",
        dataIndex: "principal",
        align: 'right',
        render: (value, record) => <CurrencyNumberFormat value={parseFloat(value)} />
      },
      {
        title: "帳戶託管餘額",
        key: "balance",
        dataIndex: "balance",
        align: 'right',
        render: (value, record) => <CurrencyNumberFormat value={parseFloat(value)} />
      },
      {
        title: "未實現餘額",
        key: "unrealizedbalance",
        dataIndex: "unrealizedbalance",
        align: 'right',
        render: (value, record) => <CurrencyNumberFormat value={parseFloat(value)} />
      },
      {
        title: "權益值",
        key: "equity",
        dataIndex: "equity",
        align: 'right',
        render: (value, record) => <CurrencyNumberFormat value={parseFloat(value)} />
      },
      {
        title: "報酬率",
        align: 'right',
        render: (value, record) => {
          return <RorText principal={parseFloat(record.principal)} balance={parseFloat(record.unrealizedbalance) + parseFloat(record.balance)} />
        }
      },
      {
        title: "保管方",
        dataIndex: "custodian",
        key: "custodian",
        render: (data) => data.name,
      },
      {
        title: "投資商品數量",
        dataIndex: "holdingsqty",
        key: "holdingsqty",
      },
      {
        title: "託管日期",
        dataIndex: "enabledate",
        key: "enabledate"
      },
      {
        title: () => (
          <Tooltip title="如商品已建立績效紀錄則無法刪除。">
            <Space>
              動作
              <QuestionCircleOutlined />
            </Space>
          </Tooltip>
        ),
        key: "action",
        width: 200,
        render: (data) => {
          let viewingdata = {
            id: data.id,
            account: data.account,
            authorizationmodesid: data.authorizationmodesid,
          };
          return (
            <>
              <Button
                type="link"
                onClick={this.editDrawerVisible.bind(this, viewingdata)}
              >
                編輯
              </Button>
              <Divider type="vertical" />
              <Button
                type="link"
                onClick={this.accountDebitCreditDrawerVisible.bind(
                  this,
                  viewingdata
                )}
              >
                入出金
              </Button>
              {data.deletable ? (
                <>
                  <Divider type="vertical" />
                  <Button
                    type="link"
                    onClick={this.deleteAccountVisible.bind(this, viewingdata)}
                  >
                    刪除
                  </Button>
                </>
              ) : null}
            </>
          );
        },
      },
    ];
    return (
      <Layout.CMSLayout {...this.props}>
        <PageHeader
          pageTitle={this.state.customername}
          routes={routes}
          extra={[
            <Button
              key="createFundaccount"
              onClick={this.createDrawerVisible}
              className="button-color"
              style={{ marginBottom: "10px", borderRadius: 5, float: "right" }}
            >
              新增資金帳戶
            </Button>,
          ]}
        />
        <div className="main-content">
          <Table
            columns={columns}
            dataSource={this.state.fundaccounts}
            loading={this.state.loading}
            rowKey="id"
            pagination={{
              position: ["bottomLeft"],
              total: this.state.pagination.total,
              current: this.state.pagination.current,
              pageSize: this.state.pagination.pagesize,
              showSizeChanger: false,
              onChange: (page) => this.getFundaccounts(page),
            }}
            scroll={{
              scrollToFirstRowOnChange: true,
              x: 1400,
            }}
          />
        </div>
        {!this.state.createDrawerVisible ? null : (
          <CreateAccountDrawer
            customerid={this.state.customerid}
            visible={this.state.createDrawerVisible}
            custodian={this.state.custodians}
            authorizationmodes={this.state.authorizationmodes}
            assets={this.state.assets}
            onClose={this.createDrawerVisible}
            reloadList={this.getFundaccounts}
          />
        )}
        {!this.state.editDrawerVisible ? null : (
          <EditAccountDrawer
            visible={this.state.editDrawerVisible}
            onClose={this.editDrawerVisible}
            authorizationmodes={this.state.authorizationmodes}
            viewing={this.state.viewingAccount}
            reloadList={this.getFundaccounts}
          />
        )}
        {!this.state.accountDebitCreditDrawerVisible ? null : (
          <AccountDebitCreditDrawer
            visible={this.state.accountDebitCreditDrawerVisible}
            onClose={this.accountDebitCreditDrawerVisible}
            reloadList={this.getFundaccounts}
            accountid={this.state.viewingAccount.id}
            customerId={this.state.customerid}
            getfundaccounts={this.getFundaccounts}
          />
        )}
        {!this.state.detailDrawerVisible ? null : (
          <AccountsDetailDrawer
            visible={this.state.detailDrawerVisible}
            assets={this.state.assets}
            accountDetail={this.state.viewingAccount}
            onClose={this.detailDrawerVisible}
            reloadList={this.getFundaccounts}
          />
        )}
        {!this.state.deleteAccountVisible ? null : (
          <DeleteAccountModal
            visible={this.state.deleteAccountVisible}
            viewing={this.state.viewingAccount}
            onClose={this.deleteAccountVisible}
            reloadList={this.getFundaccounts}
          />
        )}
      </Layout.CMSLayout>
    );
  }
}
export default HoldingsListPage;
