import React from "react";
import { Form, Input, InputNumber, Select, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import { TYPE_PERFORMANCE, TYPE_TRANSACTION } from "../../consts/HoldingTypes";

class HoldingsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assetid: null,
      assetprincipal: null,
      unitprice: 0,
      quantity: 0,
      type: TYPE_PERFORMANCE,
    };
  }
  onChangeQuantity = (value) => {
    this.setState({
      quantity: value
    });
  };
  onChangeUnitPrice = (value) => {
    this.setState({
      unitprice: value
    });
    if (this.state.quantity) {
      let principle = this.state.quantity * value;
      this.onChangePrinciple(principle);
    }
  };
  onChangePrinciple = (value) => {
    this.setState({
      assetprincipal: value
    });
    if (this.props.calculateRemainPrincipal != null) {
      this.props.calculateRemainPrincipal(value);
    }
  };
  onSelectAsset = (item) => {
    this.setState({ assetid: item });
  };
  onSelectPerformanceType = (type) => {
    this.setState({ type: type });
  };
  render() {
    return (
      <>
        <Form.Item
          label="選擇商品"
          name={
            this.props.createflag
              ? [this.props.fielddata.name, "assetid"]
              : "assetid"
          }
          rules={[{ required: true, message: "請選擇商品" }]}
        >
          <Select
            size="large"
            placeholder="請選擇商品"
            allowClear
            onChange={this.onSelectAsset}
          >
            {this.props.assets !== undefined
              ? this.props.assets.map((row) => (
                <Select.Option key={"assetsid" + row.id} value={row.id}>
                  {row.assetname}
                </Select.Option>
              ))
              : null}
          </Select>
        </Form.Item>
        <Form.Item
          label="績效記錄方式"
          name={
            this.props.createflag
              ? [this.props.fielddata.name, "type"]
              : "type"
          }
          rules={[{ required: true, message: "請選擇績效記錄方式" }]}
        >
          <Select
            size="large"
            placeholder="請選擇績效記錄方式"
            allowClear
            onChange={this.onSelectPerformanceType}
          >
            <Select.Option value={TYPE_PERFORMANCE}>帳戶餘額</Select.Option>
            <Select.Option value={TYPE_TRANSACTION}>交易明細</Select.Option>
          </Select>

        </Form.Item>
        {this.state.type === TYPE_TRANSACTION && <>
          <Form.Item
            label="買進時間"
            name={
              this.props.createflag
                ? [this.props.fielddata.name, "enabledate"]
                : "enabledate"
            }
            rules={[{ required: true, message: "買進時間" }]}
          >
            <DatePicker
              size="large"
              style={{ width: "100%" }}
              showTime={true}
              locale={locale}
              format={`${process.env.REACT_APP_DATE_FORMAT} (dd) HH:mm`}
              placeholder="請選擇時間"
            />
          </Form.Item>
          <Form.Item
            label={`本金 ${this.props.createflag
              ? `(可分配餘額:${this.props.remaining === 0 ? 0 : this.props.remaining
              })`
              : ""
              }`}
            name={
              this.props.createflag
                ? [this.props.fielddata.name, "principal"]
                : "principal"
            }
            rules={[
              {
                required: true,
                message: "請輸入本金",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              size="large"
              disabled={
                this.props.remaining === 0 && this.state.assetprincipal === null
              }
              placeholder="請輸入商品初始本金"
              min="0"
              onChange={this.onChangePrinciple}
            />
          </Form.Item>
          <Form.Item
            label="數量"
            name={
              this.props.createflag
                ? [this.props.fielddata.name, "quantity"]
                : "quantity"
            }
            rules={[{ required: true, message: "請輸入數量" }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              size="large"
              placeholder="請輸入買進數量"
              min="0"
              onChange={this.onChangeQuantity}
            />
          </Form.Item>
          <Form.Item
            label="成交價格"
            name={
              this.props.createflag
                ? [this.props.fielddata.name, "unitprice"]
                : "unitprice"
            }
            rules={[{ required: true, message: "請輸入成交價格" }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              size="large"
              placeholder=""
              min="0"
              onChange={this.onChangeUnitPrice}
              disabled={true}
            />
          </Form.Item>
          <Form.Item
            label="備註"
            name={
              this.props.createflag ? [this.props.fielddata.name, "note"] : "note"
            }
          >
            <Input.TextArea size="large" placeholder="ex: 此商品進行美金交易" />
          </Form.Item>
        </>}
      </>
    );
  }
}
export default HoldingsForm;
