import React from "react";
import { Link } from "react-router-dom";
import { PageHeader as AntdPageHeader } from "antd";

class PageHeader extends React.Component {
  itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={'/' + paths.join("/")}>{route.breadcrumbName}</Link>
    );
  };
  render() {
    return (
      <AntdPageHeader
        ghost={false}
        breadcrumb={{ itemRender: this.itemRender, routes: this.props.routes }}
        title={this.props.pageTitle}
        extra={this.props.extra}
      >
        {this.props.children}
      </AntdPageHeader>
    );
  }
}

export default PageHeader;
