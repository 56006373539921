import React from "react";
import { Modal, Input, notification } from "antd";
import { editCategory } from "../../controller/api";
import { changeUploadListVisible } from "../../controller/action";
class EditCategoryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      cateName: props.category.name,
    };
  }
  onInputChange = (e) => {
    this.setState({ cateName: e.target.value });
  };
  handleOk = () => {
    const data = { categoryName: this.state.cateName };
    this.setState({ loading: true }, () => {
      editCategory(this.props.category.id, data)
        .then((response) => {
          this.setState({ loading: false }, () => {
            notification["success"]({
              message: response.data.message,
              duration: 3,
              onClose: () => {
                this.props.changeModalVisible();
                this.props.refreshList();
                changeUploadListVisible(
                  true,
                  this.props.category.id,
                  this.state.cateName
                );
              },
            });
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        });
    });
  };
  handleCancel = () => {
    this.props.changeModalVisible();
  };
  render() {
    return (
      <Modal
        title="修改分類名稱"
        visible={this.props.visible}
        destroyOnClose={true}
        okText="確認"
        cancelText="取消"
        onOk={this.handleOk}
        okButtonProps={{
          className:"button-color",
          loading: this.state.loading,
          disabled:
            this.state.cateName !== "" && this.state.cateName.trim()
              ? false
              : true,
        }}
        onCancel={this.handleCancel}
      >
        <Input
          value={this.state.cateName}
          onChange={this.onInputChange}
          placeholder="請輸入分類名稱"
        />
      </Modal>
    );
  }
}
export default EditCategoryModal;
