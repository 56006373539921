import moment from "moment";

export const tokenIsExpired = () => {
  const expire = Number(localStorage.getItem("expire"));
  const now = moment().valueOf();

  console.log(
    "tokenIsExpired: ",
    now > expire,
    "Now Date: ",
    moment(now).format("YYYY-MM-DD HH:mm:ss"),
    "Access Token Expire Date:",
    moment(expire).format("YYYY-MM-DD HH:mm:ss")
  );
  return now > expire;
};
export const login = (tokenType, token, userId, roles, expiresIn) => {
  if (!tokenType) {
    throw new Error("Login without tokenType type. ");
  }
  if (!token) {
    throw new Error("Login without token. ");
  }
  if (!userId) {
    throw new Error("Login without userId. ");
  }
  if (!Array.isArray(roles)) {
    throw new Error("Login without roles. ");
  }
  if (!expiresIn) {
    throw new Error("Login without expiresIn. ");
  }
  localStorage.setItem("tokenType", tokenType);
  localStorage.setItem("token", token);
  localStorage.setItem("userId", userId);
  localStorage.setItem("roles", JSON.stringify(roles));
  localStorage.setItem("issue", moment().valueOf());

  // 正常應該直接判斷目前時間是否超出 Access Token 的 Expire Date
  // 目前因後端超出 Expire Date，改成在收到 Token 後到 Expire Date 之前的時間 (30分鐘 ~ 60分鐘) 當做過期做 Renew
  // expiresIn = 3
  localStorage.setItem("expire", moment().add(expiresIn, "second").valueOf());

  console.log(
    "Access Token Expire in: ",
    expiresIn,
    "seconds, Date:",
    moment().add(expiresIn, "second").format("YYYY-MM-DD HH:mm:ss")
  );
};

export const logout = () => {
  localStorage.removeItem("tokenType");
  localStorage.removeItem("token");
  localStorage.removeItem("userId");
  localStorage.removeItem("roles");
  localStorage.removeItem("issue");
  localStorage.removeItem("expire");
};

export const isLogin = () => {
  return (
    localStorage.getItem("token") !== null &&
    localStorage.getItem("expire") &&
    localStorage.getItem("roles") !== null &&
    localStorage.getItem("userId") !== null &&
    localStorage.getItem("tokenType") !== null
  );
};
export const isLogout = () => {
  return (
    localStorage.getItem("token") === null &&
    localStorage.getItem("expire") &&
    localStorage.getItem("roles") === null &&
    localStorage.getItem("userId") === null &&
    localStorage.getItem("tokenType") === null
  );
};

export const haveRoles = (roles) => {
  const userRoles = getLoginedRoles();
  let haveRole = false;
  roles.forEach((role) => {
    if (userRoles.includes(role)) {
      haveRole = true;
    }
  });
  return haveRole;
};

export const getLoginedUserId = () => {
  return Number(localStorage.getItem("userId"));
};
export const getLoginedTokenType = () => {
  return localStorage.getItem("tokenType");
};
export const getLoginedToken = () => {
  return localStorage.getItem("token");
};
export const getLoginedRoles = () => {
  return localStorage.getItem("roles") === null
    ? []
    : JSON.parse(localStorage.getItem("roles"));
};
