import React from "react";
import {
  Spin,
  Button,
  Card,
  Typography,
  notification,
  Descriptions,
  message,
} from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import * as Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import {
  getCustomerDetail,
  getAccounts,
  getHoldings,
  createBatchPerformances,
} from "../../controller/api";
import moment from "moment";
import { Link } from "react-router-dom";
import BatchPerformanceForm from "../../components/Performance/BatchPerformanceForm";
import CurrencyNumberFormat from "../../components/CurrencyNumberFormat";

class CreateBatchPerformanceDetailPageV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      customerid: props.match.params.customerId, // Get customerId from url.
      customername: null,
      fundaccounts: [],
      performances: [],
      createsuccessed: [],
    };
  }
  componentDidMount() {
    this.refreshCustomerDetail();
    this.refreshFundaccounts()
      .then(() => {
        this.state.fundaccounts.forEach((item) => {
          this.refreshHoldings(item.id);
        });
      })
      .then(() => {
        this.setState({ loading: false });
      });
  }
  refreshCustomerDetail = () => {
    getCustomerDetail(this.state.customerid)
      .then((res) => {
        this.setState({
          customername: res.data.customer.cnName,
        });
      })
      .catch((error) => {
        console.log(error);
        notification["error"]({
          message: error.message,
          duration: 3,
        });
      });
  };
  refreshFundaccounts = async () => {
    await getAccounts(this.state.customerid, `?all=true`)
      .then((res) => {
        let fundaccounts = res.data.fundaccounts;
        fundaccounts = fundaccounts.map((row) => {
          const fundaccount = this.state.fundaccounts.find(
            (row2) => row2.id === row.id
          );
          let submitted = false;
          let holdings = [];
          if (fundaccount) {
            submitted = fundaccount.submitted;
            holdings = fundaccount.holdings;
          }
          row.submitted = submitted;
          row.holdings = holdings;
          return row;
        });
        this.setState({ fundaccounts: fundaccounts });
      })
      .catch((error) => {
        console.log(error);
        notification["error"]({
          message: error.message,
          duration: 3,
        });
      });
  };
  refreshHoldings = (id) => {
    let fundaccounts = [...this.state.fundaccounts];
    getHoldings(id, `?all=true`)
      .then((res) => {
        fundaccounts.forEach((fundaccount) => {
          if (fundaccount.id === id) {
            fundaccount.holdings = res.data.holdings;
          }
        });
        this.setState({ fundaccounts });
      })
      .catch((error) => {
        console.log(error);
        notification["error"]({
          message: error.message,
          duration: 3,
        });
      });
  };
  getLastAccountBalance = (fundaccountId, key) => {
    // 查詢最後一筆帳戶餘額
    const performances = this.state.performances;
    const fundaccount = this.state.fundaccounts.find(
      (row) => row.id === fundaccountId
    );
    let lastBalance = fundaccount.balance;
    let fundAccountPerformance = performances.filter(
      (row) => row.fundaccountId === fundaccountId
    );
    let index = fundAccountPerformance.length;
    // 若有指定 key 則找指定 key 的上一筆餘額
    if (key) {
      index = fundAccountPerformance.findIndex((row) => row.key === key);
    }
    let lastPerformance = fundAccountPerformance[index - 1];
    if (lastPerformance) {
      lastBalance = lastPerformance.balance;
    }
    return lastBalance;
  };
  onPerformanceItemAdd = (fundaccountId) => {
    const performances = this.state.performances;
    const lastBalance = this.getLastAccountBalance(fundaccountId);
    const lastTime =
      performances.length > 0
        ? performances[performances.length - 1].time
        : moment().format("YYYY-MM-DD HH:mm:ss");
    performances.push({
      key: "perf-" + performances.length,
      fundaccountId: fundaccountId,
      time: lastTime,
      holdingid: null,
      balance: lastBalance,
      realizedprofit: null,
      deposit: null,
      unrealizedprofit: null,
      tax: null,
      commission: null,
      note: null,
    });
    this.setState({ performances: performances });
  };
  onPerformanceItemDelete = (key) => {
    const performances = this.state.performances;
    let deleteItem = performances.find((row) => row.key === key);
    if (!deleteItem) {
      console.warn("Cannot find item: " + key);
      return;
    }
    performances.splice(performances.indexOf(deleteItem), 1);
    this.setState({ performances: performances });
  };
  onPerformanceItemValueChange = (key, field, value) => {
    const performances = this.state.performances;
    let updateItem = performances.find((row) => row.key === key);
    if (!updateItem) {
      console.warn("Cannot find item: " + key);
      return;
    }
    const lastBalance = this.getLastAccountBalance(
      updateItem.fundaccountId,
      key
    );

    if (field === "balance") {
      // 若輸入餘額時，與上次的差額直接計算做為已實現損益
      let balance = value;
      if (!isNaN(balance)) {
        let realizedprofit = balance - lastBalance;
        updateItem.realizedprofit = realizedprofit;
      }
    }
    if (field === "realizedprofit") {
      // 若輸入已實現損益時，與上次的差額扣掉已實現損益計算入出金額
      let balance = updateItem.balance;
      let realizedprofit = value;
      if (!isNaN(balance) && !isNaN(realizedprofit)) {
        let buy = balance - lastBalance - realizedprofit;
        updateItem.buy = buy;
      }
    }
    if (field === "buy") {
      // 若輸入出金額時，與上次的差額扣掉入出金額計算已實現損益
      let balance = updateItem.balance;
      let buy = value;
      let deposit = updateItem.deposit;
      let realizedprofit = updateItem.realizedprofit;
      if (!isNaN(balance) && !isNaN(realizedprofit)) {
        let deposit = balance - lastBalance - realizedprofit - buy;
        updateItem.deposit = deposit;
      } else if (!isNaN(balance) && !isNaN(deposit)) {
        let realizedprofit = balance - lastBalance - buy - deposit;
        updateItem.realizedprofit = realizedprofit;
      }
    }
    if (field === "deposit") {
      // 若輸入出金額時，與上次的差額扣掉入出金額計算已實現損益
      let balance = updateItem.balance;
      let buy = updateItem.buy;
      let deposit = value;
      let realizedprofit = updateItem.realizedprofit;
      if (!isNaN(balance) && !isNaN(deposit) && !isNaN(buy)) {
        let realizedprofit = balance - lastBalance - buy - deposit;
        updateItem.realizedprofit = realizedprofit;
      } else if (!isNaN(balance) && !isNaN(deposit) && !isNaN(realizedprofit)) {
        let buy = balance - lastBalance - realizedprofit - deposit;
        updateItem.buy = buy;
      }
    }
    updateItem[field] = value;
    this.setState({ performances: performances });
  };
  onHandleSubmit = () => {
    const fundaccounts = this.state.fundaccounts;
    const performances = this.state.performances;
    fundaccounts.forEach((fundaccount) => {
      if (fundaccount.submitted) return; // 已同步過就不再同步
      let fundAccountPerformance = performances.filter(
        (row) => row.fundaccountId === fundaccount.id
      );
      let valid = true;
      fundAccountPerformance.forEach((row) => {
        if (!row.holdingid) {
          message.warning("請選擇商品");
          valid = false;
        }
        if (!row.time) {
          message.warning("請選擇時間");
          valid = false;
        }
        if (row.balance === null) {
          message.warning("請填寫餘額");
          valid = false;
        }
        if (row.balance < 0) {
          message.warning("帳戶餘額不得為負值");
          valid = false;
        }
      });
      if (!valid) return; // 有欄位未填
      let requestPerformances = fundAccountPerformance.map((row) => {
        return {
          periodtype: "realtime",
          buy: row.buy,
          deposit: row.deposit,
          holdingid: row.holdingid,
          unrealizedprofit: row.unrealizedprofit,
          realizedprofit: row.realizedprofit,
          balance: row.balance,
          tax: row.tax,
          commission: row.commission,
          date: row.time,
          note: row.note,
        };
      });
      if (!requestPerformances.length) return; // 若沒有資料則略過
      this.setState({ loading: true });
      createBatchPerformances(requestPerformances)
        .then((response) => {
          fundaccount.submitted = true;
          this.setState({ fundaccounts: fundaccounts });
        })
        .catch((error) => {
          console.log(error);
          notification["error"]({
            message: error.response
              ? error.response.data.message
              : error.message,
            duration: 3,
          });
        })
        .finally(() => {
          this.refreshFundaccounts();
          this.setState({ loading: false });
        });
    });
  };
  render() {
    const routes = [
      {
        path: "/admin",
        breadcrumbName: "首頁",
      },
      {
        path: "/administration",
        breadcrumbName: "行政作業",
      },
      {
        path: "/customerperformances",
        breadcrumbName: "績效明細",
      },
      {
        path: this.state.customerid,
        breadcrumbName: this.state.customername,
      },
      {
        path: "/create",
        breadcrumbName: "批次建立績效明細",
      },
    ];
    return (
      <Layout.CMSLayout {...this.props}>
        <PageHeader pageTitle={this.state.customername} routes={routes} />
        <div className="main-content">
          {this.state.fundaccounts.length > 0 ? (
            <Spin spinning={this.state.loading}>
              <div name="PerformanceForm">
                {this.state.fundaccounts.map((fundaccount) => (
                  <Card key={fundaccount.id} style={{ marginBottom: 10 }}>
                    <Typography.Title level={4}>
                      {fundaccount.submitted ? (
                        <>
                          <CheckCircleTwoTone />
                          &nbsp;
                        </>
                      ) : null}
                      資金帳戶：{fundaccount.account}
                    </Typography.Title>
                    <Descriptions
                      size="small"
                      column={{ lg: 3, md: 2, sm: 1, xs: 1 }}
                    >
                      <Descriptions.Item label="保管方">
                        {fundaccount.custodian.name}
                      </Descriptions.Item>
                      <Descriptions.Item label="帳戶託管金額">
                        <CurrencyNumberFormat
                          currencyid={fundaccount.currencyid}
                          value={fundaccount.principal}
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label="帳戶託管餘額">
                        <CurrencyNumberFormat
                          currencyid={fundaccount.currencyid}
                          value={fundaccount.balance}
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label="最後新增日期">
                        {fundaccount.lastdate}
                      </Descriptions.Item>
                    </Descriptions>

                    <Card
                      style={{
                        background: fundaccount.submitted ? "#fff" : "#eee",
                        margin: "10px 0",
                      }}
                    >
                      <div style={{ overflow: "auto" }}>
                        {this.state.performances
                          .filter((row) => row.fundaccountId === fundaccount.id)
                          .map((row) => (
                            <div key={row.key} className="row-style">
                              <BatchPerformanceForm
                                selectableHolding={fundaccount.holdings}
                                values={row}
                                disabled={fundaccount.submitted}
                                onValueChange={this.onPerformanceItemValueChange.bind(
                                  this,
                                  row.key
                                )}
                                onDeleteItem={this.onPerformanceItemDelete.bind(
                                  this,
                                  row.key
                                )}
                              />
                            </div>
                          ))}
                      </div>
                      {fundaccount.holdings.map((holding) =>
                        holding.assetname === "台灣指數程式" ? (
                          <Typography.Text
                            type="secondary"
                            style={{ marginTop: 10 }}
                          >
                            此商品已連結程式自動匯入
                          </Typography.Text>
                        ) : (
                          <Button
                            className="addItembutton"
                            block
                            onClick={this.onPerformanceItemAdd.bind(
                              this,
                              fundaccount.id
                            )}
                            disabled={fundaccount.submitted}
                          >
                            新增績效明細
                          </Button>
                        )
                      )}
                    </Card>
                  </Card>
                ))}
                <div style={{ marginTop: "5px" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="button-color mt"
                    onClick={this.onHandleSubmit}
                    disabled={this.state.fundaccounts.reduce(
                      (allSubmitted, currentValue) =>
                        allSubmitted && currentValue.submitted,
                      true
                    )}
                  >
                    儲存
                  </Button>
                  <Link
                    style={{ padding: "10px 15px", borderRadius: 5 }}
                    to="/admin/administration/customerperformances"
                  >
                    回上一頁
                  </Link>
                </div>
              </div>
            </Spin>
          ) : (
            "目前無任何資金帳戶及持有商品，請先新增資新帳戶及持有商品"
          )}
        </div>
      </Layout.CMSLayout>
    );
  }
}
export default CreateBatchPerformanceDetailPageV2;
