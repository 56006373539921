import React, { Component } from "react";
import "./Drawer.css";
import { Drawer as AntdDrawer } from "antd";

class Drawer extends Component {
  render() {
    return (
      <AntdDrawer {...this.props}>
          {this.props.children}
      </AntdDrawer>
    );
  }
}

export default Drawer;
