import React from "react";
import { InputNumber, Input, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import moment from "moment";
class PerformancesForm extends React.Component {
  onValueChange = (field, value) => {
    if (field === "time") {
      // Convert momemnt to date string
      value = value.format("YYYY-MM-DD HH:mm:ss");
    }
    if (field === "note") {
      // Get value from AntD Input component
      value = value.target.value;
    }
    this.props.onValueChange(field, value);
  };
  render() {
    const size = "large";
    const { values, disabled } = this.props;
    return (
      <>
        <div label="時間" className="cloumn-style mg">
          <DatePicker
            locale={locale}
            placeholder="請選擇時間"
            style={{ width: '100%' }}
            format="YYYY-MM-DD HH:mm"
            inputReadOnly={true}
            allowClear={false}
            value={moment(values.time)}
            onChange={this.onValueChange.bind(this, "time")}
            disabled={disabled}
            showTime={true}
          />
        </div>
        <div label="帳戶餘額" className="cloumn-style mg">
          <InputNumber
            style={{ width: '100%' }}
            size={size}
            value={values.balance}
            onChange={this.onValueChange.bind(this, "balance")}
            disabled={disabled}
          />
        </div>
        <div label="已實現損益" className="cloumn-style mg">
          <InputNumber
            style={{ width: '100%' }}
            size={size}
            value={values.realizedprofit}
            onChange={this.onValueChange.bind(this, "realizedprofit")}
            disabled={disabled}
          />
        </div>
        <div label="入出金" className="cloumn-style mg">
          <InputNumber
            style={{ width: '100%' }}
            size={size}
            value={values.deposit}
            onChange={this.onValueChange.bind(this, "deposit")}
            disabled={disabled}
          />
        </div>
        <div label="未實現損益" className="cloumn-style mg">
          <InputNumber
            style={{ width: '100%' }}
            size={size}
            value={values.unrealizedprofit}
            onChange={this.onValueChange.bind(this, "unrealizedprofit")}
            disabled={disabled}
          />
        </div>
        <div label="手續費" className="cloumn-style mg">
          <InputNumber
            style={{ width: '100%' }}
            size={size}
            value={values.commission}
            onChange={this.onValueChange.bind(this, "commission")}
            disabled={disabled}
          />
        </div>
        <div label="稅額" className="cloumn-style mg">
          <InputNumber
            style={{ width: '100%' }}
            size={size}
            value={values.tax}
            onChange={this.onValueChange.bind(this, "tax")}
            disabled={disabled}
          />
        </div>
        <div label="備註" className="cloumn-style mg">
          <Input
            style={{ width: '100%' }}
            size={size}
            placeholder="本筆績效的摘要"
            value={values.note}
            onChange={this.onValueChange.bind(this, "note")}
            disabled={disabled}
          />
        </div>
      </>
    );
  }
}
export default PerformancesForm;
