import React, { Component } from 'react'
import { Button, Col, Descriptions, Row, Table, Typography } from 'antd'
import Drawer from "../Drawer";
import CreateTransactionDetailDrawer from '../Transcation/CreateTransactionDetailDrawer'
import { getHoldingDetail, getHoldingTranscations } from '../../controller/api';
import { TYPE_PERFORMANCE, TYPE_TRANSACTION } from '../../consts/HoldingTypes';

export class HoldingsDetailDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            holdingsData: this.props.holdingsData,
            transactions: [],
            showCreateTranscationDetaliModal: false
        };
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.holdingsId !== prevProps.holdingsId) {
            this.refresh();
        }
    }
    refresh = () => {
        this.refreshHoldings();
        this.refreshTranscations();
    };
    refreshHoldings = () => {
        if (!this.props.holdingsId) return;
        getHoldingDetail(this.props.holdingsId).then((response) => {
            this.setState({
                holdingsData: response.data.holdings
            });
        });
    };
    refreshTranscations = () => {
        if (!this.props.holdingsId) return;
        getHoldingTranscations(this.props.holdingsId).then((response) => {
            this.setState({
                transactions: response.data.transactions
            });
        });
    };
    showCreateTranscationDetaliModal = () => {
        this.setState({
            showCreateTranscationDetaliModal: true,
        });
    };
    hideCreateTranscationDetailModal = () => {
        this.refresh();
        this.setState({
            showCreateTranscationDetaliModal: false,
        });
    };
    getHoldingTypeName = (type) => {
        switch (type) {
            case TYPE_TRANSACTION:
                return "交易明細";
            case TYPE_PERFORMANCE:
                return "帳戶餘額";
            default:
                return "不明";
        }
    };
    render() {
        const columns = [
            {
                title: "時間",
                dataIndex: "date",
                key: "date",
                defaultSortOrder: "descend",
                sorter: (a, b) => new Date(a.date) - new Date(b.date),
            },
            {
                title: "類型",
                dataIndex: "action",
                key: "action",
                render: (text, record) => text === "buy" ? "買進" : "賣出"
            },
            {
                title: "數量",
                dataIndex: "quantity",
                key: "quantity"
            },
            {
                title: "成交價格",
                dataIndex: "unitprice",
                key: "unitprice"
            },
            {
                title: "小計",
                dataIndex: "subtotal",
                key: "subtotal"
            },
            {
                title: "本金",
                dataIndex: "afterprincipal",
                key: "afterprincipal"
            },
            {
                title: "權益值",
                dataIndex: "afterequity",
                key: "afterequity"
            },
            {
                title: "已實現損益",
                dataIndex: "realized",
                key: "realized"
            },
            {
                title: "備註",
                dataIndex: "note",
                key: "note"
            },
            {
                title: "動作",
            },
        ];
        const { holdingsData, holdingsId, ...drawerProps } = this.props;

        return (
            <Drawer width={800} {...drawerProps}>
                <Typography.Title level={4}>
                    商品 {this.state.holdingsData ? this.state.holdingsData.assets.assetname : '商品名稱'}
                </Typography.Title>
                <Descriptions size="small" column={{ lg: 3, md: 2, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="買進日期">
                        <Typography.Text type="secondary">
                            {this.state.holdingsData ? this.state.holdingsData.enabledate : 0}
                        </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="本金" span={2}>
                        <Typography.Text type="secondary">
                            {this.state.holdingsData ? this.state.holdingsData.principal : 0}
                        </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="績效記錄方式">
                        <Typography.Text type="secondary">
                            {this.state.holdingsData ? this.getHoldingTypeName(this.state.holdingsData.type) : ''}
                        </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="持有數量">
                        <Typography.Text type="secondary">
                            {this.state.holdingsData ? this.state.holdingsData.inventory : 0}
                        </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="未實現餘額">
                        <Typography.Text type="secondary">
                            {this.state.holdingsData ? this.state.holdingsData.unrealizedbalance : 0}
                        </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="未實現損益">
                        <Typography.Text type="secondary">
                            {this.state.holdingsData ? this.state.holdingsData.unrealizedprofit : 0}
                        </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="已實現損益">
                        <Typography.Text type="secondary">
                            {this.state.holdingsData ? this.state.holdingsData.realizedprofit : 0}
                        </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="備註">
                        <Typography.Text type="secondary">
                            {this.state.holdingsData ? this.state.holdingsData.note : 0}
                        </Typography.Text>
                    </Descriptions.Item>
                </Descriptions>
                {this.state.holdingsData && this.state.holdingsData.type === TYPE_TRANSACTION && <>
                    <Row justify="space-between">
                        <Col></Col>
                        <Col>
                            <Button
                                className="button-color"
                                style={{ marginBottom: "10px", borderRadius: 5, float: "right" }}
                                onClick={this.showCreateTranscationDetaliModal}
                            >新增交易明細</Button>
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={this.state.transactions}
                        rowKey="id"
                        scroll={{
                            x: 1000,
                            scrollToFirstRowOnChange: true,
                        }}
                    />
                </>}
                {this.state.holdingsData && this.state.holdingsData.type === TYPE_PERFORMANCE && <>
                    <Typography.Text>此商品採用帳戶餘額方式記錄績效，無交易明細</Typography.Text>
                </>}
                <CreateTransactionDetailDrawer
                    holdingsId={this.props.holdingsId}
                    visible={this.state.showCreateTranscationDetaliModal}
                    onSave={this.hideCreateTranscationDetailModal}
                    onClose={this.hideCreateTranscationDetailModal}
                />
            </Drawer>
        )
    }
}

export default HoldingsDetailDrawer;
