import React from "react";
import { InputNumber, Input, Select, DatePicker, TimePicker } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/zh_TW";
import moment from "moment";
class TransactionForm extends React.Component {
  render() {
    const { fielddata, send } = this.props;
    return (
      <>
        <div
          label={this.props.createMulti ? "時間" : "交易時間"}
          className={this.props.optionClass}
          name="date"
        >
          {this.props.createMulti ? (
            <TimePicker
              placeholder="請選擇時間"
              style={{ width: "100%" }}
              format="HH:mm"
              inputReadOnly={true}
              allowClear={false}
              onChange={this.props.onTimeChange}
              onSelect={this.props.onTimeChange}
              disabled={send}
              value={
                (fielddata && fielddata.date) !== undefined
                  ? moment(fielddata.date)
                  : undefined
              }
            />
          ) : (
            <DatePicker
              size={this.props.size}
              style={{ width: "100%" }}
              placeholder="請選擇日期"
              showTime={true}
              locale={locale}
              value={fielddata !== undefined ? fielddata.date : null}
              format={`${process.env.REACT_APP_DATE_FORMAT} (dd) HH:mm`}
              onChange={this.props.onDateChange}
            />
          )}
        </div>

        <div
          label="類型"
          className={this.props.optionClass}
          name="action"
        >
          <Select
            style={{ width: "100%" }}
            size={this.props.size}
            placeholder="買/賣"
            onChange={this.props.onSelectChange.bind(this, "action")}
            disabled={send}
            >
            <Select.Option value="buy">買進</Select.Option>
            <Select.Option value="sell">賣出</Select.Option>
          </Select>
        </div>
        <div label="金額" className={this.props.optionClass} name="subtotal">
          <InputNumber
            style={{ width: "100%" }}
            size={this.props.size}
            placeholder="請輸入交易金額"
            value={fielddata !== undefined ? fielddata.subtotal : null}
            disabled={
              send||
              fielddata.action === null ||
              fielddata.action === undefined
            }
            onChange={this.props.onValueChange.bind(this, "subtotal")}
          />
        </div>
        <div label="數量" className={this.props.optionClass} name="quantity">
          <InputNumber
            style={{ width: "100%" }}
            size={this.props.size}
            placeholder="請輸入交易數量"
            value={fielddata !== undefined ? fielddata.quantity : null}
            disabled={
              send||
              fielddata.action === null ||
              fielddata.action === undefined
            }
            onChange={this.props.onValueChange.bind(this, "quantity")}
          />
        </div>
        <div label="成交價格" className={this.props.optionClass} name="unitprice">
          <InputNumber
            style={{ width: "100%" }}
            size={this.props.size}
            placeholder="請輸入成交價格"
            value={fielddata !== undefined ? fielddata.unitprice : null}
            disabled={true}
            onChange={this.props.onValueChange.bind(this, "unitprice")}
          />
        </div>
        <div label="備註" className={this.props.optionClass} name="note">
          <Input
            size={this.props.size}
            placeholder="本筆交易的備註"
            onChange={this.props.onValueChange.bind(this, "note")}
            disabled={send}
          />
        </div>
        {this.props.createMulti && !send ? (
          <div label="&nbsp;" className={this.props.optionClass}>
            <MinusCircleOutlined onClick={this.props.onDeleteItem} />
          </div>
        ) : null}
      </>
    );
  }
}
export default TransactionForm;
