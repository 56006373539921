import React from "react";
import { Descriptions, Tabs, Typography, Skeleton } from "antd";
import * as Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import UploadFiles from "../../components/FileManager/UploadFiles";
import NoteList from "../../components/NoteList";
import { getCustomerDetail } from "../../controller/api";
import CategoryList from "../../components/FileManager/CategoryList";
import { Observer } from "mobx-react";
import { changeUploadListVisible } from "../../controller/action";
import { changeToUploadList } from "../../controller/observer";
class CustomerDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changeToFilesList: false,
      customerID: props.match.params.customerId,
      customerData: null,
    };
  }
  componentDidMount() {
    changeUploadListVisible(false);
    getCustomerDetail(this.state.customerID).then((response) => {
      this.setState({
        customerData: response.data.customer,
      });
    });
  }
  getTargetCategoryFiles = () => {
    changeUploadListVisible(true);
  };

  render() {
    let routes = null;
    this.state.customerData === null
      ? (routes = [])
      : (routes = [
          {
            path: "/admin",
            breadcrumbName: "首頁",
          },
          {
            path: "/customers",
            breadcrumbName: "我的客戶",
          },
          {
            path: `/${this.state.customerID}`,
            breadcrumbName: this.state.customerData.cnName,
          },
        ]);
    const { TabPane } = Tabs;
    const none = <Typography.Text type="secondary">尚未填寫</Typography.Text>;
    return (
      <Layout.CMSLayout {...this.props}>
        {this.state.customerData === null ? (
          <Skeleton active />
        ) : (
          <>
            <PageHeader
              pageTitle={this.state.customerData.cnName}
              routes={routes}
            />
            <div className="main-content">
              <Tabs
                type="card"
                onChange={() => changeUploadListVisible(false)}
              >
                <TabPane tab="基本資料" key="1">
                  <Descriptions
                    className="descriptions-table"
                    bordered
                    column={1}
                  >
                    <Descriptions.Item label="客戶名稱">
                      {this.state.customerData.name === null
                        ? none
                        : this.state.customerData.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="中文姓名">
                      {this.state.customerData.cnName === null
                        ? none
                        : this.state.customerData.cnName}
                    </Descriptions.Item>
                    <Descriptions.Item label="英文名">
                      {this.state.customerData.enName === null
                        ? none
                        : this.state.customerData.enName}
                    </Descriptions.Item>
                    <Descriptions.Item label="國籍身分">
                      {this.state.customerData.nationality === null
                        ? none
                        : this.state.customerData.nationality}
                    </Descriptions.Item>
                    <Descriptions.Item label="身分證字號">
                      {this.state.customerData.idCode === null
                        ? none
                        : this.state.customerData.idCode}
                    </Descriptions.Item>
                    <Descriptions.Item label="護照號碼">
                      {this.state.customerData.passport === null
                        ? none
                        : this.state.customerData.passport}
                    </Descriptions.Item>
                    <Descriptions.Item label="稱呼">
                      {this.state.customerData.title === null
                        ? none
                        : this.state.customerData.title}
                    </Descriptions.Item>
                    <Descriptions.Item label="出生年月日">
                      {this.state.customerData.birth === null
                        ? none
                        : this.state.customerData.birth}
                    </Descriptions.Item>
                    <Descriptions.Item label="電話(日間)">
                      {(this.state.customerData.dayZone,
                      this.state.customerData.dayTel) === null
                        ? none
                        : `${this.state.customerData.dayZone} - ${
                            this.state.customerData.dayTel
                          } ${
                            this.state.customerData.dayExt === null
                              ? ""
                              : `分機 ${this.state.customerData.dayExt}`
                          }`}
                    </Descriptions.Item>
                    <Descriptions.Item label="電話(夜間)">
                      {(this.state.customerData.nightZone,
                      this.state.customerData.nightTel) === null
                        ? none
                        : `${this.state.customerData.nightZone} - ${
                            this.state.customerData.nightTel
                          } ${
                            this.state.customerData.nightExt === null
                              ? ""
                              : `分機 ${this.state.customerData.nightExt}`
                          }`}
                    </Descriptions.Item>
                    <Descriptions.Item label="行動電話">
                      {this.state.customerData.mobile === null
                        ? none
                        : this.state.customerData.mobile}
                    </Descriptions.Item>
                    <Descriptions.Item label="聯絡地址">
                      {this.state.customerData.contactAddress === null
                        ? none
                        : this.state.customerData.contactAddress}
                    </Descriptions.Item>
                    <Descriptions.Item label="戶籍地址">
                      {this.state.customerData.registeredAddress === null
                        ? none
                        : this.state.customerData.registeredAddress}
                    </Descriptions.Item>
                    <Descriptions.Item label="電子郵件">
                      {this.state.customerData.email === null
                        ? none
                        : this.state.customerData.email}
                    </Descriptions.Item>
                    <Descriptions.Item label="行業別">
                      {this.state.customerData.industryType === null
                        ? none
                        : this.state.customerData.industryType}
                    </Descriptions.Item>
                    <Descriptions.Item label="本業別">
                      {this.state.customerData.jobType === null
                        ? none
                        : this.state.customerData.jobType}
                    </Descriptions.Item>
                  </Descriptions>
                </TabPane>
                <TabPane tab="文件檔案" key="2">
                  <Observer>
                    {() =>
                      !changeToUploadList.visible ? (
                        <CategoryList customerId={this.state.customerID} />
                      ) : (
                        <UploadFiles customerId={this.state.customerID} />
                      )
                    }
                  </Observer>
                </TabPane>
                <TabPane tab="客戶筆記" key="3">
                  <NoteList customerId={this.state.customerData.id} />
                </TabPane>
              </Tabs>
            </div>
          </>
        )}
      </Layout.CMSLayout>
    );
  }
}
export default CustomerDetailPage;
