import React from "react";
import { Modal, Select, Typography } from "antd";

import { getCategoryList, moveFile } from "../../controller/api";
import { changeToUploadList } from "../../controller/observer";
class FileMoveModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      categoryList: [],
      moveto: null,
    };
  }
  componentDidMount() {
    getCategoryList(this.props.customerId).then((res) => {
      this.setState({ categoryList: res.data.data });
    });
  }
  handleChange = (key) => {
    this.setState({ moveto: key });
  };
  handleOk = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.files.forEach((key, index) => {
          const data = { fileId: key, categoryId: this.state.moveto };
          moveFile(data)
            .then((res) => {
              if (index + 1 === this.props.files.length) {
                this.setState({ loading: false }, () => {
                  this.props.refreshList();
                  this.props.changeModalVisible();
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      }
    );
  };
  handleCancel = () => {
    this.props.changeModalVisible();
  };
  render() {
    const { Option } = Select;
    return (
      <Modal
        title={"移動 " + this.props.files.length + " 個項目至..."}
        visible={this.props.visible}
        destroyOnClose={true}
        okText="移動"
        cancelText="取消"
        onOk={this.handleOk}
        okButtonProps={{
          className:"button-color",
          loading: this.state.loading,
          disabled: this.state.moveto !== null ? false : true,
        }}
        onCancel={this.handleCancel}
      >
        <Typography.Text>
          您目前位於{" "}
          <Typography.Text type="warning">
            {changeToUploadList.cateName}
          </Typography.Text>{" "}
          ，請選擇移動的目的地
        </Typography.Text>
        {this.state.categoryList.length > 0 ? (
          <Select
            placeholder="請選擇移動的目的地"
            style={{ width: "100%", marginTop: 5 }}
            onChange={this.handleChange}
          >
            {this.state.categoryList.map((file) => (
              <Option key={file.id}>{file.categoryName}</Option>
            ))}
          </Select>
        ) : null}
      </Modal>
    );
  }
}
export default FileMoveModal;
