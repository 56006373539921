import React from "react";
import { Form, Button, notification } from "antd";
import Drawer from "../Drawer";
import HoldingsForm from "./HoldingsForm";
import {
  createHoldings,
  // getFundAccountAssets
} from "../../controller/api";
import moment from "moment";
import { TYPE_PERFORMANCE } from "../../consts/HoldingTypes";
class CreateHoldingsDrawer extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  getInitialValues = () => {
    return { currency: "TWD", type: TYPE_PERFORMANCE };
  };
  onInputChange = (e) => {
    this.setState({
      accountprincipal: e.target.value,
      remaining: e.target.value,
    });
  };
  onHandleSubmit = (props) => {
    const data = props;
    data.enabledate = moment(props.enabledate).format(
      process.env.REACT_APP_DATE_FORMAT
    );
    data.fundaccountsid = this.props.viewingaccount;
    this.setState({ loading: true }, () => {
      createHoldings(data).then((res) => {
        notification["success"]({
          message: res.data.message,
          duration: 3,
          onClose: () => {
            this.props.reloadAccountDetail(1);
            this.props.onClose();
          },
        });
      })
      .catch((error) => {
        console.log(error);
        notification["error"]({
          message: error.response.data ? error.response.data.message:error.message,
          duration: 3,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
    });
  };
  onValuesChange = (changedValues, allValues) => {
    let principal = allValues.principal;
    let quantity = allValues.quantity;
    if( principal && quantity ) {
      let unitprice = principal / quantity;
      this.setUnitprice(unitprice);
    }
  };
  setUnitprice = (value) => {
    this.formRef.current.setFieldsValue({ unitprice: value });
  };
  render() {
    return (
      <Drawer
        title="新增商品"
        width={"45%"}
        visible={this.props.visible}
        onClose={this.props.onClose}
        destroyOnClose={true}
      >
        <Form
          name="HoldingsForm"
          layout="vertical"
          onFinish={this.onHandleSubmit}
          initialValues={this.getInitialValues()}
          onValuesChange={this.onValuesChange}
          scrollToFirstError={true}
          ref={this.formRef}
        >
          <HoldingsForm createflag={false} assets={this.props.assets} />
          <div style={{ marginTop: "5px" }}>
            <Button
              type="primary"
              htmlType="submit"
              className="button-color mt"
              loading={this.state.loading}
            >
              儲存
            </Button>
            <Button
              style={{ marginLeft: "5px" }}
              className="mt"
              onClick={this.props.onClose}
            >
              取消
            </Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}
export default CreateHoldingsDrawer;
