import React from "react";
import { Modal, Typography, notification } from "antd";
import { deleteFundAccount } from "../../controller/api";
class DeleteAccountModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  handleOk = () => {
    this.setState({ loading: true }, () => {
      deleteFundAccount(this.props.viewing.id)
        .then((res) => {
          notification["success"]({
            message: res.data.fundaccount.account + res.data.message,
            duration: 3,
            onClose: () => {
              this.handleCancel();
              this.props.reloadList();
            },
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    });
  };
  handleCancel = () => {
    this.props.onClose();
  };
  render() {
    return (
      <Modal
        title={`您正在刪除${this.props.viewing.account}`}
        visible={this.props.visible}
        destroyOnClose={true}
        okText="刪除"
        cancelText="取消"
        onOk={this.handleOk}
        okButtonProps={{ loading: this.state.loading, type: "danger" }}
        onCancel={this.handleCancel}
      >
        您確定要刪除&nbsp;
        <Typography.Text type="warning">
          {this.props.viewing.account}
        </Typography.Text>
        &nbsp;?
      </Modal>
    );
  }
}
export default DeleteAccountModal;
