import React, { Component } from 'react'
import { Typography } from 'antd';
import { PropTypes } from 'prop-types';

/**
 * 報酬率文字
 * @augments {Component<Props, State>}
 */
export class RorText extends Component {
    static propTypes = {
        /** 目前餘額 */
        balance: PropTypes.number,
        /** 本金 */
        principal: PropTypes.number,
        /** 報酬率 */
        value: PropTypes.number
    }
    render() {
        let { balance, principal, value } = this.props;
        let ror = null;
        if (value !== undefined) {
            ror = (parseFloat(value) * 100).toFixed(2);
        } else {
            balance = parseFloat(balance);
            principal = parseFloat(principal);
            ror = (((balance - principal) / principal) * 100).toFixed(2);
        }
        return (
            <Typography.Text className={ror === 0 || isNaN(ror) ? "" : (ror > 0 ? "red" : "green")}>
                {ror === 0 || isNaN(ror) ? "" : (ror > 0 ? "+" : "")}
                {ror === 0 ? "-" : `${ror}%`}
            </Typography.Text>
        );
    }
}

export default RorText
